import { BaseValidator } from './';
import { prescriptionConstant } from '../_constants/prescription.constant';


class PrescriptionFormValidator extends BaseValidator {
    constructor(props) {
        super(props);
    }

    form = (form) => {
        this.validateField(!form.pharmacy, 'Pharmacy is Required');
        this.validateField(form.Medications.length === 0, 'Medication is Required');
        
        if (!prescriptionConstant.IS_UNLIMITED)
            this.validateField(form.Medications.length > 3, 'More than 3 medications are not allowed');
        // duration validation
        if (form.Medications.length > 0) {
            const nonDurationDrug = form.Medications.filter(o => {
                const usage = [...o.usages].pop();
    
                if (usage.duration === '') {
                    return true;
                } else {
                    return false;
                }
            });

            this.validateField(nonDurationDrug.length > 0, 'Medication duration is Required');
        }
    }
}


export { PrescriptionFormValidator };