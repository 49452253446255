import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { registrationAction } from '../../_actions';
import { AgreementDialog } from './AgreementDialog';
import { PrivacyPolicyDialog } from '.';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { profileConstant } from '../../_constants';

class TermsOfUseDialog extends React.Component {
    state = { showFeesModal: false };
    constructor(props) {
        super(props);
        this.website = "AskMedication.com";
        this.company = this.website + " " + "Inc.";
    }
    handleFeesModalOpen = () => {
        this.setState({ showFeesModal: true });
    };

    handleFeesModalClose = () => {
        this.setState({ showFeesModal: false });
    };

    handlePrivacyPolicy = () => {
        const { dispatch } = this.props;
        dispatch(registrationAction.closeTermsOfUse());
        dispatch(registrationAction.openPrivacyPolicy());
    }

    handleClose = (checked) => {
        const { dispatch } = this.props;
        dispatch(registrationAction.closeTermsOfUse(checked));
    };

    buildContent() {
        return (
            <>
                <div className="title">
                    <div>{this.website}</div>
                    <div>TERMS OF USE</div>
                </div>
                <div className="section">
                    <div>{profileConstant.UPDATE_DATE_AND_VERSION}</div>
                    <div>
                        Welcome to {this.website} (the <strong>&quot;Portal&quot;</strong>) of {this.company} (<strong>“{this.company},” “we,” “us,” “our”</strong>).
                    </div>
                    <div>
                        By clicking that you agree to these terms and conditions and by using this Portal, you accept
                        and agree to be bound by these terms of use and any additional terms they expressly
                        incorporate by reference (<strong>&quot;Terms of Use&quot;</strong>), including our <a href="#" onClick={this.handlePrivacyPolicy}>Privacy Policy</a> and our <Link to="#" onClick={this.handleFeesModalOpen}>Patient Fee Schedule</Link>.
                    </div>
                    <div>
                        IF YOU DO NOT AGREE WITH ANY PART OF THESE TERMS OF USE OR THE PRIVACY
                        POLICY YOU MUST IMMEDIATELY DISCONTINUE ANY USE OF THE PORTAL AND THE
                        SERVICES OFFERED THEREWITH.
                    </div>
                    <div>
                        DO NOT USE THE PORTAL TO COMMUNICATE OR FOR TREATMENT OF MEDICAL
                        EMERGENCIES. IF THERE IS AN URGENT ISSUE OR A RESPONSE IS NEEDED QUICKLY
                        REGARDING YOUR HEALTH CARE, YOU MUST CALL YOUR HEALTHCARE PROVIDER,
                        GO TO A NEARBY EMERGENCY DEPARTMENT OR URGENT CARE CENTRE, OR CALL
                        911 IMMEDIATELY.
                    </div>
                </div>
                <div className="section">
                    <div className="title">
                        USE OF THE PORTAL
                    </div>
                    <div>
                        The Portal provides a secure online platform which allows you to receive patient care services
                        (the <strong>“Services”</strong>) using this Portal from one of our physicians licensed to practice medicine in the
                        Province of British Columbia. You must be 19 years old or older and located in British Columbia
                        at the time of seeking and obtaining our Services, provide your personal information and
                        identification we require to create a patient user account for you (the <strong>“Patient User Account”</strong>),
                        and agree to these Terms of Use, our <a href="#" onClick={this.handlePrivacyPolicy}>Privacy Policy</a> and our <Link to="#" onClick={this.handleFeesModalOpen}>Patient Fee Schedule</Link> in order to seek and receive the
                        Services available through this Portal.
                    </div>
                    <div>
                        You agree to treat {this.website} confidentially, and not to disclose to any other person or entity, your user
                        name, password, or any other piece of information chosen by you or provided to you as part of
                        our security procedures. You agree to exercise caution when accessing your Patient User
                        Account so that others are not able to view or record your password. Your Patient User Account
                        is personal to you and you agree not to provide any other person with access to the Portal or
                        portions of it such as your user name, password, or other security information.
                    </div>
                    <div>
                        You agree that you will not use this Portal for improper, unethical or fraudulent purposes.
                    </div>
                    <div>
                        It is a condition of your use of the Portal that all the information you submit is correct, current,
                        and complete.
                    </div>
                    <div>
                        You agree that you will do your part to protect and safeguard your personal information when
                        using this Portal. You are expressly required to:
                    </div>
                    <ul>
                        <li>
                            Use only safe, encrypted internet access on each and every occasion that you log in to,
                            create and/or modify your Patient User Account. You are not to use unsecured public
                            networks, such as public wi-fi from a hotel or coffee shop, when creating or accessing
                            your Patient User Account;
                        </li>
                        <li>
                            Refrain from saving your password for your Account to your phone, computer or other
                            electronic device;
                        </li>
                        <li>
                            Refrain from, at any time, sharing your password with another individual;
                        </li>
                        <li>
                            Notify us immediately of any unauthorized access to or use of your Patient User
                            Account, including your user name or password, or if you believe that there is a risk of
                            the potential unauthorized use of your Patient User Account including your user name
                            and password; and,
                        </li>
                        <li>
                            Ensure that you logout from your Patient User Account at the end of each session.
                        </li>
                    </ul>
                    <div>
                        We reserve the right at any time and from time to time, to disable or terminate your Patient User
                        Account, any username, password, or other identifier, whether chosen by you or provided by
                        us, in our sole discretion for any or no reason, including any violation of any provision of these
                        Terms of Use.
                    </div>
                    <div>
                        You may stop using the Portal or deactivate your Patient User Account at any time. Should you
                        discontinue your use of the Portal, or if your Patient User Account is discontinued or terminated
                        for any reason, your Patient User Account will be deactivated. We will work with you to fulfill any
                        personal information access requests.
                    </div>
                    <div>
                        As long as you maintain a Patient User Account, you consent to, and may not “opt out” of
                        receiving service related communications from us.
                    </div>
                </div>
                <div className="section">
                    <div className="title">
                        DISCLAIMERS
                    </div>
                    <div>
                        {this.company}, to the fullest extent permitted by law, disclaims any and all warranties, either expressed or
                        implied, statutory or otherwise, makes no representations or warranties of any kind including,
                        without limitation any representations or warranties about the accuracy, reliability,
                        completeness, currency or timeliness of the Portal or the services or the results or outcomes
                        that may be obtained from the use of the Portal or the Services. You acknowledge and agree that
                        the Portal and the Services are provided on an &quot;as is&quot; basis.
                    </div>
                </div>
                <div className="section">
                    <div className="title">
                        LIMITATION OF LIABILITY
                    </div>
                    <div>
                        Despite our best efforts to safeguard your personal information accessible on the
                        Portal, we cannot and do not guarantee complete confidentiality and security of your personal
                        information, as communication via the internet is subject to interception, loss, or alteration. You
                        acknowledge and agree that any information you communicate to us over the internet and using
                        this Portal is at your own risk.
                    </div>
                    <div>
                        You acknowledge that any use of or reliance on the Portal or on the Services is completely at
                        your own risk. You agree that {this.company} shall not be responsible or liable for any loss, damages, injury,
                        claims or liabilities whatsoever (including without limitation, direct, indirect, special, punitive or
                        consequential damages, or damages for loss of information) arising from or in connection with
                        the use of or access to, or the inability to use or access, the Portal, the Services and/or any
                        information stored, provided or otherwise communicated between you and {this.company} (whether arising in
                        contract, tort, negligence, equity, common law or otherwise) even if {this.company} has been advised of the
                        possibility of such damage or loss.
                    </div>
                    <div>
                        {this.company} assumes no liability or responsibility for any (1) personal injury, property, bodily, or material
                        damage of any nature whatsoever, resulting from your inability to access or access to and use
                        of the Portal or the Services, (2) any unauthorized access to or use of our servers and/or any
                        and all personal information stored on our servers, (3) any interruption or cessation of
                        transmission to or from the Portal, (4) any bugs, viruses, trojan horses, denial-of-service attack,
                        flooding, logic bombs or other technologically harmful material or event which may be
                        transmitted to or through the Portal, and/or (5) any errors or omissions in any information,
                        including without limitation the content of the Portal.
                    </div>
                </div>
                <div className="section">
                    <div className="title">
                        PAYMENT OF PATIENT FEES
                    </div>
                    <div>
                        By using this Portal, you agree to provide payment to {this.company}, in accordance with the <Link to="#" onClick={this.handleFeesModalOpen}>Patient Fee Schedule</Link> (the <strong>“Patient Fee Schedule”</strong>). By
                        using the Portal and the Services available thereon, you affirm that you are 19 years of age or
                        older and you accept and are bound by the Patient Fee Schedule.
                    </div>
                    <div>
                        All prices posted on the Patient Fee Schedule are subject to change without notice. The price
                        charged for a service will be the price advertised on the Patient Fee Schedule at the time the
                        Services are sought. We strive to display accurate fee information; however, we may, on
                        occasion, make inadvertent typographical errors, inaccuracies or omissions related to our fees.
                        We reserve the right to correct any errors, inaccuracies or omissions at any time and to cancel
                        any requests for Services.
                    </div>
                    <div>
                        Terms of payment are within our sole discretion and payment must be received by us before our
                        acceptance of a request for our Service. We utilize a third party payment processor, PayPal, to
                        receive payment in accordance with our Patient Fee Schedule. You confirm the following are
                        accurate: (i) the credit card information or Paypal account you supply to us is true, correct and complete, (ii) you
                        are duly authorized to use such credit card or Paypal account for the purchase, (iii) charges incurred by you will be
                        honoured by your credit card company, and (iv) you will pay charges incurred by you based on
                        the Patient Fee Schedule, including all applicable taxes, if any.
                    </div>
                </div>
                <div className="section">
                    <div className="title">
                        INTELLECTUAL PROPERTY AND USE OF MARKS
                    </div>
                    <div>
                        {this.company} either owns the intellectual property rights, including copyright, or has acquired the necessary
                        permissions, in the information, including all text, HTML (hypertext markup language) code,
                        multimedia clips, images, graphics, icons, JavaScript code and the selection and arrangement
                        of the content of the Portal (collectively the <strong>&quot;Information&quot;</strong>). Unauthorized use by you of the
                        Information may violate copyright, trademark, and other laws.
                    </div>
                    <div>
                        {this.website} and related names displayed on the Portal are the trademarks, service marks or registered
                        trademarks of {this.company}. Except as provided for under these Terms of Use, any reproduction of any of
                        these marks without our express written consent is strictly prohibited. Except as expressly
                        granted, nothing contained in these Terms of Use shall be construed as conferring any license
                        or right to you under any intellectual property or rights proprietary to {this.company}.
                    </div>
                    <div>
                        We grant you a limited license to use this Portal provided that you: (i) abide by these Terms of
                        Use; and, (ii) do not modify, transmit, reproduce, adapt, create derivative works from,
                        decompile, decode, disassemble, reverse engineer, reverse assemble or otherwise attempt to
                        discover any source code or the architectural framework for any Information or anything within
                        or associated with the Portal.
                    </div>
                </div>
                <div className="section">
                    <div className="title">
                        OTHER PROHIBITED SECURITY VIOLATIONS
                    </div>
                    <div>
                        You must not attempt to circumvent or violate the security of this Portal including, without
                        limitation: (i) accessing content and data that is not intended for you; (ii) attempting to breach or
                        breaching the security or authentication measures which are not authorized; (iii) restricting,
                        disrupting, or disabling service to users, hosts, servers or networks; (iv) illicitly reproducing
                        TCP/IP packet header; (v) disrupting network services and otherwise disrupting the Portal
                        owner’s ability to monitor the Portal; (vi) using any robot, spider, or other automatic device,
                        process, or means to access the Portal for any purpose, including monitoring or copying any of
                        the material on the Portal; (vii) introducing any viruses, trojan horses, worms, logic bombs, or
                        other material that is malicious or technologically harmful; (viii) attacking the Portal via a denial-of-service attack,
                        distributed denial-of-service attack, flooding, mail bombing or other
                        technologically harmful event; or (ix) otherwise attempting to interfere with the proper working of
                        the Portal.
                    </div>
                </div>
                <div className="section">
                    <div className="title">
                        GENERAL
                    </div>
                    <div>
                        These Terms of Use and the use of the Portal shall be governed by the laws of British
                        Columbia, and the laws of Canada applicable therein. You irrevocably submit to the exclusive
                        jurisdiction of the courts located in the Province of British Columbia. {this.company} makes no representations
                        that the Portal or the Services are available for use in other locations.
                    </div>
                    <div>
                        Notwithstanding any other agreement, or understanding between you and {this.company}, these Terms of Use,
                        the Privacy Policy and the Patient Fee Schedule, as updated from time to time, shall be the sole
                        and entire agreement between you and {this.company} with respect to the Portal, and supersedes and
                        invalidates all other commitments, representations, warranties, conditions and understanding
                        relating to the subject matter hereof.
                    </div>
                    <div>
                        If any provision of these Terms of Use is held by a court of competent jurisdiction to be invalid, it
                        shall be severed and the remaining provisions shall remain in full force without being invalidated
                        in any way.
                    </div>
                    <div>
                        {this.company} shall be excused from performance under these Terms of Use if we are prevented, forbidden or
                        delayed from performing, or omits to perform, any act or requirement under these Terms of use
                        by reason of: (a) any provision of any present or future law or regulation, (b) any act or omission
                        of a third party, or (c) any act of God, emergency condition, or war, or (d) computer or
                        telecommunications or other technological failure or other circumstance beyond the control of {profileConstant.COMPANY_NAME}
                    </div>
                    <div>
                        The terms, provisions, covenants, and conditions contained in these Terms of Use which, by
                        their terms, require their performance after the expiration or other termination of these Terms of
                        Use will be and remain enforceable notwithstanding the expiration or other termination of these
                        Terms of Use for any reason whatsoever.
                    </div>
                </div>
                <div className="section">
                    <div className="title">
                        MODIFICATIONS, AMENDMENTS and ASSIGNMENT
                    </div>
                    <div>
                        {this.company} may, in its sole discretion, modify or revise these Terms of Use, its <a href="#" onClick={this.handlePrivacyPolicy}>Privacy Policy</a> or its <Link to="#" onClick={this.handleFeesModalOpen}>Patient Fee Schedule</Link> from time to time without notice and you agree to be bound by such modifications or
                        revisions. Your continued use of the Portal constitutes your acceptance of such modifications.
                        You should visit these pages periodically to review the Terms of Use, Privacy Policy, and
                        Patient Fee Schedule. Nothing in these Terms of Use shall be deemed to confer any third party
                        rights or benefits.
                    </div>
                    <div>
                        We may change, withdraw, or terminate the information, material, or any service or function
                        provided on the Portal, at any time at our sole discretion without notice. We are not liable if, for
                        any reason, all or any part of the Portal is restricted to Users or unavailable at any time or for
                        any period.
                    </div>
                </div>
                <PrivacyPolicyDialog />
                <Dialog
                    open={this.state.showFeesModal}
                    onClose={this.handleFeesModalClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Fees"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {/* Please note that prices include all applicable taxes. */}
                            {/* Please note that given the COVID-19 pandemic ALL our services are free if you have a valid B.C. Services Card (CareCard) or equivalent provincial health card (Alberta, Ontario, etc.). */}
                            Please note that given the COVID-19 pandemic ALL our services are free if you have a valid B.C. Services Card (CareCard). Usually this service would be private pay and cost $20 for a prescription containing up to 2 medications and $10 more for each subsequent medication.
                        </DialogContentText>
                        {/* <hr />
                        <DialogContentText>
                            <span className="m-3">1st prescription: <strong>$20.00</strong></span>
                        </DialogContentText>
                        <DialogContentText>
                            <span className="m-3">Subsequent prescription(s): <strong>$10.00</strong></span>
                        </DialogContentText>
                        <DialogContentText>
                            <span className="m-3">Sleep apnea assessment request: <strong>$20.00</strong></span>
                        </DialogContentText> */}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleFeesModalClose} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }

    render() {
        const { openTermsOfUse } = this.props;

        return (
            <AgreementDialog
                open={openTermsOfUse}
                title="Terms of Use"
                content={this.buildContent()}
                onClose={(checked) => this.handleClose(checked)}
            />
        );
        
    }
}

TermsOfUseDialog.propTypes = {
    dispatch: PropTypes.func,
    openTermsOfUse: PropTypes.bool
};


function mapStateToProps(state) {
    let { openTermsOfUse } = state.registration;

    if (openTermsOfUse === undefined) {
        openTermsOfUse = false;
    }

    return {
        openTermsOfUse
    };
}

const temp = connect(mapStateToProps)(TermsOfUseDialog);

export { temp as TermsOfUseDialog };