import { loadingConstant, userConstant } from '../_constants';

export function loading(state = {}, action) {
    switch (action.type) {
        case loadingConstant.OPEN:
            return {
                messages: action.messages,
                isOpen: true
            };
        case loadingConstant.CLOSE:
            return {
                isOpen: false
            };
        case userConstant.LOGOUT:
            return {};
        default:
            return state;
    }
}