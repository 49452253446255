export const registrationConstant = {
    REGISTER_REQUEST: 'REGISTRATION_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'REGISTRATION_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'REGISTRATION_REGISTER_FAILURE',

    IS_ACTIVATED: 'REGISTRATION_IS_ACTIVATED',
    CONFIRM_CHANGE: 'REGISTRATION_CONFIRM_CHANGE',
    ACTIVATED: 'REGISTRATION_ACTIVATED',

    OPEN_PRIVACY_POLICY: 'REGISTRATION_OPEN_PRIVACY_POLICY',
    CLOSE_PRIVACY_POLICY: 'REGISTRATION_CLOSE_PRIVACY_POLICY',
    OPEN_TERMS_OF_USE: 'REGISTRATION_OPEN_TERMS_OF_USE',
    CLOSE_TERMS_OF_USE: 'REGISTRATION_CLOSE_TERMS_OF_USE',
    OPEN_PHARMANET_CONSENT: 'REGISTRATION_OPEN_PHARMANET_CONSENT',
    CLOSE_PHARMANET_CONSENT: 'REGISTRATION_CLOSE_PHARMANET_CONSENT'
};
