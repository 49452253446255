import { prescriptionService, paymentService, callService } from '../_services';
import { prescriptionConstant } from '../_constants';
import { alertAction, paymentAction, messageAction,profileAction } from './';
import { history, errorHandling, calculatePrescriptionCharge, calculatePrescriptionRefund } from '../_helpers';

export const prescriptionAction = {
    getById,
    getFaxedOrRefunded,
    getByPerson,
    getPendingByPerson,
    request,
    change,
    create,
    tableChange,
    openPrescriptionDialog,
    closePrescriptionDialog,
    openInvoiceDialog,
    closeInvoiceDialog,
    updateMedicationStatus,
    updateMedicationUsage,
    goToPrescription,
    acknowledge,
    print,
    charge,
    refund,
    createAndPending,
    saveText,
    updateMedicationAndUsage,
    updateDrPharmacy,
    TwilioCallToPatients,
    updateTwilioCallRanges
};

function getById(prescriptionId) {
    return dispatch => {
        prescriptionService.getById(prescriptionId)
            .then(
                res => {
                    dispatch({
                        type: prescriptionConstant.GET_BY_ID,
                        prescription: res.data
                    });
                },
                error => {
                    errorHandling('prescription.action.getById', error, dispatch);
                }
            );
    };
}

function getFaxedOrRefunded() {
    return dispatch => {
        prescriptionService.getFaxedOrRefunded()
            .then(
                res => {
                    dispatch({
                        type: prescriptionConstant.GET_FAXED,
                        prescriptions: res.data
                    });
                },
                error => {
                    errorHandling('prescription.action.getFaxedOrRefunded', error, dispatch);
                }
            );
    };
}

function getByPerson(personId) {
    return dispatch => {
        prescriptionService.getByPerson(personId)
            .then(
                res => {
                    dispatch({
                        type: prescriptionConstant.GET_BY_PERSON,
                        prescriptions: res.data
                    });
                },
                error => {
                    errorHandling('prescription.action.getByPerson', error, dispatch);
                }
            );
    };
}

function getPendingByPerson(personId) {
    return dispatch => {
        prescriptionService.getPendingByPerson(personId)
            .then(
                res => {
                    dispatch({
                        type: prescriptionConstant.GET_PENDING_BY_PERSON,
                        prescriptions: res.data
                    });
                },
                error => {
                    errorHandling('prescription.action.getPendingByPerson', error, dispatch);
                }
            );
    };
}

function request() {
    return {
        type: prescriptionConstant.REQUEST
    };
}

function change(prescriptionObj) {
    return {
        type: prescriptionConstant.CHANGE,
        prescriptionObj
    };
}

function create(prescriptionObj) {
    return dispatch => {
        prescriptionService.create(prescriptionObj)
            .then(
                res => {
                    const prescription = res.data.prescription;

                    dispatch({
                        type: prescriptionConstant.CREATE,
                        prescriptionObj: prescription
                    });
                    dispatch(paymentAction.checkout(prescription));
                    dispatch(alertAction.success('Prescription requested successfully.'));
                },
                error => {
                    errorHandling('prescription.action.create', error, dispatch);
                }
            );
    };
}

function createAndPending (prescriptionObj) {
    return async dispatch => {
        try {
            const res = await prescriptionService.createAndPending(prescriptionObj);
            const prescription = res.data.prescription;
            dispatch({
                type: prescriptionConstant.CREATE,
                prescriptionObj: prescription
            });
            return res.data.prescription;
            // history.push(`/prescription/${prescription._id}`);
            // dispatch(alertAction.success('Prescription requested successfully.'));    
        } catch (error) {
            errorHandling('prescription.action.create', error, dispatch);
        }      
    };
}

function tableChange(tableObj) {
    return {
        type: prescriptionConstant.TABLE_CHANGE,
        tableObj
    };
}

function openPrescriptionDialog(prescription, isText = false) {
    return {
        type: prescriptionConstant.OPEN_PRESCRIPTION_DIALOG,
        prescription,
        isText
    };
}

function closePrescriptionDialog() {
    return {
        type: prescriptionConstant.CLOSE_PRESCRIPTION_DIALOG
    };
}

function openInvoiceDialog(prescription) {
    return dispatch => {
        dispatch(paymentAction.getCardByCharge(prescription.payment.chargeId));
        dispatch({
            type: prescriptionConstant.OPEN_INVOICE_DIALOG,
            prescription
        });
    };
}

function closeInvoiceDialog() {
    return {
        type: prescriptionConstant.CLOSE_INVOICE_DIALOG
    };
}

function updateMedicationStatus(prescription, medication, status) {
    return dispatch => {
        prescriptionService.updateMedicationStatus(prescription, medication, status)
            .then(
                res => {
                    dispatch({
                        type: prescriptionConstant.MEDICATION_STATUS_UPDATED,
                        prescriptions: res.data.prescriptions
                    });
                },
                error => {
                    errorHandling('prescription.action.updateMedicationStatus', error, dispatch);
                }
            );
    };
}

function updateMedicationUsage(prescription, medication, usage) {
    return dispatch => {
        prescriptionService.updateMedicationUsage(prescription, medication, usage)
            .then(
                res => {
                    dispatch({
                        type: prescriptionConstant.UPDATE_MEDICATION_USAGE_SUCCESS,
                        prescriptions: res.data.prescriptions
                    });
                },
                error => {
                    errorHandling('prescription.action.updateMedicationUsage', error, dispatch);
                }
            );
    };
}

function updateMedicationAndUsage(prescription, medication, usage) {
    return dispatch => {
        prescriptionService.updateMedicationAndUsage(prescription, medication, usage)
            .then(
                res => {
                    dispatch({
                        type: prescriptionConstant.UPDATE_MEDICATION_USAGE_SUCCESS,
                        prescriptions: res.data.prescriptions
                    });
                },
                error => {
                    errorHandling('prescription.action.updateMedicationUsage', error, dispatch);
                }
            );
    };
}

function goToPrescription(selectPrescriptionId) {
    return {
        type: prescriptionConstant.GO_TO_PRESCRIPTION,
        selectPrescriptionId
    };
}

function acknowledge(prescription) {
    return dispatch => {
        const { _id, message, person } = prescription;

        prescriptionService.acknowledge(_id, message._id, person._id)
            .then(
                () => {
                    dispatch({
                        type: prescriptionConstant.ACKNOWLEDGE_SUCCESS
                    });
                    dispatch(messageAction.countUnread());
                    dispatch(getPendingByPerson(person._id));
                },
                error => {
                    errorHandling('prescription.action.acknowledge', error, dispatch);
                }
            );
    };
}

function print(prescription) {
    return dispatch => {
        const { _id, person } = prescription;

        prescriptionService.print(_id, person._id)
            .then(
                res => {
                    dispatch({
                        type: prescriptionConstant.PRINT_SUCCESS,
                        prescriptions: res.data
                    });
                },
                error => {
                    errorHandling('prescription.action.print', error, dispatch);
                }
            );
    };
}

function charge(tokenId, prescription) {
    return async (dispatch) => {
        try {
            const amount = calculatePrescriptionCharge(prescription.medications);

            // old api
            // const res = await paymentService.charge(tokenId, amount, 'Prescription charge');
            // await prescriptionService.paymentCompleted(prescription._id, prescription.person._id, res.data._id);

            // new api
            await prescriptionService.setPayV2(prescription._id, tokenId, amount);

            
            dispatch(alertAction.success('Charge prescription payment successful.'));
            // history.push(`/prescription/${prescription._id}`);
        } catch (error) {
            errorHandling('prescription.action.charge', error, dispatch);
        }
    };
}

function refund(prescription) {
    return async (dispatch) => {
        try {
            const amount = calculatePrescriptionRefund(prescription.medications);
            await paymentService.refund(prescription.payment.chargeId, amount);
            const res = await prescriptionService.refundSuccess(prescription._id, prescription.person._id);
            
            dispatch({
                type: prescriptionConstant.REFUND_SUCCESS,
                prescriptions: res.data
            });

            dispatch(alertAction.success('Refund prescription payment successful.'));
        } catch (error) {
            errorHandling('prescription.action.refund', error, dispatch);
        }
    };
}

function saveText(prescriptionId, text = '') {
    return async (dispatch) => {
        try {
            const res = await prescriptionService.saveText(prescriptionId, text);
            dispatch({
                type: prescriptionConstant.SET_TEXT,
                id: prescriptionId,
                text
            });
        } catch (error) {
            errorHandling('prescription.action.saveText', error, dispatch);
            throw (error);
        }
    }
}

function updateDrPharmacy(prescription){
    return async (dispatch) => {
        try {
            const res = await prescriptionService.updateDrPharmacy(prescription);
            const updatedPrescription = res.data.prescriptions.find(({ _id }) => {
                return _id == prescription._id
            });
            dispatch({
                type: prescriptionConstant.SET_DR_PHARMACY,
                id: prescription._id,
                drPharmacy: updatedPrescription.drPharmacy,
            });
        } catch (error) {
            errorHandling('prescription.action.update doctor pharmacy', error, dispatch);
            throw (error);
        }
    }
}

function TwilioCallToPatients(prescriptionId, userId, userBestPhone){
    return async (dispatch) => {
        try {
            const res = await prescriptionService.TwilioCallToPatients(prescriptionId, userId, userBestPhone);
            dispatch({
                type: prescriptionConstant.CALL_TO_PATIENT,
                nextCallTime: res.data.nextTime,
                isCallAvailable: res.data.isCallAvailable
            });
        } catch (error) {
            errorHandling('prescription.action.TwilioCallToPatients', error, dispatch);
            throw (error);
        }
    }
}

function updateTwilioCallRanges (ranges, prescriptionId) {
    return async (dispatch) => {
        try {
            const res = await callService.updateTwilioCallRanges(ranges, prescriptionId);
            dispatch({
                type: prescriptionConstant.UPDATE_CALL_RANGE,
                callRanges:ranges
            })
        } catch (error){
            errorHandling('prescription.action.updateTwilioCallRanges', error, dispatch);
            throw (error);
        }

    }
}

