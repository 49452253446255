
import axios from 'axios';
import config from '../config';
import { getToken } from '../_helpers';


export const medicalInfoService = {
    getByPerson,
    getById,
    save
};

function getByPerson(personId) {
    const url = `${config.apiHost}/medicalinfo/person/${personId}`;

    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.get(url);
}

function getById(id) {
    const url = `${config.apiHost}/medicalinfo/${id}`;
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.get(url);
}

function save(medicalInfoObj) {
    const url = `${config.apiHost}/medicalinfo/`;

    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.post(url, medicalInfoObj);
}