import React from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import { connect } from 'react-redux';
import { patientAction } from '../../_actions';
import { formatDate, setStringCapital } from '../../_helpers';
import { BaseTable, PersonIcon } from '../../components';


class PatientsPage extends BaseTable {
    constructor(props) {
        super(props);
        this.state = {
            orderByLastName: 'LastName',
            orderByFirstName: 'FirstName',
            orderByDate: 'CreatedAt',
            isCreatedAtAcs: true,
            isNameAcs: true,
            isOrderByName: true,
            isOrderByDate:false,
            page: props.page,
            rowsPerPage: props.rows,
            isDatePressed:false,
            isLastNamePressed:true,
            isFirstNamePressed:false,
            inputKey:'',
            
        };
    }

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(patientAction.getPatients());
    }

    selectPatient = (user) => {
        const { dispatch } = this.props;
        dispatch(patientAction.selectPatient(user.Person._id));
        this.props.clickPatient(user.Person._id);
    }

    handleOrderByCreated = () => {
        const { isCreatedAtAcs} = this.state;
        this.setState({
            isOrderByName: false,
            isOrderByDate: true,
            isCreatedAtAcs: !isCreatedAtAcs,
            isDatePressed:true,
            isLastNamePressed:false,
            isFirstNamePressed:false, 
        })
    }

    handleOrderByFirstName = () => {
        const { isNameAcs } = this.state;
        this.setState({
            isOrderByName: true,
            isOrderByDate: false,
            isNameAcs: !isNameAcs,
            isLastNamePressed:false,
            isFirstNamePressed:true,
            isDatePressed:false,
        })
    }

    handleOrderByLastName = () => {
        const { isNameAcs } = this.state;
        this.setState({
            isOrderByName: true,
            isOrderByDate: false,
            isNameAcs: !isNameAcs,
            isLastNamePressed:true,
            isFirstNamePressed:false,
            isDatePressed:false,

        })
    }

    handleChange= ( e ) => {
        this.setState({
            [e.target.name]:e.target.value,
        })

    }

    patientsListsBySearch = (users) => {
        const { rowsPerPage, page, orderBy, orderByDate,isNameAcs, isOrderByName, isOrderByDate, isCreatedAtAcs, isFirstNamePressed } = this.state;
        if (isOrderByName){
            let orderOption;
            orderOption = isNameAcs ? 'asc' : 'desc';
            let orderOptionByName;
            orderOptionByName = isFirstNamePressed ? 'FirstName': 'LastName';
        return (
            this.patientStableSort(users, this.patientsGetSorting(orderOption, orderOptionByName))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(user => {

                    let firstName = (user.Person || {}).FirstName;
                    firstName = setStringCapital(firstName);
                    
                    let lastName = (user.Person || {}).LastName;
                    lastName = setStringCapital(lastName);

                    let style = user.Person ? { cursor: 'pointer' } : { cursor: 'not-allowed' };
                    if (this.props.selectId == user.Person._id)
                        style = { ...style, borderStyle: "dotted", borderColor: "#17a2b8" };     
                    return (
                        <TableRow key={user._id} className="row" style={style}
                            onClick={() => this.selectPatient(user)}
                        >
                            <TableCell>
                                {user.Person &&(firstName || '')}
                            </TableCell>
                            <TableCell>
                                {user.Person &&(lastName|| '')}
                            </TableCell>

                            <TableCell>
                                {user.Email}
                            </TableCell>
                            <TableCell>
                                {user.Person.HealthDocuments && ((user.Person.HealthDocuments[0] || { }).DocumentNumber || '')}
                            </TableCell>
                            <TableCell>
                                {user.Person.DateOfBirth && formatDate(user.Person.DateOfBirth)}
                            </TableCell>
                            <TableCell>
                                {formatDate(user.CreatedAt)}
                            </TableCell>
                        </TableRow>
                    );
                })
        )} else if ( isOrderByDate ){
            let orderOption;
            orderOption= isCreatedAtAcs ? 'asc' :'desc';
            
                return(
                    this.stableSort(users, this.getSorting(orderOption, orderByDate))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(user => {
                        let firstName = (user.Person || {}).FirstName;
                        firstName = setStringCapital(firstName);
                        
                        let lastName = (user.Person || {}).LastName;
                        lastName = setStringCapital(lastName);

                        let style = user.Person ? { cursor: 'pointer' } : { cursor: 'not-allowed' };
                        if (this.props.selectId == user.Person._id)
                            style = {...style, borderStyle: "dotted", borderColor: "#17a2b8"};
                            
                        return (
                            <TableRow key={user._id} className="row" style={style}
                                onClick={() => this.selectPatient(user)}
                            >
                                {/* <TableCell>
                                    {user.Person && <PersonIcon person={user.Person} />}
                                </TableCell> */}
                                <TableCell>
                                {user.Person &&(firstName || '')}
                                 </TableCell>
                               <TableCell>
                                {user.Person &&(lastName|| '')}
                                </TableCell>
                                <TableCell>
                                    {user.Email}
                                </TableCell>
                                <TableCell>
                                {user.Person.HealthDocuments && ((user.Person.HealthDocuments[0] || { }).DocumentNumber || '')}
                                </TableCell>
                                <TableCell>
                                {user.Person.DateOfBirth && formatDate(user.Person.DateOfBirth)}
                                </TableCell>
                                <TableCell>
                                    {formatDate(user.CreatedAt)}
                                </TableCell>
                            </TableRow>
                        )
                    })
                )
      
        }  
    }

    renderTable() {
        let { users = [] } = this.props;
        const { rowsPerPage, page, isCreatedAtAcs, isNameAcs, inputKey, isDatePressed, isLastNamePressed, isFirstNamePressed } = this.state;
        users = users.filter(user => {
            const { Person } = user;
            const { LastName = '', FirstName = '', HealthDocuments = [], DateOfBirth = '' } = Person;
            const  cardNumber = (HealthDocuments[0] || { }).DocumentNumber || '';
            
            return LastName.toLowerCase().indexOf(inputKey.toLowerCase()) > -1 
                || FirstName.toLowerCase().indexOf(inputKey.toLowerCase()) > -1 
                || cardNumber.toLowerCase().indexOf(inputKey.toLowerCase()) > -1 
                || formatDate(DateOfBirth).toLowerCase().indexOf(inputKey.toLowerCase()) > -1
                || DateOfBirth.toLowerCase().indexOf(inputKey) > -1;
        });
        let btnClassCreatedAt = "btn btn-sm ml-4";
        let btnClassLastName = "btn btn-sm ml-4";
        let btnClassFirstName = "btn btn-sm ml-4";
        btnClassCreatedAt +=  isDatePressed ? ' btn-warning' :' btn-outline-secondary';
        btnClassLastName += isLastNamePressed ? ' btn-warning' :' btn-outline-secondary';
        btnClassFirstName += isFirstNamePressed ? ' btn-warning' :' btn-outline-secondary';

        if (users) {
            return (
                <Paper>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    FirstName 
                                    <button className={ btnClassFirstName } onClick = {this.handleOrderByFirstName}>{isNameAcs ? (<i class="fa fa-arrow-up" aria-hidden="true"/>) : (<i class="fa fa-arrow-down" aria-hidden="true"/>)}</button>
                                </TableCell>
                                <TableCell>
                                     LastName 
                                    <button className={ btnClassLastName } onClick = {this.handleOrderByLastName}>{isNameAcs ? (<i class="fa fa-arrow-up" aria-hidden="true"/>) : (<i class="fa fa-arrow-down" aria-hidden="true"/>)}</button>
                                </TableCell>
                                <TableCell>Email</TableCell> 
                                <TableCell>Health Number</TableCell>  
                                <TableCell>Date of Birth</TableCell>                             
                                <TableCell>
                                    Date joined
                                    <button className= { btnClassCreatedAt }
                                    onClick = {this.handleOrderByCreated}>{isCreatedAtAcs ? (<i class="fa fa-arrow-up" aria-hidden="true"/>) : (<i class="fa fa-arrow-down" aria-hidden="true"/>) }
                                    </button>
    
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                                {this.patientsListsBySearch(users)}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 15, 20]}
                        component="div"
                        count={users.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        backIconButtonProps={{
                            'aria-label': 'Previous Page',
                        }}
                        nextIconButtonProps={{
                            'aria-label': 'Next Page',
                        }}
                        onChangePage={(e, page, ...rest) => {
                            this.props.setPage(page);
                            this.handleChangePage(e, page, ...rest);
                            // this.setState({page});
                        }}
                        onChangeRowsPerPage={(e, ...rest) => {
                            this.props.setRows(e.target.value);
                            this.handleChangeRowsPerPage(e, ...rest);
                            // this.setState({rowsPerPage: e.target.value});
                        }}
                    />
                </Paper>
            );
        } else {
            return '';
        }
    }

    render() {
        const { inputKey } = this.state;
        return (
            <div className="problem-page">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                        <h1 className="h2">Patients</h1>
                        <div><span style={{borderStyle: "dotted", borderColor: "#17a2b8"}}> {"patient"} </span> <span>{" indicates the patient just clicked on lastly "}</span></div>
                </div>
                <div className="mb-2">
                    <input className="form-control" type="text" placeHolder="Search by first name, last name, date of birth or health card number..." value={inputKey} name="inputKey"
                     onChange={this.handleChange} onFocus={this.handleChange} />
                </div>
                <div>
                    {this.renderTable()}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { users, page = 0, rows = 10, selectId = null } = state.patient;
    const { medicalInfoObj } = state.medicalInfo;

    return {
        users,
        medicalInfoObj,
        page,
        rows,
        selectId
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setPage: (page = 0) => {
            dispatch(patientAction.setPage(page));
        },
        setRows: (rows = 10) => {
            dispatch(patientAction.setRows(rows));
        },
        clickPatient: (id) => {
            dispatch(patientAction.clickPatient(id));
        },
        dispatch
    }
}

const temp = connect(mapStateToProps, mapDispatchToProps)(PatientsPage);

export { temp as PatientsPage };

