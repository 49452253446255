import html2canvas from 'html2canvas';


export default async function getCanvasFromEle(div) {
    const divWidth = div.clientWidth;
    const divHeight = div.clientHeight;
    const ratio = divHeight / divWidth;
    div.style.whiteSpace = "pre-wrap";

    const noteNode = div.getElementsByTagName("textarea")[0];

    if (noteNode) {
        const div = document.createElement("div");
        div.style.margin = "10px";
        div.style.fontSize = "10px";
        div.innerHTML = noteNode.value;
        noteNode.replaceWith(div);
    }

    const canvas = await html2canvas(div, {
        scale: 5,
        allowTaint : true,
        useCORS: true,
        y: window.scrollY + div.getBoundingClientRect().top,
        x: window.scrollX + div.getBoundingClientRect().left
    });

    return canvas;
}
