import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { prescriptionAction } from '../_actions';
import { isDoctor } from '../_helpers';
import { StatusBadge } from './';
import { DrugUsage } from './DrugUsage';
import { DrugSearchDumb } from './DrugSearchDumb';

class Medication extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isEdit: false
        };      
    }

    updateIsEdit = (isEdit) => {
        this.setState({
            isEdit
        });
    }

    handleEdit = () => {
        this.updateIsEdit(true);
    }

    handleCancel = () => {
        this.updateIsEdit(false);
    }

    approve = (medication) => {
        const { dispatch, prescription } = this.props;
        dispatch(prescriptionAction.updateMedicationStatus(prescription, medication, 'Approved'));
    }

    reject = (medication) => {
        const { dispatch, prescription } = this.props;
        dispatch(prescriptionAction.updateMedicationStatus(prescription, medication, 'Rejected'));
    }

    undo = (medication) => {
        const { dispatch, prescription } = this.props;
        dispatch(prescriptionAction.updateMedicationStatus(prescription, medication, undefined));
    }

    updateUsage = (medication, usage) => {
        const { dispatch, prescription } = this.props;
        if (isDoctor())
            dispatch(prescriptionAction.updateMedicationAndUsage(prescription, medication, usage));
        else
            dispatch(prescriptionAction.updateMedicationUsage(prescription, medication, usage));

        this.updateIsEdit(false);
    }

    renderEdit() {
        const { prescription } = this.props;
        let isFaxed;
        let isRefunded;

        if (prescription) {
            isFaxed = prescription.statuses.find(o => o === 'Faxed');
            isRefunded = prescription.statuses.find(o => o === 'Refunded');
        }

        if (!this.state.isEdit && isFaxed === undefined && isRefunded === undefined) {
            return (
               (<div className="edit">
                    <a href="" onClick={(e) => {
                        e.preventDefault();
                        this.handleEdit();
                    }}>Edit</a>
                </div>)
            );
        } else {
            return '';
        }
    }

    renderAction(item) {
        const { prescription } = this.props;
        const isFaxed = prescription.statuses.find(o => o === 'Faxed');
        const isRefunded = prescription.statuses.find(o => o === 'Refunded');

        if (isDoctor() && prescription.message && prescription.message.isRead
            && !this.state.isEdit && isFaxed === undefined && isRefunded === undefined) {
            return (
                <div className="action">
                    <div>
                        {item.status == undefined && 
                            <button className="btn btn-outline-success btn-xs" onClick={() => this.approve(item)}>
                                Approve
                            </button>
                        }
                    </div>
                    <div>
                        {item.status == undefined && 
                            <button className="btn btn-outline-danger btn-xs mt-2" onClick={() => this.reject(item)}>
                                Reject
                            </button>
                        }
                    </div>
                    <div>
                        {item.status != undefined &&
                            <button className="btn btn-outline-secondary btn-xs" onClick={() => this.undo(item)}>
                                <i className='fas fa-undo-alt'></i>
                            </button>
                        }
                    </div>
                </div>
            );
        } else {
            return '';
        }
    }

    renderIngredients = (items) => {
        return (
            <div className="ingredients">
                {items.map((item) => {
                    return ' ' + item.ingredient_name + ' ' + item.strength + ' ' + item.strength_unit + '; ';
                })}
            </div>
        );
    }

    getUsage() {
        let usage;
        const { item, isOriginal } = this.props;
        
        if (isOriginal)
            usage = item.usages[0];
        else 
            usage = [...item.usages].pop();
        
        return usage;
    }

    renderUsage(item) {
        const { onAddUsage, onCancelUsage, onEditUsage, hideDuration } = this.props;

        if (onAddUsage) {
            return (
                <DrugUsage 
                    isEdit={true} 
                    hideDuration={hideDuration} 
                    onAdd={(usage) => onAddUsage(usage)}
                    onCancel={onCancelUsage}
                    isOther={isDoctor()}
                />
            );
        } else if (onEditUsage)  {
            const usage = this.getUsage(); //[...item.usages].pop();
            
            return (
                <DrugUsage usage={usage}  isEdit={this.state.isEdit} hideDuration={hideDuration} 
                    onCancel={this.handleCancel} onUpdate={(usage) => {
                        onEditUsage(item, usage);
                        this.updateIsEdit(false);
                    }}
                    isOther={isDoctor()}
                />
            );
        } else {
            const usage = this.getUsage(); //[...item.usages].pop();
            return (
                <DrugUsage usage={usage} isEdit={this.state.isEdit} hideDuration={hideDuration} 
                    onCancel={this.handleCancel} onUpdate={(usage) => {
                        if (this.medication)
                            item = {...item, ...this.medication};

                        this.updateUsage(item, usage)
                    }}
                    isOther={isDoctor()}
                />
            );
        }
    }

    renderRightPanel() {
        const { item, action, allowEdit, handleDelete } = this.props;

        if ((handleDelete || allowEdit || action) && !this.state.isEdit) {
            return (
                <div className="right-panel">
                    {handleDelete &&
                        <div className="remove" onClick={() => handleDelete(item)}>
                            <i className="fa fa-remove"></i>
                        </div>
                    }
                    {allowEdit && this.renderEdit()}
                    {action && this.renderAction(item)}
                </div>
            );
        } else {
            return '';
        }
    }

    renderDrugSearch() {
        const { item: itemFromProps, isOriginal = false } = this.props;
        const item = {...itemFromProps};

        if ((item.changes || []).length > 0 && !isOriginal) {
            const lastChange = item.changes[item.changes.length - 1];
            item.name = lastChange.name;
            item.administrations = lastChange.administrations;
            item.formulations = lastChange.formulations;
            item.ingredients = lastChange.ingredients;
        }

        return <DrugSearchDumb item={item} onSelect={(medication = null) => {
            this.medication = medication;
        }}/>;
    }

    render() {
        const { type, item: itemFromProps, isStatus = true, isOriginal = false } = this.props;
        
        const item = {...itemFromProps};
        if ((item.changes || []).length > 0 && !isOriginal) {
            const lastChange = item.changes[item.changes.length - 1];
            item.name = lastChange.name;
            item.administrations = lastChange.administrations;
            item.formulations = lastChange.formulations;
            item.ingredients = lastChange.ingredients;
        }

        const classes = type === 'print' ? 'print badge-medication' : 'badge-medication';

        return (
            <div className="medication">
                <div className={classes}>
                    <div className="left-panel">
                        {this.state.isEdit && isDoctor()
                            ? this.renderDrugSearch()
                            : <React.Fragment>
                                <div className="drug-title">
                                    <div>
                                        <span className="drug-name">
                                            {item.name}
                                        </span>
                                        <span className="drug-description">
                                            ({item.administrations[0]}/{item.formulations[0]})
                                        </span>
                                    </div>
                                    {type !== 'print' && isStatus && <StatusBadge statusObj={{ text: item.status }} />}
                                </div>
                                {this.renderIngredients(item.ingredients)}
                            </React.Fragment>
                        }
                        {this.renderUsage(item)}
                    </div>
                    {this.renderRightPanel()}
                </div>
            </div>
        );
    }
}


Medication.propTypes = {
    dispatch: PropTypes.func,
    handleDelete: PropTypes.func,
    prescription: PropTypes.object,
    allowEdit: PropTypes.bool,
    action: PropTypes.bool,
    item: PropTypes.object,
    type: PropTypes.string,
    onAddUsage: PropTypes.func,
    onCancelUsage: PropTypes.func,
    onEditUsage: PropTypes.func,
    hideDuration: PropTypes.bool
};


const temp = connect()(Medication);

export { temp as Medication };