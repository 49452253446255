import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import moment from 'moment';
import config from '../../config';
import { RequiredLabel, DateOfBirth } from '../../components';
import { userService } from '../../_services';


// const isNativeDatePicker = false;
class Recovery extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userRecovery: null,
            isConfirmed: false
        };
        this.onDateChange = this.onDateChange.bind(this);
    }

    /**
     * Get the value from the inputs form
     */
    changeValue = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    /**
     * Submit the form sending a post request to the API - Searching the user
     */
    submitFormSearch = (e) => {
        e.preventDefault();

        const url = `${config.apiHost}/user/recovery/search`;

        axios.post(url, this.state)
            .then(res => {
                this.setState({ userRecovery: res.data, ErrorMessage: null });
            }).catch(error => {
                this.setState({ userRecovery: null, ErrorMessage: error.response.data.message });
            });
    }

    /**
     * Submit the send email request to the API 
     */
    submitConfirm = async (event) => {
        event.preventDefault();
        try {
            const response = await userService.sendEmailInvitation(this.state.userRecovery.Email);
            this.setState({
                isConfirmed: true
            })
        } catch (error){
            this.setState({ErrorMessage: error.response.data.message});
        }  
        };
    
    /**
     * submit cancel and go back to login page
     */
    submitCancel = async (event) => {
        event.preventDefault();
        this.setState({ ErrorMessage: null });
        this.props.history.push('/login');
    }

    /**
     * Submit the form sending a post request to the API - Recovering the password
     */
    submitFormPassword = (e) => {
        e.preventDefault();

        const url = `${config.apiHost}/user/recovery/password`;

        axios.post(url, {
            Id: this.state.userRecovery._id,
            Email: this.state.userRecovery.Email,
            Password: this.state.Password
        }).then(() => {
            this.setState({ ErrorMessage: null });
            this.props.history.push('/login');
        }).catch(error => {
            this.setState({ ErrorMessage: error.response.data.error ? this.toStringErrors(error.response.data.error) : error.response.data.message });
        });
    }

    /**
     * To string of an array of errors from API
     */
    toStringErrors(errors) {
        if (errors !== null && errors.length > 0) {
            let stringErrors = 'Error: ';
            errors.map(item => stringErrors += item.message + ' ');
            return stringErrors;
        } else {
            return 'Sorry. Unknown error. Contact the administrator.';
        }
    }

    onDateChange(date) {
        this.setState({
            DateOfBirth: moment(date).format('YYYY-MM-DD')
        });
    }

    removeError = (e) => {
        e.preventDefault();
        this.setState({
            ErrorMessage: null
        });
    }

   



    /**
     * Render the HTML template
     */
    render() {

        if (this.state.isConfirmed) 
            return <div className="container">
                <h4>
                    A link to reset your password was sent to the email address we have on file. Please login to your email and click on this link to proceed with your password recovery.
                </h4>
            </div>

        let ErrorAlert = <div/>;

        if (this.state.ErrorMessage) {
            ErrorAlert =
                <div className="alert alert-danger alert-register alert-dismissible fade show" /*role="alert"*/>
                    {this.state.ErrorMessage}
                    <button 
                        type="button" 
                        className="close" 
                        /*data-dismiss="alert" */
                        aria-label="Close"
                        onClick={this.removeError}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>;
        }

        let formShow = null;
        if (!this.state.userRecovery) {
            formShow =
                <div className="btn-bar">
                    <button className="btn btn-primary btn-lg btn-sign-up" type="submit" onClick={this.submitFormSearch}>Validate my information</button>
                </div>;


        } else {
            // formShow =
            //     <div>
            //         <hr className="mb-4" />

            //         <h4 className="my-3">User found</h4>
            //         <div className="row">
            //             <div className="col-md-6 mb-2">
            //                 <label htmlFor="Name">Name</label>
            //                 <input type="email" className="form-control" id="Name" name="Name" value={this.state.userRecovery.Person.FirstName + ' ' + this.state.userRecovery.Person.LastName} disabled />
            //             </div>

            //             <div className="col-md-6 mb-2">
            //                 <label htmlFor="Password">New Password *</label>
            //                 <input type="password" className="form-control" id="Password" name="Password" onChange={this.changeValue} />
            //                 <div className="invalid-feedback">
            //                     Password is required.
            //                 </div>
            //             </div>
            //         </div>

            //         <div className="btn-bar">
            //             <button className="btn btn-success btn-lg btn-sign-up" type="submit" onClick={this.submitFormPassword}>Recover Password</button>
            //         </div>
            //     </div>;

            ///////////////////
            formShow = 
                <div>
                    <hr className="mb-4" />
                    <h4 className="my-3">User found</h4>
                    <div className="row">
                        <p>Please confirm that you want to reset your password. A password reset link will be sent to the email we have on file.</p>
                    </div>
                    <div className="btn-bar">
                        <button className="btn btn-primary btn-lg btn-sign-up mr-4" type="submit" onClick={this.submitConfirm}>Confirm</button>
                        <button className="btn btn-secondary btn-lg btn-sign-up" type="submit" onClick={this.submitCancel}>Cancel</button>
                    </div>
                </div>
        }

        return (
            <div className="container">
                <div className="py-5 text-center">
                    <h2>Password Recovery</h2>
                    <p className="lead">Enter the information below in order to recover your password:</p>
                </div>
                <div className="row">
                    <div className="col-md-10 offset-md-1">
                        {ErrorAlert}
                        <form className="needs-validation form-register">
                            <div className="row">
                                <div className="col-md-4 mb-2">
                                    <label htmlFor="Email">Email *</label>
                                    <input type="email" className="form-control" id="Email" name="Email" onChange={this.changeValue} required disabled={this.state.userRecovery} />
                                    <div className="invalid-feedback">
                                        Email is required.
                                    </div>
                                </div>
                                {/* <div className="col-md-3 mb-2">
                                    <label htmlFor="DateOfBirth">Date of birth *</label>
                                    {isNativeDatePicker 
                                        ? <input type="date" className="form-control" id="DateOfBirth" name="DateOfBirth" onChange={this.changeValue} required disabled={this.state.userRecovery} />
                                        : <DatePicker
                                            selected={this.state.DateOfBirth ? moment(this.state.DateOfBirth).toDate() : null}
                                            onChange={this.onDateChange}
                                            placeholderText='dd/mmm/yyyy'
                                            dateFormat='dd/MMM/yyyy'
                                            disabled={this.state.userRecovery}
                                        />
                                    }

                                    <div className="invalid-feedback">
                                        Date of birth is required.
                                    </div>
                                </div> */}
                                <div className="col-md-4 mb-2">
                                    {/* <RequiredLabel htmlFor="DateOfBirth">
                                        Date of birth 
                                    </RequiredLabel> */}
                                    <label htmlFor="DateOfBirth">Date of birth  *</label>
                                    <DateOfBirth onChange={this.onDateChange} required disabled={this.state.userRecovery}/>
                                    <div className="invalid-feedback">
                                            Date of birth is required.
                                    </div>

                                </div>
                                <div className="col-md-4 mb-2">
                                    <label htmlFor="DocumentNumber">Health document number *</label>
                                    <input type="text" className="form-control" id="DocumentNumber" name="DocumentNumber" onChange={this.changeValue} required disabled={this.state.userRecovery} />
                                    <div className="invalid-feedback">
                                        Health Document number is required.
                                    </div>
                                </div>
                            </div>
                            {formShow}
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}


Recovery.propTypes = {
    history: PropTypes.object
};


const temp = Recovery;

export { temp as Recovery };