import { patientService } from '../_services';
import { patientConstant } from '../_constants';
import { prescriptionAction, medicalInfoAction, noteAction,noteDraftAction, auditAction, sleepInfoAction, sleepAssessmentAction } from './';
import { history, errorHandling } from '../_helpers';


export const patientAction = {
    getPatients,
    selectPatient,
    getPatient,
    setPage,
    setRows,
    clickPatient
};

function getPatients() {
    return dispatch => {
        patientService.getPatients()
            .then(
                res => {
                    dispatch({
                        type: patientConstant.GET_PATIENTS_SUCCESS,
                        users: res.data
                    });
                },
                error => {
                    errorHandling('patient.action.getPatients', error, dispatch);
                }
            );
    };
}

function selectPatient(personId) {
    return () => {
        history.push(`/patient/${personId}`);
    };    
}

function clickPatient(id) {
    return {
        type: patientConstant.SELECT_PATIENT,
        id
    }; 
}

function getPatient(personId) {
    return dispatch => {
        dispatch(prescriptionAction.getPendingByPerson(personId));
        dispatch(medicalInfoAction.getByPerson(personId));
        dispatch(sleepInfoAction.getByPerson(personId));
        dispatch(sleepAssessmentAction.getPendingByPerson(personId));
        dispatch(noteAction.getByPerson(personId));
        dispatch(noteDraftAction.getByPerson(personId));
        
        // reset prescription and note to undefined, won't go to specific prescription or note
        dispatch(prescriptionAction.goToPrescription());
        dispatch(sleepAssessmentAction.goToSleepAssessment());
        dispatch(noteAction.goToNote());

        patientService.getPatient(personId)
            .then(
                res => {
                    dispatch({
                        type: patientConstant.GET_PATIENT_SUCCESS,
                        person: res.data
                    });

                    // create audit record whenever any user access the patient
                    dispatch(auditAction.create(personId));
                },
                error => {
                    errorHandling('patient.action.getPatient', error, dispatch);
                }
            );
    };
}

function setPage(page = 0) {
    return {
        type: patientConstant.SET_PAGE,
        page
    }
}

function setRows(rows = 10) {
    return {
        type: patientConstant.SET_ROWS,
        rows
    }
}

