import { userConstant } from '../_constants';
import { store } from './store';

// export const isAuth = () => {
//     if (getToken() && sessionStorage.getItem('user')) {
//         return true;
//     } else {
//         return false;
//     }
// };

export const isAuth = () => {
    if (getToken() && getUser()) {
        return true;
    } else {
        return false;
    }
}

export const clearSessionStorage = () => {
    sessionStorage.clear();
};

export const getUser = () => {
    const state = store.getState();
    const { user } = state.authentication;
    return user;
};

export const getToken = () => {
    return sessionStorage.getItem('token');
};

export const isDoctor = () => {
    return getUser().Role === userConstant.ROLE_DOCTOR;
};

export const isPatient = () => {
    return getUser().Role === userConstant.ROLE_PATIENT;
};

export const isAdmin = () => {
    return getUser().isAdmin ? true : false;
}

export const isCustomerService = () => {
    return getUser().Role === userConstant.ROLE_CUSTOMERSERVICE;
}

export const getPersonId = () => {
    return getUser().Person._id;
};

export const getPersonName = () => {
    const person = getUser().Person;
    return `${person.FirstName} ${person.LastName}`;
};

export const getPerson = () => {
    return getUser().Person;
};