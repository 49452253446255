import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MedicalInfo } from '../MedicalInfo';
import { prescriptionAction } from '../../_actions';
import { history } from '../../_helpers';

class PrescriptionMedicalInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };

        this.handleEdit = this.handleEdit.bind(this);
        this.handleConfirm = this.handleConfirm.bind(this);
    }

    handleEdit() {
        history.push('/medicalInfo/edit');
    }

    handleConfirm() {
        const { dispatch } = this.props;

        dispatch(prescriptionAction.request());
        history.push('/request-prescription/form');
    }

    render() {
        return (
            <div>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h1 className="h2">Request a Prescription</h1><hr />
                </div>
                <h1 className="h5 mb-3 font-weight-normal">Check your Medical Information</h1>
                <hr />
                <MedicalInfo />
                <hr className="mb-4" />
                <div className="mb-3 d-flex justify-content-between">
                    <button className="btn btn-secondary btn-lg mr-3" onClick={this.handleEdit}>Edit</button>
                    <button 
                        className="btn btn-primary btn-lg btn-sign-up" 
                        onClick={this.handleConfirm}
                        data-test-id="prescription-medical-info__confirm-button"
                    >Confirm</button>
                </div>
            </div>
        );
    }
}


PrescriptionMedicalInfo.propTypes = {
    dispatch: PropTypes.func.isRequired
};


function mapStateToProps(state) {
    const { status, medicalInfoObj } = state.medicalInfo;

    return {
        status,
        medicalInfoObj
    };
}

const temp = connect(mapStateToProps)(PrescriptionMedicalInfo);

export { temp as PrescriptionMedicalInfo };
