import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { RingLoader } from 'react-spinners';


const Loading = ({ message, isOpen }) => {
    return (
        isOpen ? <div className="loading">
            <RingLoader
                sizeUnit={'px'}
                size={120}
                color={'rgb(54, 215, 183)'}
                loading={true}
            />
            <span className="text">
                {message ? message : ''}
            </span>
        </div>
            :
            ''
    );
};


Loading.propTypes = {
    message: PropTypes.string,
    isOpen: PropTypes.bool,
};



function mapStateToProps(state) {
    let { message, isOpen } = state.loading;

    if (isOpen === undefined) {
        isOpen = false;
    }

    return {
        message,
        isOpen
    };
}


const temp = connect(mapStateToProps)(Loading);

export { temp as Loading };