import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { medicalInfoAction, alertAction } from '../../_actions';
import { history, isDoctor, isPatient, getPersonId } from '../../_helpers';
import { PharmacySelect, DrugSearch, RequiredLabel } from '../../components';
import { ICDSelect, OtherSurgery, OtherAllergy } from './';


const styles = () => ({
    root: {
        marginTop: 20,
    }
});

const MAX_LENGTH = 200;
class EditMedicalInfoPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };

        this.handleCancel = this.handleCancel.bind(this);
    }

    componentDidMount() {
        const { dispatch, status, match } = this.props;

        // if medicalInfoObj is changed during edit mode, get the latest one
        if (isPatient() && status === 'changed') {
            dispatch(medicalInfoAction.getByPerson(getPersonId()));
        }

        if (isDoctor() && match.params.id) {
            dispatch(medicalInfoAction.getById(match.params.id));
        }
    }

    handleCancel() {
        history.goBack();
    }

    /**
     * Get the value from the inputs form
     */
    handleChange = event => {
        const { dispatch, medicalInfoObj } = this.props;

        medicalInfoObj[event.target.name] = event.target.value;

        // dispatch messageAction change
        dispatch(medicalInfoAction.change(medicalInfoObj));
    }

    /**
     * Get the drugs items from the component
     */
    handleMedicationsChanged = (medications) => {
        const { dispatch, medicalInfoObj } = this.props;

        medicalInfoObj.MedicationsCurrentlyTaken = medications;

        dispatch(medicalInfoAction.change(medicalInfoObj));
    }

    /**
     * Add the value on the array Medical Conditions
     */
    addMedicalCondition = (event) => {
        const { dispatch, medicalInfoObj } = this.props;

        // add a new item
        if (event.target.checked) {
            // checked no
            if (event.target.value === 'No') {
                medicalInfoObj.NoMedicalCondition = true;
            }
            else if (event.target.value === 'Other') {
                medicalInfoObj.otherMedicalConditions = [];
            }
            else {
                medicalInfoObj.MedicalConditions.push(event.target.value);
            }
        }
        // remove an item
        else {
            // checked no
            if (event.target.value === 'No') {
                medicalInfoObj.NoMedicalCondition = false;
            }
            else if (event.target.value === 'Other') {
                medicalInfoObj.otherMedicalConditions = null;
            }
            else {
                medicalInfoObj.MedicalConditions = medicalInfoObj.MedicalConditions.filter(item => item !== event.target.value);
            }
        }

        // clear regular and other medical condition
        if (medicalInfoObj.NoMedicalCondition) {
            medicalInfoObj.MedicalConditions = [];
            medicalInfoObj.otherMedicalConditions = null;
        }

        dispatch(medicalInfoAction.change(medicalInfoObj));
    }

    /**
     * Add the value on the array Surgeries
     */
    addSurgery = (event) => {
        const { dispatch, medicalInfoObj } = this.props;

        // add new item
        if (event.target.checked) {
            // checked no
            if (event.target.value === 'No') {
                medicalInfoObj.NoSurgery = true;
            }
            else if (event.target.value === 'Other') {
                medicalInfoObj.otherSurgery = [];
            }
            else {
                medicalInfoObj.Surgeries.push(event.target.value);
            }
        }
        // remove an item
        else {
            // checked no
            if (event.target.value === 'No') {
                medicalInfoObj.NoSurgery = false;
            }
            else if (event.target.value === 'Other') {
                medicalInfoObj.otherSurgery = null;
            }
            else {
                medicalInfoObj.Surgeries = medicalInfoObj.Surgeries.filter(item => item !== event.target.value);
            }
        }

        // clear regular and other surgery
        if (medicalInfoObj.NoSurgery) {
            medicalInfoObj.Surgeries = [];
            medicalInfoObj.otherSurgery = null;
        }

        dispatch(medicalInfoAction.change(medicalInfoObj));
    }

    /**
     * Add the value on the array Allergies
     */
    addAllergy = (event) => {
        const { dispatch, medicalInfoObj } = this.props;

        // add new item
        if (event.target.checked) {
            // checked no
            if (event.target.value === 'No') {
                medicalInfoObj.NoAllergy = true;
            }
            else if (event.target.value === 'Other') {
                medicalInfoObj.otherAllergy = [];
            }
            else {
                medicalInfoObj.AllergyToMedication.push(event.target.value);
            }
        }
        // remove an item 
        else {
            // checked no
            if (event.target.value === 'No') {
                medicalInfoObj.NoAllergy = false;
            }
            else if (event.target.value === 'Other') {
                medicalInfoObj.otherAllergy = null;
            }
            else {
                medicalInfoObj.AllergyToMedication = medicalInfoObj.AllergyToMedication.filter(item => item !== event.target.value);
            }
        }

        // clear regular and other allergy
        if (medicalInfoObj.NoAllergy) {
            medicalInfoObj.AllergyToMedication = [];
            medicalInfoObj.otherAllergy = null;
        }

        dispatch(medicalInfoAction.change(medicalInfoObj));
    }

    errorMsgs = [];

    validateForm() {
        this.errorMsgs = [];
        const obj = this.props.medicalInfoObj;

        this.validateField(obj.Sex === '', 'Gender is required');
        this.validateField(!obj.NoMedicalCondition && obj.MedicalConditions.length === 0 && obj.otherMedicalConditions === null, 'Medical condition is required');
        this.validateField(!obj.NoSurgery && obj.Surgeries.length === 0 && obj.otherSurgery === null, 'Surgery is required');
        this.validateField(!obj.NoAllergy && obj.AllergyToMedication.length === 0 && obj.otherAllergy === null, 'Allergy is required');
        this.validateField(obj.AlcoholHistory === '', 'Alcohol history is required');
        this.validateField(obj.SmokingHistory === '', 'Smoking history is required');
        this.validateField(obj.defaultPharmacy === null, 'Preferred pharmacy is required');
        this.validateField(obj.otherMedicalConditions !== null && obj.otherMedicalConditions.length === 0, 'Other medical condition is required');
        this.validateField(obj.otherSurgery !== null && obj.otherSurgery.length === 0, 'Other surgical history is required');
        this.validateField(obj.otherAllergy !== null && obj.otherAllergy.length === 0, 'Other allergy to medications is required');

        // check errorMsg array to see any errors
        if (this.errorMsgs.length === 0) {
            return true;
        }
        else {
            return false;
        }
    }

    validateField(condition, errMsg) {
        if (condition) this.errorMsgs.push(`${errMsg}`);
    }

    /**
     * Submit the form sending a post request to the API
     */
    handleSubmit = (event) => {
        event.preventDefault();

        const { dispatch, medicalInfoObj } = this.props;

        if (this.validateForm()) {
            dispatch(medicalInfoAction.save(medicalInfoObj, () => history.goBack()));
        } else {
            dispatch(alertAction.error('Form validation errors', this.errorMsgs));
        }
    }

    renderMedicalConditionNo() {
        const name = 'medicalConditionGroup';
        const id = 'noMedicalCondition';
        const onChange = this.addMedicalCondition;
        const checked = this.props.medicalInfoObj.NoMedicalCondition;

        return this.renderGenericNo(name, id, onChange, checked);
    }

    renderMedicalConditionCheckbox(id, value) {
        const { medicalInfoObj } = this.props;

        const name = 'medicalConditionGroup';
        const onChange = this.addMedicalCondition;
        const disabled = medicalInfoObj.NoMedicalCondition;
        const checked = medicalInfoObj.MedicalConditions.indexOf(value) > -1;

        return this.renderGenericCheckbox(name, id, value, onChange, disabled, checked);
    }

    renderMedicalConditionOther() {
        const { medicalInfoObj } = this.props;

        const name = 'medicalConditionGroup';
        const id = 'otherMedicalConditionCheckbox';
        const onChange = this.addMedicalCondition;
        const disabled = medicalInfoObj.NoMedicalCondition;
        const checked = medicalInfoObj.otherMedicalConditions !== null;
        const inputName = 'otherMedicalConditions';
        const inputValue = medicalInfoObj.otherMedicalConditions;

        return (
            <div className="col-md-4 mb-2">
                <div className="custom-control custom-checkbox mb-2">
                    <input type="checkbox" name={name} value="Other" id={id} className="custom-control-input"
                        onChange={onChange} disabled={disabled} checked={checked} />
                    <label className="custom-control-label" htmlFor={id}>Other</label>
                </div>
                {checked &&
                    <ICDSelect name={inputName} onChange={this.handleChange} value={inputValue} />
                }
            </div>
        );
    }

    renderSurgeryNo() {
        const name = 'surgeryGroup';
        const id = 'noSurgery';
        const onChange = this.addSurgery;
        const checked = this.props.medicalInfoObj.NoSurgery;

        return this.renderGenericNo(name, id, onChange, checked);
    }

    renderSurgeryCheckbox(id, value) {
        const { medicalInfoObj } = this.props;

        const name = 'surgeryGroup';
        const onChange = this.addSurgery;
        const disabled = medicalInfoObj.NoSurgery;
        const checked = medicalInfoObj.Surgeries.indexOf(value) > -1;

        return this.renderGenericCheckbox(name, id, value, onChange, disabled, checked);
    }

    renderSurgeryOther() {
        const { medicalInfoObj } = this.props;

        const name = 'surgeryGroup';
        const id = 'otherSurgeryCheckbox';
        const onChange = this.addSurgery;
        const disabled = medicalInfoObj.NoSurgery;
        const checked = medicalInfoObj.otherSurgery !== null;
        const inputName = 'otherSurgery';
        const inputValue = medicalInfoObj.otherSurgery;

        return (
            <div className="col-md-4 mb-2">
                <div className="custom-control custom-checkbox mb-2">
                    <input type="checkbox" name={name} value="Other" id={id} className="custom-control-input"
                        onChange={onChange} disabled={disabled} checked={checked} />
                    <label className="custom-control-label" htmlFor={id}>Other</label>
                </div>
                {checked &&
                    <OtherSurgery name={inputName} onChange={this.handleChange} value={inputValue} />
                }
            </div>
        );
    }

    renderAllergyNo() {
        const name = 'allgeryGroup';
        const id = 'noAllergy';
        const onChange = this.addAllergy;
        const checked = this.props.medicalInfoObj.NoAllergy;

        return this.renderGenericNo(name, id, onChange, checked);
    }

    renderAllergyCheckbox(id, value) {
        const { medicalInfoObj } = this.props;

        const name = 'allgeryGroup';
        const onChange = this.addAllergy;
        const disabled = medicalInfoObj.NoAllergy;
        const checked = medicalInfoObj.AllergyToMedication.indexOf(value) > -1;

        return this.renderGenericCheckbox(name, id, value, onChange, disabled, checked);
    }

    renderAllergyOther() {
        const { medicalInfoObj } = this.props;

        const name = 'allgeryGroup';
        const id = 'otherAllergyCheckbox';
        const onChange = this.addAllergy;
        const disabled = medicalInfoObj.NoAllergy;
        const checked = medicalInfoObj.otherAllergy !== null;
        const inputName = 'otherAllergy';
        const inputValue = medicalInfoObj.otherAllergy;

        return (
            <div className="col-md-8 mb-2">
                <div className="custom-control custom-checkbox mb-2">
                    <input type="checkbox" name={name} value="Other" id={id} className="custom-control-input"
                        onChange={onChange} disabled={disabled} checked={checked} />
                    <label className="custom-control-label" htmlFor={id}>Other</label>
                </div>
                {checked &&
                    <OtherAllergy name={inputName} onChange={this.handleChange} value={inputValue} />
                }
            </div>
        );
    }

    renderGenericNo(name, id, onChange, checked) {
        return (
            <div className="col-md-12 mb-2">
                <div className="custom-control custom-checkbox">
                    <input type="checkbox" name={name} value="No" id={id} className="custom-control-input" onChange={onChange} checked={checked} />
                    <label className="custom-control-label" htmlFor={id}>
                        <strong>No</strong>
                    </label>
                </div>
            </div>
        );
    }

    renderGenericCheckbox(name, id, value, onChange, disabled, checked) {
        return (
            <div className="custom-control custom-checkbox">
                <input type="checkbox" name={name} value={value} id={id} className="custom-control-input"
                    onChange={onChange} disabled={disabled} checked={checked} />
                <label className="custom-control-label" htmlFor={id}>{value}</label>
            </div>
        );
    }

    render() {
        const { medicalInfoObj } = this.props;

        if (medicalInfoObj === undefined) return '';

        return (
            <div id="edit-medical-info-page">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h1 className="h2">
                        {this.props.noMedicalInfo ? 'Create ' : 'Update '}
                        Medical Information
                        {isDoctor() &&
                            <span className="person">&nbsp;({medicalInfoObj.Person.FirstName} {medicalInfoObj.Person.LastName})</span>
                        }
                    </h1>
                    <hr />
                </div>
                <form className="form-medical-info" onSubmit={this.handleSubmit} noValidate>
                    <h2 className="h5 mb-3 font-weight-normal">Gender</h2>
                    <div className="row">
                        <div className="col-md-4 mb-2">
                            <label htmlFor="Sex">
                                Select your gender
                                <span className="text-danger"><b> *</b></span>
                            </label>
                            <select className="custom-select d-block w-100" id="Sex" name="Sex" onChange={this.handleChange} value={medicalInfoObj.Sex} required>
                                <option value="">Choose...</option>
                                <option value="Female">Female</option>
                                <option value="Male">Male</option>
                                <option value="Transgender">Transgender</option>
                                <option value="Non-binary">Non-binary</option>
                                <option value="Two-spirit">Two-spirit</option>
                                <option value="Unsure/questioning">Unsure/questioning</option>
                            </select>
                        </div>
                    </div>
                    <hr />
                    <h2 className="h5 mb-3 font-weight-normal">Medical conditions</h2>
                    <div className="row">
                        <div className="col-md-12 mb-2">
                            <label htmlFor="MedicalConditionsSelect">
                                Do you have any medical conditions?
                                <span className="text-danger"><b> *</b></span>
                            </label>
                            <p className="microtext">Choose all that apply.</p>
                            <div className="row">
                                {this.renderMedicalConditionNo()}
                            </div>
                            <div className="row">
                                <div className="col-md-4 mb-2">
                                    {this.renderMedicalConditionCheckbox('AlcoholProblemCondition', 'Alcohol problem')}
                                    {this.renderMedicalConditionCheckbox('AnemiaCondition', 'Anemia')}
                                    {this.renderMedicalConditionCheckbox('AnxietyCondition', 'Anxiety')}
                                    {this.renderMedicalConditionCheckbox('ArthritisCondition', 'Arthritis')}
                                    {this.renderMedicalConditionCheckbox('AsthmaCondition', 'Asthma')}
                                    {this.renderMedicalConditionCheckbox('AtrialFibrillationCondition', 'Atrial fibrillation')}
                                    {this.renderMedicalConditionCheckbox('BloodClotsCondition', 'Blood clots')}
                                    {this.renderMedicalConditionCheckbox('CancerCondition', 'Cancer')}
                                    {this.renderMedicalConditionCheckbox('DiabetesCondition', 'Diabetes')}
                                </div>
                                <div className="col-md-4 mb-2">
                                    {this.renderMedicalConditionCheckbox('StreetDrugProblemCondition', 'Street drug problem')}
                                    {this.renderMedicalConditionCheckbox('EmphysemaCondition', 'Emphysema')}
                                    {this.renderMedicalConditionCheckbox('HeartDiseaseCondition', 'Heart disease')}
                                    {this.renderMedicalConditionCheckbox('HepatitisCondition', 'Hepatitis')}
                                    {this.renderMedicalConditionCheckbox('HighBloodPressureCondition', 'High blood pressure')}
                                    {this.renderMedicalConditionCheckbox('HighCholesterolCondition', 'High cholesterol')}
                                    {this.renderMedicalConditionCheckbox('KidneyDiseaseCondition', 'Kidney disease')}
                                    {this.renderMedicalConditionCheckbox('OsteoporosisCondition', 'Osteoporosis')}
                                    {this.renderMedicalConditionCheckbox('PositiveTBorTuberculosisTestCondition', 'Positive TB or tuberculosis test')}
                                </div>
                                <div className="col-md-4 mb-2">
                                    {this.renderMedicalConditionCheckbox('ProstateProblemCondition', 'Prostate problem')}
                                    {this.renderMedicalConditionCheckbox('SeasonalAllergiesCondition', 'Seasonal allergies')}
                                    {this.renderMedicalConditionCheckbox('SeizureDisorderCondition', 'Seizure disorder')}
                                    {this.renderMedicalConditionCheckbox('SleepApneaCondition', 'Sleep apnea')}
                                    {this.renderMedicalConditionCheckbox('StrokeCondition', 'Stroke')}
                                    {this.renderMedicalConditionCheckbox('ThyroidDiseaseCondition', 'Thyroid disease')}
                                    {this.renderMedicalConditionCheckbox('UlcersoftheStomachCondition', 'Ulcers of the stomach')}                                    
                                </div>
                            </div>
                            <div className="row">
                                {this.renderMedicalConditionOther()}
                            </div>
                        </div>
                    </div>
                    <hr />
                    <h2 className="h5 mb-3 font-weight-normal">Surgical history</h2>
                    <div className="row">
                        <div className="col-md-12 mb-2">
                            <label htmlFor="MedicalConditionsSelect">
                                Have you ever had a surgery?
                                <span className="text-danger"><b> *</b></span>
                            </label>
                            <p className="microtext">Choose all that apply.</p>
                            <div className="row">
                                {this.renderSurgeryNo()}
                            </div>
                            <div className="row">
                                <div className="col-md-4 mb-2">
                                    {this.renderSurgeryCheckbox('AppendectomySurgery', 'Appendectomy (remove the appendix)')}
                                    {this.renderSurgeryCheckbox('BreastSurgery', 'Breast surgery')}
                                    {this.renderSurgeryCheckbox('CataractSurgery', 'Cataract surgery')}
                                    {this.renderSurgeryCheckbox('CholecystectomySurgery', 'Cholecystectomy (remove the gallbladder)')}
                                    {this.renderSurgeryCheckbox('ColectomySurgery', 'Colectomy (remove part of the colon or large intestine)')}
                                    {this.renderSurgeryCheckbox('GlaucomaSurgery', 'Glaucoma surgery')}
                                </div>
                                <div className="col-md-4 mb-2">
                                    {this.renderSurgeryCheckbox('HeartBypassSurgery', 'Heart bypass')}
                                    {this.renderSurgeryCheckbox('HeartStentingSurgery', 'Heart stenting')}
                                    {this.renderSurgeryCheckbox('HeartValveSurgery', 'Heart valve surgery')}
                                    {this.renderSurgeryCheckbox('HerniaSurgery', 'Hernia repair')}
                                    {this.renderSurgeryCheckbox('HipReplacementSurgery', 'Hip replacement')}
                                    {this.renderSurgeryCheckbox('HysterectomySurgery', 'Hysterectomy (take out the uterus)')}
                                </div>
                                <div className="col-md-4 mb-2">                                    
                                    {this.renderSurgeryCheckbox('KneeSurgery', 'Knee replacement')}
                                    {this.renderSurgeryCheckbox('OopherectomySurgery', 'Oopherectomy (remove an ovary)')}
                                    {this.renderSurgeryCheckbox('ProstateSurgery', 'Prostate surgery')}
                                    {this.renderSurgeryCheckbox('TonsillectomySurgery', 'Tonsillectomy (remove a tonsil)')}
                                </div>
                            </div>
                            <div className="row">
                                {this.renderSurgeryOther()}
                            </div>
                        </div>
                    </div>
                    <hr />
                    <h2 className="h5 mb-3 font-weight-normal">Allergy to medications</h2>
                    <div className="row">
                        <div className="col-md-12 mb-2">
                            <label htmlFor="MedicalConditionsSelect">
                                Do you have any allergy to medications?
                                <span className="text-danger"><b> *</b></span>
                            </label>
                            <p className="microtext">Choose all that apply.</p>
                            <div className="row">
                                {this.renderAllergyNo()}
                            </div>
                            <div className="row">
                                <div className="col-md-4 mb-2">
                                    {this.renderAllergyCheckbox('AmoxicillinAllergy', 'Amoxicillin')}
                                    {this.renderAllergyCheckbox('CodeineAllergy', 'Codeine')}
                                </div>
                                <div className="col-md-4 mb-2">
                                    {this.renderAllergyCheckbox('MacrolidesAllergy', 'Macrolides (like erythromycin)')}
                                    {this.renderAllergyCheckbox('PenicillinAllergy', 'Penicillin')}
                                </div>
                                <div className="col-md-4 mb-2">
                                    {this.renderAllergyCheckbox('SulfaAllergy', 'Sulfa (sulfonamides)')}                                    
                                </div>
                            </div>
                            <div className="row">
                                {this.renderAllergyOther()}
                            </div>
                        </div>
                    </div>
                    <hr />
                    <h2 className="h5 mb-3 font-weight-normal">Medication currently taken</h2>
                    <p className="microtext">Choose all that apply.</p>
                    <DrugSearch onMedicationsChanged={this.handleMedicationsChanged}  
                        preload={medicalInfoObj.MedicationsCurrentlyTaken} hideDuration={true} 
                        isCrossOut = {false}
                    />
                    <hr />
                    <div >
                        <h2 className="h5 mb-3 font-weight-normal">
                            Supplements and herbal medications
                        </h2>
                        <textarea  className="form-control " maxlength={MAX_LENGTH} name="supplements" 
                                    id="supplements" value={medicalInfoObj.supplements}
                                     placeholder=" Add Supplements and herbal medications"
                                     onChange={this.handleChange}
                                      >   
                        </textarea>
                        <span className="float-right">{`${(medicalInfoObj["supplements"] || '').length}/${MAX_LENGTH}`}</span>
                    </div>
                    <hr />
                    <h2 className="h5 mb-3 font-weight-normal">Social history</h2>
                    <div className="row">
                        <div className="col-12 mb-3">
                            <label htmlFor="AlcoholHistory">
                                Do you drink alcoholic beverages?
                                <span className="text-danger"><b> *</b></span>
                            </label>
                            <p className="description">Please note that 1 alcoholic beverage is considered one 330 ml bottle of beer, one glass of wine or one shot of hard liquor.</p>
                            <select className="custom-select d-block w-100" id="AlcoholHistory" name="AlcoholHistory" onChange={this.handleChange} value={medicalInfoObj.AlcoholHistory} required>
                                <option value="">Choose...</option>
                                <option value="No">No, I don’t.</option>
                                <option value="1 to 7 alcoholic beverages per week on average">1 to 7 alcoholic beverages per week on average</option>
                                <option value="8 to 14 alcoholic beverages per week on average">8 to 14 alcoholic beverages per week on average</option>
                                <option value="15 or more alcoholic beverages per week on average">15 or more alcoholic beverages per week on average</option>
                            </select>
                        </div>
                        <div className="col-12 mb-3">
                            <label htmlFor="SmokingHistory" className="expand-bottom">
                                Do you smoke cigarettes?
                                <span className="text-danger"><b> *</b></span>
                            </label>
                            <select className="custom-select d-block w-100" id="SmokingHistory" name="SmokingHistory" onChange={this.handleChange} value={medicalInfoObj.SmokingHistory} required>
                                <option value="">Choose...</option>
                                <option value="No">No, I don’t.</option>
                                <option value="Less than 1 pack per day">Less than 1 pack per day</option>
                                <option value="1 to 2 packs per day">1 to 2 packs per day</option>
                                <option value="More than 2 packs per day">More than 2 packs per day</option>
                            </select>
                        </div>
                    </div>
                    <hr />
                    <div >
                        <h2 className="h5 mb-3 font-weight-normal">
                        Other items you wish to advise the doctor about
                        </h2>
                        <textarea  className="form-control " maxlength={MAX_LENGTH} name="otherItems" 
                                    id="otherItems" value={medicalInfoObj.otherItems}
                                    onChange={this.handleChange}
                                    >               
                        </textarea>
                        <span className="float-right">{`${(medicalInfoObj["otherItems"] || '').length}/${MAX_LENGTH}`}</span>
                    </div>
                    <hr />
                    <div>
                        <h2 className="h5 mb-3 font-weight-normal">Preferred pharmacy</h2>
                        <RequiredLabel htmlFor="defaultPharmacy">Please select your preferred pharmacy</RequiredLabel>
                        <PharmacySelect id="defaultPharmacy" name="defaultPharmacy" value={medicalInfoObj.defaultPharmacy} onChange={this.handleChange} />
                    </div>
                    <hr />
                 
                    <div className="mb-3 text-right">
                        <button className="btn btn-outline-secondary btn-lg mr-3" type="button" onClick={this.handleCancel}>Cancel</button>
                        <button className="btn btn-success btn-lg" type="submit">Save</button>
                    </div>
                </form>
            </div>
        );
    }
}


EditMedicalInfoPage.propTypes = {
    dispatch: PropTypes.func,
    medicalInfoObj: PropTypes.object,
    match: PropTypes.object,
    status: PropTypes.string,
    noMedicalInfo: PropTypes.bool
};



function mapStateToProps(state) {
    const { status, noMedicalInfo, medicalInfoObj } = state.medicalInfo;

    return {
        status,
        noMedicalInfo,
        medicalInfoObj
    };
}

const temp = connect(mapStateToProps)(withStyles(styles)(EditMedicalInfoPage));

export { temp as EditMedicalInfoPage };

