import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { profileAction } from '../../_actions';
import { UserCredential, PersonalInfo, HealthDocument } from './';
import { UserEmail } from './userEmail';


class ProfilePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        const { dispatch, user } = this.props;

        if (!user) {
            dispatch(profileAction.get());
        }
    }

    /**
     * Render the HTML template
     */
    render() {
        return (
            <div className="profile-page">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 border-bottom">
                    <h1 className="h2">Profile</h1>
                </div>
                <PersonalInfo />
                <hr className="mb-1" />
                <HealthDocument />
                <hr className="mb-1" />
                {/*<UserCredential />
                <hr className="mb-1" />*/}
                <UserEmail />
                <hr className="mb-1" />
            </div>
        );
    }
}


ProfilePage.propTypes = {
    dispatch: PropTypes.func.isRequired,
    user: PropTypes.object
};


function mapStateToProps(state) {
    let { user, status } = state.profile;

    if (status === 'changed') {
        user = undefined;
    }

    return {
        user
    };
}


const temp = connect(mapStateToProps)(ProfilePage);

export { temp as ProfilePage };
