import moment from 'moment';

export const formatTime = (date) => {
    return moment(date).format('LT');
};

export const formatDate = (date) => {
    return moment(date).format('ll');
};

export const formatDateTime = (date) => {
    return moment(date).format('MMM DD, YYYY, h:mm A');
};

export const yearsOld = (dateOfBirth) => {
    const today = moment(new Date());
    const dob = moment(dateOfBirth);
    const years = today.diff(dob, 'years');
    
    return years;
};

export const isOver24Hours = (lastDate) => {
    const today = moment(new Date());
    const hours = today.diff(lastDate, 'hours');

    return hours >= 24 ? true : false;
};