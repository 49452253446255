import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { NotePanel } from '../Note';
import { history, formatDate, isDoctor, yearsOld, setStringCapital, getFormattedPhoneNumber } from '../../_helpers';
import { MedicalInfo } from '../MedicalInfo';
import { PendingPrescription, PendingSleepAssessment } from './';
import { patientAction, sleepAssessmentAction } from '../../_actions';
import { SleepInfo } from '../SleepReferral';



class PatientPage extends Component {
    constructor(props) {
        super(props);
        if (props.openPendingSleepAssesment) {
            this.state = {
                isexpanded1: false,
                isexpanded2: false,
                isexpanded3: false,
                isexpanded4: true,
            };
        } else {
            this.state = {
                isexpanded1: true,
                isexpanded2: true,
                isexpanded3: true,
                isexpanded4: false,
            };
        }
        this.onPersonalInfoClick = this.onPersonalInfoClick.bind(this);
    }

    componentDidMount() {
        const { dispatch, match } = this.props;
        dispatch(patientAction.getPatient(match.params.id));
        if (this.props.openPendingSleepAssesment)
            this.props.setOpenPendingSleep(false);
    }

    componentDidUpdate(){

        // const {isexpanded1, isexpanded2, isexpanded3 } = this.state;
        // if (openPendingSleepAssesment){
        //     this.setState({
        //         isexpanded1:!isexpanded1,
        //         isexpanded2:!isexpanded2,
        //         isexpanded3:!isexpanded3,
                
        //     })
        // }
        /*
        if (this.props.openPendingSleepAssesment) {
            const { isexpanded1, isexpanded2, isexpanded3, isexpanded4 } = this.state;
            if (isexpanded1) {
                this.setState({ isexpanded1: false });
            }
            if (isexpanded2) {
                this.setState({ isexpanded2: false });
            }
            if (isexpanded3) {
                this.setState({ isexpanded3: false });
            }
            if (!isexpanded4) {
                this.setState({ isexpanded4: true });
            }
            this.props.setOpenPendingSleep(false);
        }*/
    }
    
    

    editMedicalInfo = (id) => {
        history.push(`/medicalInfo/edit/${id}`);
    }
    onPersonalInfoClick() {
        history.push(`/personal-info/${this.props.person._id}/edit`);
    }
    renderContent() {
        const { person, prescriptions, medicalInfoObj, noMedicalInfo, sleepInfoObj ,openPendingSleepAssesment} = this.props;
        const { isexpanded1, isexpanded2,isexpanded3, isexpanded4 } = this.state;


        if (person) {
            return (
                <div className="content">
                    <div className="left-panel">
                        <ExpansionPanel expanded={isexpanded1}>
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon name="isexpanded1" onClick={() => this.setState({isexpanded1:!isexpanded1})} />}
                                className="title"
                            >
                                <span className="mr-2">Pending Prescriptions</span>
                                {prescriptions && prescriptions.length > 0 &&
                                    <span>({prescriptions.length})</span>
                                }
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <PendingPrescription />
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <br />
                        <ExpansionPanel expanded={isexpanded2}>
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon name="isexpanded2" onClick={() =>this.setState({isexpanded2:!isexpanded2})}/>}
                                className="title"
                            >
                                <span className="mr-2">Personal Information</span>
                                <button className="btn btn-outline-info" onClick={this.onPersonalInfoClick}>Edit</button>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <div className='patient-info'>
                                    <div className="row mb-2">
                                        <div className="col-4">
                                            <strong>First Name</strong>
                                            <div>{setStringCapital(person.FirstName)}</div>
                                        </div>
                                        <div className="col-4">
                                            <strong>Last Name</strong>
                                            <div>{setStringCapital(person.LastName)}</div>
                                        </div>
                                        <div className="col-4">
                                            <strong>Date of Birth:</strong>
                                            <div>{formatDate(person.DateOfBirth)}</div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row mb-2">
                                        <div className="col-4">
                                            <strong>Age</strong>
                                            <div>{`${yearsOld(person.DateOfBirth)} years old`}</div>
                                        </div>
                                        <div className="col-4">
                                             <strong>{`${(person.HealthDocuments[0] || '').Type || 'Card'} Number`}</strong>
                                            <div>{(person.HealthDocuments[0] ||'').DocumentNumber || ''}</div>
                                        </div>
                                        <div className="col-4">
                                             <strong>Card Province</strong>
                                             <div>{(person.HealthDocuments[0] || '').Province || 'BC'}</div>
                                        </div>
                                       
                                    </div>
                                    <hr />
                                    <div className="row mb-2">
                                        <div className="col-4">
                                            <strong>Cell Phone Number:</strong>
                                            <div>{getFormattedPhoneNumber(person.PhoneNumber)}</div>
                                        </div> 
                                        <div className="col-4">
                                            <strong>Best Phone Number:</strong>
                                            <div>{getFormattedPhoneNumber(person.BestPhoneNumber)} </div> 
                                        </div>
                                        <div className="col-4">
                                            <strong>Street Address:</strong>
                                            <div>{person.Address} </div>
                                        </div>      
                                    </div>
                                    <hr />
                                    <div className="row mb-2">
                                        <div className="col-4">
                                            <strong>City</strong>
                                            <div>{person.City}</div>
                                        </div>
                                        <div className="col-4">
                                            <strong>Province</strong>
                                            <div>{person.Province}</div>

                                        </div>
                                        <div className="col-4">
                                            <strong>Postal Code</strong>
                                            <div>{person.PostalCode}</div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row mb-2">
                                        <div className="col-8">
                                            <strong>Email</strong>
                                            <div>{person.Email}</div>
                                        </div>
                                       
                                       
                                    </div>
                                </div>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <br />
                        <ExpansionPanel expanded={isexpanded3}>
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon name="isexpanded3" onClick={() => this.setState({isexpanded3:!isexpanded3})}/>}
                                className="title"
                            >                                
                                <span className="mr-2">Medical Information</span>
                                {noMedicalInfo === false && isDoctor() &&
                                    <button className="btn btn-outline-info" onClick={() => this.editMedicalInfo(medicalInfoObj._id)}>Edit</button>
                                }
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <MedicalInfo pharmacy={false} />
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <br />
                        <ExpansionPanel expanded = {isexpanded4} >
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon  onClick= {() =>this.setState({isexpanded4:!isexpanded4}) }/>}
                                className="title"
                            >                                
                                <span className="mr-2">Pending Sleep Assessment</span>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <PendingSleepAssessment />
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <br />
                        <ExpansionPanel>
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                className="title"
                            >                                
                                <span className="mr-2">Sleep Information</span>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <SleepInfo sleepInfoObj={sleepInfoObj} />
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </div>
                    <div className="right-panel">
                        <NotePanel />
                    </div>
                </div>
            );
        } else {
            return (
                <div className="mb-4">
                    Please go to patients page to select one of the patient.
                </div>
            );
        }
    }

    render() {
        const { person } = this.props;

        return (
            <div className="patient-page">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h1 className="h2">
                        Patient
                        <span className="patient-name">&nbsp;{person && `(${setStringCapital(person.FirstName)} ${setStringCapital(person.LastName)})`}</span>
                    </h1>
                </div>
                <div>
                    {this.renderContent()}
                </div>
                <div>
                    <button className="btn btn-outline-secondary btn-lg my-4" onClick={() => history.goBack()/** history.push('/patients')*/}>Back</button>
                </div>
            </div>
        );
    }
}


PatientPage.propTypes = {
    dispatch: PropTypes.func.isRequired,
    match: PropTypes.object,
    person: PropTypes.object,
    medicalInfoObj: PropTypes.object,
    prescriptions: PropTypes.array,
    noMedicalInfo: PropTypes.bool,
    sleepInfoObj: PropTypes.object
};



function mapStateToProps(state) {
    const { person } = state.patient;
    const { prescriptions } = state.prescription;
    const { medicalInfoObj, noMedicalInfo } = state.medicalInfo;
    const { sleepInfoObj } = state.sleepInfo;
    let { openPendingSleepAssesment } = state.sleepAssessment;

    if (openPendingSleepAssesment === undefined) {
        openPendingSleepAssesment = false;
    }

    return {
        person,
        prescriptions,
        medicalInfoObj,
        noMedicalInfo,
        sleepInfoObj,
        openPendingSleepAssesment,
    };
}

const mapDispatchToProps = (dispatch) => {
    return { 
        dispatch,
        setOpenPendingSleep: (isOpen) => dispatch(sleepAssessmentAction.setOpenPendingSleep(isOpen)) 
    };
}

const temp = connect(mapStateToProps, mapDispatchToProps)(PatientPage);

export { temp as PatientPage };

