import { userConstant, noteConstant } from '../_constants';

export function note(state = {}, action) {
    switch (action.type) {
        case noteConstant.GET_BY_PERSON:
            return {
                ...state,
                personId: action.personId,
                notes: [...action.notes]
            };
        case noteConstant.CREATED:
        case noteConstant.SAVED:
            return {
                ...state,
                text: undefined,
                notes: [...action.notes]
            };
        case noteConstant.EDIT:
            return {
                ...state,
                text: undefined,
                editNoteId: action.editNoteId
            };
        case noteConstant.CANCEL_EDIT:
            return {
                ...state,
                text: undefined,
                editNoteId: undefined
            };
        case noteConstant.CHANGE:
            return {
                ...state,
                text: action.text
            };
        case noteConstant.SEND_NOTE_SUCCESS:
        case noteConstant.ACKNOWLEDGE_REPLY_SUCCESS:
            return {
                ...state,
                notes: [...action.notes]
            };
        case noteConstant.OPEN_REVISION:
            return {
                ...state,
                note: { ...action.note }
            };
        case noteConstant.CLOSE_REVISION:
            return {
                ...state,
                note: undefined
            };
        case noteConstant.GO_TO_NOTE:
            return {
                ...state,
                selectedNoteId: action.selectedNoteId
            };
        case userConstant.LOGOUT:
            return {};
        default:
            return state;
    }
}