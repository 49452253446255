import axios from 'axios';
import config from '../config';
import { getToken } from '../_helpers';



export const userService = {
    login,
    updateDontShowLastLogin, 
    getUserEmailByToken,
    getUserByEmail,
    sendEmailInvitation,
    logout        
};


function login(email, password, role, ipObj, code, storedCode) {
    debugger
    const url = `${config.apiHost}/user/authenticate`;
    const body = {
        Email: email,
        Password: password,
        Role: role,
        ipObj,
        code,
        storedCode
    };

    return axios.post(url, body);
}

function updateDontShowLastLogin(id, dontShowLastLogin) {
    const url = `${config.apiHost}/user/dont-show-last-login`;
    const body = {
        id,
        dontShowLastLogin
    };
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.put(url, body);
}

async function getUserEmailByToken() {
    try {
        const url = `${config.apiHost}/user/token`;
        axios.defaults.headers.common['x-access-token'] = getToken();
        const res = await axios.post(url);
        return res.data.Email;
    } catch (error) {
        throw (error);
    }
}

async function getUserByEmail(email) {
    try {
        const url = `${config.apiHost}/user/email/${email}`
        axios.defaults.headers.common['x-access-token'] = getToken();
        const res = await axios.get(url);
        return res.data;
    } catch (error) {
        throw (error);
    }
}

function sendEmailInvitation(email) {
        const url = `${config.apiHost}/user/recovery/send-email`;
        const body = {
            email: email,
        };
    return axios.post(url, body);
}

function logout(user) {
    const url = `${config.apiHost}/user/logout`;
    const body = {
        user
    };

    return axios.post(url, body);
}