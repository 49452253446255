import { medicationFilterConstant, userConstant } from '../_constants';

export function medicationFilter(state = {}, action) {
    switch (action.type) {
        case medicationFilterConstant.GET_SUCCESS:
        case medicationFilterConstant.CREATED:
        case medicationFilterConstant.DELETED:
            return {
                medicationFilterList: [...action.medicationFilterList]
            };
        case medicationFilterConstant.CHANGE:
            return {
                ...state,
                medicationFilter: { ...action.medicationFilter }
            };
        case userConstant.LOGOUT:
            return {};
        default:
            return state;
    }
}