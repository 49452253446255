import React from 'react';
import PropTypes from 'prop-types';
import { badgeColor, statusText, formatDateTime } from '../_helpers';


const StatusBadges = ({ statusList }) => {
    return (
        <div className="status-badges">
            {
                statusList.map((o, index) => {
                    return <StatusBadge key={index} statusObj={o} />;
                })
            }
        </div>
    );
};


StatusBadges.propTypes = {
    statusList: PropTypes.array
};


export { StatusBadges };



const StatusBadge = ({ statusObj }) => {
    if (statusObj == undefined || statusObj.text == undefined) return '';     // status either null or undefined

    const obj = {
        text: statusText[statusObj.text.toUpperCase()],
        color: badgeColor[statusObj.text.toUpperCase()],
        name: statusObj.createdBy ? `${statusObj.createdBy.FirstName} ${statusObj.createdBy.LastName}` : null,
        createdAt: statusObj.createdAt
    };

    return (
        <div className="status-badge">
            <span className={`badge badge-pill ${obj.color}`}>{obj.text}</span>
            {obj.name &&
                <span className="extra-info">
                    by
                    <span className="person">{obj.name}</span>
                    at
                    <span className="date">{formatDateTime(obj.createdAt)}</span>
                </span>
            }
        </div>
    );
};


StatusBadge.propTypes = {
    statusObj: PropTypes.object
};


export { StatusBadge };