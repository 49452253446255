import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getUser, formatDateTime } from '../../_helpers';
import { noteAction, noteDraftAction } from '../../_actions';
import { PersonIcon } from '../../components';
import { NoteReply } from './';
import { multilineText } from '../../_helpers';
import { Element, scroller, animateScroll } from 'react-scroll';


class Note extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        const { selectedNoteId } = this.props;

        if (selectedNoteId) {
            // scroll to specific note
            scroller.scrollTo(selectedNoteId, {
                containerId: 'notes',
                duration: 1,
                smooth: true,
                offset: -300
            });
        } else {
            // scroll to bottom note
            animateScroll.scrollToBottom({
                containerId: 'notes',
                duration: 1,
                smooth: true
            });
        }
    }

    handleChange = event => {
        const { dispatch } = this.props;

        dispatch(noteAction.change(event.target.value));
    }

    handleEdit = (noteId) => {
        const { dispatch, editDraft } = this.props;

        dispatch(noteAction.edit(noteId));

        // close edit draft if draft is in edit mode
        if (editDraft) {
            dispatch(noteDraftAction.close());
        }
    }

    handleSave = () => {
        const { dispatch, editNoteId, personId, text } = this.props;
        const fromObj = getUser().Person;

        dispatch(noteAction.save(editNoteId, personId, fromObj, text));
    }

    handleCancel = () => {
        const { dispatch } = this.props;

        dispatch(noteAction.cancelEdit());
    }

    sendNote = (note) => {
        const { dispatch, personId } = this.props;
        const fromObj = getUser().Person;
        const to = personId;
        const subject = 'Doctor sent you a note';
        const lastVersion = [...note.versions].pop();     // last note

        dispatch(noteAction.sendNote(note._id, fromObj, to, subject, lastVersion));
    }

    openNoteVersion = (note) => {
        const { dispatch } = this.props;
        dispatch(noteAction.openRevision(note));
    }

    renderHeader() {
        const { editNoteId, note } = this.props;
        const { message } = note;
        const isEditNote = editNoteId === note._id;
        const isSent = message !== undefined;
        let action;

        if (!isSent) {
            // not send yet
            if (isEditNote) {
                action = (
                    <div>
                        <a href="#" onClick={() => this.handleSave(editNoteId)}>Save</a>
                        <a href="#" onClick={this.handleCancel}>Cancel</a>
                    </div>
                );
            } else {
                action = (
                    <div>
                        <a href="#" onClick={() => this.handleEdit(note._id)}>Edit</a>
                        <a href="#" onClick={() => this.sendNote(note)}>Send note</a>
                    </div>
                );
            }
        }

        return (
            <>
                <div className="note-id">
                    {note._id}
                </div>
                {action}
            </>
        );
    }

    renderText() {
        const { editNoteId, note } = this.props;
        const isEditNote = editNoteId === note._id;
        const latestNote = [...note.versions].pop();


        if (isEditNote) {
            let text = this.props.text;

            if (text === undefined) {
                text = latestNote.text;
            }

            return (
                <textarea className="form-control" rows="4" placeholder="Your note..."
                    value={text} onChange={this.handleChange}></textarea>
            );
        } else {
            const { text } = latestNote;

            return (
                <div className="multiline-text">
                    {multilineText(text)}
                </div>
            );
        }
    }

    render() {
        const { note, selectedNoteId } = this.props;
        const { message } = note;
        const latestNote = [...note.versions].pop();
        const { from, createdAt } = latestNote;
        const isSent = message !== undefined;
        const hasReply = message && message.replies.length > 0;
        const selected = note._id === selectedNoteId;

        let classes = 'note';

        if (isSent) classes += ' is-sent';
        if (hasReply) classes += ' has-reply';
        if (selected) classes += ' selected';

        return (
            <Element name={note._id} className={classes}>
                <div className="header">
                    {this.renderHeader()}
                </div>
                <div className="text">
                    {this.renderText()}
                </div>
                <div className="footer">
                    <PersonIcon className="mr-2" person={from} />
                    <span>
                        <span className="created-at">
                            {`${formatDateTime(createdAt)}`}
                        </span>
                        <span className="revision">
                            <a href="#" onClick={() => this.openNoteVersion(note)}>Rev {note.versions.length}</a>
                        </span>
                    </span>
                </div>
                <NoteReply note={note} />
            </Element>
        );
    }
}


Note.propTypes = {
    dispatch: PropTypes.func.isRequired,
    selectedNoteId: PropTypes.string,
    editNoteId: PropTypes.string,
    personId: PropTypes.string,
    text: PropTypes.string,
    editDraft: PropTypes.bool,
    note: PropTypes.object
};


function mapStateToProps(state) {
    const { editNoteId, personId, text, selectedNoteId } = state.note;
    const { edit } = state.noteDraft;

    return {
        selectedNoteId,
        editNoteId,
        personId,
        text,
        editDraft: edit
    };
}

const temp = connect(mapStateToProps)(Note);

export { temp as Note };