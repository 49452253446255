import { paymentConstant, prescriptionConstant, userConstant } from '../_constants';

export function payment(state = {}, action) {
    switch (action.type) {
        case paymentConstant.CHECKOUT:
            return {
                prescription: action.prescription
            };
        case paymentConstant.GET_CHARGE_SUCCESS:
            return {
                card: {...action.card}
            };
        case paymentConstant.PAID:
        case prescriptionConstant.CLOSE_INVOICE_DIALOG:
        case userConstant.LOGOUT:
            return {};
        default:
            return state;
    }
}