import { registrationService } from '../_services';
import { registrationConstant } from '../_constants';
import { history } from '../_helpers';
import { loadingAction, alertAction } from './';
import { errorHandling } from '../_helpers';


export const registrationAction = {
    register,
    isActivated,
    confirmChange,
    activate,
    openPrivacyPolicy,
    closePrivacyPolicy,
    openTermsOfUse,
    closeTermsOfUse,
    openPharmanetConsent,
    closePharmanetConsent,
    confirmOfficeToken
};

function register(formData) {
    return dispatch => {
        dispatch(request());
        dispatch(loadingOpen());

        registrationService.register(formData)
            .then(
                () => {
                    dispatch(success());
                    dispatch(alertAction.success('Registration is success.'));
                    history.push('/confirm');
                },
                error => {
                    errorHandling('registration.action.register', error, dispatch);
                }
            )
            .finally(
                () => { dispatch(loadingClose()); }
            );
    };

    function request() { return { type: registrationConstant.REGISTER_REQUEST }; }
    function success(user) { return { type: registrationConstant.REGISTER_SUCCESS, user }; }    
}

function confirmOfficeToken(token) {
    return dispatch => {
        registrationService.confirmOfficeToken(token)
            .then(
                success => {
                    dispatch(alertAction.success('Token is confirmed.'));
                },
                error => {
                    errorHandling('registration.action.register', error, dispatch);
                }
            )
            .finally(
                () => { dispatch(loadingClose()); }
            );
    };
} 

function isActivated(email) {
    return dispatch => {
        registrationService.isActivated(email)
            .then(
                res => {
                    dispatch({
                        type: registrationConstant.IS_ACTIVATED,
                        isActivated: res.data.isActivated
                    });

                    if (res.data.isActivated) {
                        dispatch(alertAction.info('This account is activated.'));
                    }
                },
                error => {
                    dispatch({
                        type: registrationConstant.IS_ACTIVATED,
                        isActivated: undefined
                    });

                    errorHandling('registration.action.isActivated', error, dispatch);
                }
            )
            .finally(
            );
    };
}

function confirmChange(confirm) {
    return {
        type: registrationConstant.CONFIRM_CHANGE,
        confirm
    };
}


function activate(email, verificationCode) {
    return dispatch => {
        dispatch(loadingOpen());

        registrationService.activate(email, verificationCode)
            .then(
                () => {
                    dispatch({
                        type: registrationConstant.ACTIVATED
                    });

                    dispatch(alertAction.success('Registration is confirmed.'));
                    history.push('/login');
                },
                error => {
                    errorHandling('registration.action.activate', error, dispatch);
                }
            )
            .finally(
                () => { dispatch(loadingClose()); }
            );
    }; 
}

function openPrivacyPolicy() {
    return {
        type: registrationConstant.OPEN_PRIVACY_POLICY
    };
}

function closePrivacyPolicy(checked) {
    return {
        type: registrationConstant.CLOSE_PRIVACY_POLICY,
        checked
    };
}

function openTermsOfUse() {
    return {
        type: registrationConstant.OPEN_TERMS_OF_USE
    };
}

function closeTermsOfUse(checked) {
    return {
        type: registrationConstant.CLOSE_TERMS_OF_USE,
        checked
    };
}

function openPharmanetConsent() {
    return {
        type: registrationConstant.OPEN_PHARMANET_CONSENT
    };
}

function closePharmanetConsent(checked) {
    return {
        type: registrationConstant.CLOSE_PHARMANET_CONSENT,
        checked
    };
}


function loadingOpen() { return loadingAction.open(); }
function loadingClose() { return loadingAction.close(); }