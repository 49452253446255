import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { connect } from 'react-redux';
import { formatDateTime, isEmptyObj, getUser } from '../../_helpers';
import { appAction } from '../../_actions';
import { userService } from '../../_services';


class LastLoginDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    handleClose = () => {
        const { dispatch } = this.props;
        dispatch(appAction.closeLastLoginDialog());
        this.setState({
            checkConfirmLocation : false,
        })
    };

    changeCheckbox = (event) => {
        const userId = getUser()._id;
        userService.updateDontShowLastLogin(userId, event.target.checked);
    }

    handleChangeConfirm = (event) => {
        this.setState({
            checkConfirmLocation: event.target.checked,
        })
    }

    renderButton = () => {
        const { checkConfirmLocation } = this.state;
        const enable = checkConfirmLocation;
        return(
            <>
                {enable ?
                    <button 
                        type="button" 
                        className="btn btn-sm btn-primary" 
                        onClick={this.handleClose}
                        data-test-id={"last-login__OK-button"}
                    >
                         Ok
                    </button>
                    :
                    (<button 
                        type="button" 
                        className="btn btn-sm btn-primary disabled"  
                        disabled 
                        onClick={this.handleClose}
                        data-test-id={"last-login__OK-button"}
                    >
                         Ok
                    </button>  )                   
                }
            </>
        )
    }

    displayContent = () => {
        const { lastLogin } = this.props;
        const { checkConfirmLocation } = this.state;

        if (lastLogin) {
            return (
                <>                    
                    <div className="col-md-12">
                        <span className="field-name">Date</span>
                        <span>{formatDateTime(lastLogin.createdDate)}</span>
                    </div>
                    {lastLogin.ip && <div className="col-md-12">
                        <span className="field-name">IP Address</span>
                        <span>{lastLogin.ip}</span>
                    </div>}
                    {lastLogin.city && <div className="col-md-12">
                        <span className="field-name">City</span>
                        <span>{lastLogin.city}</span>
                    </div>}
                    {lastLogin.region && <div className="col-md-12">
                        <span className="field-name">Region</span>
                        <span>{lastLogin.region}</span>
                    </div>}
                    {lastLogin.country && <div className="col-md-12">
                        <span className="field-name">Country</span>
                        <span>{lastLogin.country}</span>
                    </div>}
                    
                    <div className="text-info mt-2">
                        If this information is incorrect, please change your password immediately. 
                        Click on Profile and User Credentials.
                    </div>
                    <div className="mt-1 d-flex align-items-center justify-content-between">
                        <div>
                        <div className="custom-control custom-checkbox mr-2">
                            <input type="checkbox" className="custom-control-input" id="checkbox"
                                onChange={this.changeCheckbox} />
                            <label className="custom-control-label" htmlFor="checkbox">
                                Don&apos;t show this for the next 24 hours
                            </label>
                        </div>
                        <div className="custom-control custom-checkbox mr-2">
                            <input 
                                type="checkbox" 
                                className="custom-control-input" 
                                id="confirmLocation"
                                checked={checkConfirmLocation || false} 
                                onChange={this.handleChangeConfirm}
                            />
                            <label 
                                className="custom-control-label" 
                                htmlFor="confirmLocation"
                                data-test-id={"last-login__confirm-location-checkbox"}
                            >
                                I confirm that I am at the present time physically located in British Columbia, Canada.
                            </label>
                        </div>
                        </div>
                        {/* <button type="button" className="btn btn-sm btn-primary" onClick={this.handleClose}>
                            Ok
                        </button> */}
                        {this.renderButton()}
                    </div>
                </>
            );
        } else {
            return '';
        }
    }

    render() {
        const { openDialog } = this.props;

        return (
            <Dialog id="last-login-dialog"
                fullWidth={true}
                maxWidth="xs"
                open={openDialog}
                //onClose={this.handleClose}
            >
                <DialogTitle>
                    Last Login
                </DialogTitle>
                <DialogContent>
                    {this.displayContent()}
                </DialogContent>
            </Dialog>
        );
    }
}

LastLoginDialog.propTypes = {
    dispatch: PropTypes.func,
    onClose: PropTypes.func,
    lastLogin: PropTypes.object,
    openDialog: PropTypes.bool
};


function mapStateToProps(state) {
    let { lastLogin } = state.app;

    if (lastLogin === undefined) {
        lastLogin = {};
    }

    return {
        lastLogin,
        openDialog: !isEmptyObj(lastLogin)
    };
}

const temp = connect(mapStateToProps)(LastLoginDialog);

export { temp as LastLoginDialog };