export const prescriptionConstant = {
    GET_BY_ID: 'PRESCRIPTION_GET_BY_ID',
    
    REQUEST: 'PRESCRIPTION_REQUEST',
    CHANGE: 'PRESCRIPTION_CHANGE',
    CREATE: 'PRESCRIPTION_CREATE',

    GET_NON_CREATED: 'PRESCRIPTION_GET_NON_CREATED',
    GET_FAXED: 'PRESCRIPTION_GET_FAXED',
    GET_BY_PERSON: 'PRESCRIPTION_GET_BY_PERSON',
    GET_PENDING_BY_PERSON: 'PRESCRIPTION_GET_PENDING_BY_PERSON',

    TABLE_CHANGE: 'PRESCRIPTION_TABLE_CHANGE',
    OPEN_PRESCRIPTION_DIALOG: 'PRESCRIPTION_OPEN_PRESCRIPTION_DIALOG',
    CLOSE_PRESCRIPTION_DIALOG: 'PRESCRIPTION_CLOSE_PRESCRIPTION_DIALOG',
    OPEN_INVOICE_DIALOG: 'PRESCRIPTION_OPEN_INVOICE_DIALOG',
    CLOSE_INVOICE_DIALOG: 'PRESCRIPTION_CLOSE_INVOICE_DIALOG',

    MEDICATION_STATUS_UPDATED: 'PRESCRIPTION_MEDICATION_STATUS_UPDATED',
    UPDATE_MEDICATION_USAGE_SUCCESS: 'PRESCRIPTION_UPDATE_MEDICATION_USAGE_SUCCESS',
    GO_TO_PRESCRIPTION: 'PRESCIRPTION_GO_TO_PRESCRIPTION',
    ACKNOWLEDGE_SUCCESS: 'PRESCIRPTION_ACKNOWLEDGE_SUCCESS',
    REFUND_SUCCESS: 'PRESCRIPTION_REFUND_SUCCESS',
    PRINT_SUCCESS: 'PRESCRIPTION_PRINT_SUCCESS',

    IS_UNLIMITED: false,
    SET_TEXT: "PRESCRIPTION_SAVE_TEXT",
    SET_DR_PHARMACY:"PRESCRIPTION_SAVE_DR_PHARMACY",

    CALL_TO_PATIENT: 'TWILLIO_CALL_PATIENT_FOR_PRESCRIPTION',

    UPDATE_CALL_RANGE: 'TWILLIO_CALL_UPDATE_RANGE'
};
