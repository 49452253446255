import React from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Tooltip from '@material-ui/core/Tooltip';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { messageAction } from '../../_actions';
import { messageConstant } from '../../_constants';
import { getPersonId } from '../../_helpers/authorized';
import { formatDate, formatTime, isDoctor, isCustomerService, isPatient } from '../../_helpers';
import { BaseTable } from '../../components';


class MessageTable extends BaseTable {
    constructor(props) {
        super(props);
        this.state = {
            order: 'desc',
            orderBy: 'updatedAt',
            page: props.page,
            rowsPerPage: props.rows,
            open: false
        };
    }

    // click to open message
    handleOpenMessage = (messageObj) => {
        const { dispatch } = this.props;

        if (isDoctor() || isCustomerService()) {
            dispatch(messageAction.doctorOpenMessage(messageObj));
            this.props.clickMessage(messageObj._id);
        } else {
            dispatch(messageAction.openMessage(messageObj));
        }
    };

    archive = (messageId) => {
        const { dispatch } = this.props;
        const personId = getPersonId();

        dispatch(messageAction.archive(messageId, personId));
        dispatch(messageAction.countUnread());
    }

    goBackToInbox = (messageId) => {
        const { dispatch } = this.props;
        const personId = getPersonId();

        dispatch(messageAction.goBackToInbox(messageId, personId));
        dispatch(messageAction.countUnread());
    }



    renderName(obj) {
        const { tabValue } = this.props;
        const repliesCount = obj.replies.length === 0 ? '' : (obj.replies.length + 1).toString();
        let name = '';
        let type = '';

        if (tabValue === messageConstant.TAB_SENT) {
            // Sent
            type = 'sent';
            if (getPersonId() === (obj.to || {})._id) {
                // if to is me, user from, someone send to me
                let firstName = '';
                const from = obj.from;
                if (from !== null && typeof from !== 'undefined')
                    firstName = from.FirstName;
                name = `To: ${firstName}`;
            } else {
                // if to is not me, use to, i sent out
                let firstName = '';
                const to = obj.to;
                if (to !== null && typeof to !== 'undefined')
                    firstName = to.FirstName;
                name = `To: ${firstName}`;
            }
        } else if (tabValue === messageConstant.TAB_INBOX || tabValue === messageConstant.TAB_ARCHIVE) {
            // Inbox
            type = 'inbox';
            // with replies
            if (obj.replies.length > 0) {
                const personId = getPersonId();

                if (obj.to._id === personId) {
                    // other people send to me
                    name = `${getName(((obj || {}).from || {}).FirstName || '', ((obj || {}).from || {}).LastName || '')}, me`;
                } else {
                    // i send out
                    name = `me, ${getName(((obj || {}).to || {}).FirstName || '', ((obj || {}).to || {}).LastName || '')}`;
                }
            } else {
                // without any replies
                name = `${((obj || {}).from || {}).FirstName || ''} ${((obj || {}).from || {}).LastName || ''}`;
            }
        }

        return (
            <>
                <span className={`name ${type}`}>{name}</span>
                <span className="replies-count">{repliesCount}</span>
            </>
        );

        function getName(firstName, lastName) {
            let name = `${firstName} ${lastName}`;
            if (name.length > 10) {
                name = firstName;
            }

            return name;
        }
    }

    renderMessage(obj) {
        const length = 80;
        let message = obj.message;

        // update message to the last replied message
        if (obj.replies.length > 0) {
            message = obj.replies[obj.replies.length - 1].message;
        }

        if (message.length > length) {
            return message.substring(0, length) + '...';
        }

        return message;
    }

    getRowClass(obj) {
        const { tabValue } = this.props;
        const personId = getPersonId();

        // unread
        if (obj.isRead === false) {
            // inbox
            if (tabValue === messageConstant.TAB_INBOX || tabValue === messageConstant.TAB_ARCHIVE) {
                // sent to me without any reply
                if (obj.repliedBy === undefined) {
                    return 'row unread';
                }

                // not reply by me
                if (obj.repliedBy !== personId) {
                    return 'row unread';
                }
            } else if (tabValue === messageConstant.TAB_SENT) {
                if (obj.repliedBy !== undefined && obj.repliedBy !== personId) {
                    return 'row unread';
                }
            } 
        }

        return 'row';
    }

    renderUpdatedAt(obj) {
        let updatedAt = new Date(obj.updatedAt);
        const isToday = new Date().toDateString() === updatedAt.toDateString();

        if (isToday) {
            return formatTime(updatedAt);
        }

        return formatDate(updatedAt);
    }

    render() {
        const { messageList, tabValue } = this.props;
        const { order, orderBy, rowsPerPage, page } = this.state;

        return (
            <div className="message-table">
                <Paper className={(isDoctor() || isCustomerService())? tabValue : ''}>
                    <div className="header">
                        <div className="action">
                            {/* <button className="btn">
                                <i className="material-icons" onClick={this.archive}>archive</i>
                            </button> */}
                        </div>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
                            component="div"
                            count={messageList.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            backIconButtonProps={{
                                'aria-label': 'Previous Page',
                            }}
                            nextIconButtonProps={{
                                'aria-label': 'Next Page',
                            }}
                            onChangePage={(e, page, ...rest) => {
                                this.props.setPage(page);
                                this.handleChangePage(e, page, ...rest);
                            }}
                            onChangeRowsPerPage={(e,...rest) => {
                                this.props.setRows(e.target.value);
                                this.handleChangeRowsPerPage(e,...rest);
                            }}
                        />
                    </div>
                    <Table>
                        <TableBody>
                            {this.stableSort(messageList, this.getSorting(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map(obj => {
                                    const rowClass = this.getRowClass(obj);
                                    let style = obj ? {cursor: 'pointer'} : {cursor:'not-allowed'};
                                    if (this.props.selectMessageId == obj._id)
                                       style = {...style, borderStyle: "dotted", borderColor: "#17a2b8"}
                                    return (
                                        <TableRow className={rowClass} key={obj._id} style={style}>
                                            <TableCell onClick={() => this.handleOpenMessage(obj)}>
                                                {this.renderName(obj)}
                                            </TableCell>
                                            <TableCell className="content" onClick={() => this.handleOpenMessage(obj)}>
                                                <span className="subject">{obj.subject}</span>
                                                <span className="message"> - {this.renderMessage(obj)}</span>
                                            </TableCell>
                                            <TableCell className="updated-at">
                                                {this.renderUpdatedAt(obj)}
                                            </TableCell>
                                            <TableCell className="action">
                                                {!obj.isArchive && !isPatient() &&
                                                    <Tooltip title="Archive message">
                                                        <button className="btn">
                                                            <i className="material-icons" onClick={() => this.archive(obj._id)}>archive</i>
                                                        </button>
                                                    </Tooltip>
                                                }
                                                {obj.isArchive && !isPatient() &&
                                                    <Tooltip title="Inbox message">
                                                        <button className="btn">
                                                            <i className="fas fa-envelope" onClick={() => this.goBackToInbox(obj._id)}></i>
                                                        </button>
                                                    </Tooltip>
                                                }
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </Paper>
            </div>
        );
    }
}


function mapStateToProps(state) {
    const { tabValue, messageList, page = 0, rows = 10, selectMessageId = null } = state.message;

    return {
        tabValue,
        messageList,
        page,
        rows,
        selectMessageId
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setPage: (page = 0) => {
            dispatch (messageAction.setPage(page));
        }, 
        setRows: (rows = 10) => {
            dispatch(messageAction.setRows(rows));
        },
        clickMessage: (id) => {
            dispatch(messageAction.clickMessage(id));
        },
        dispatch
    }

}


const temp = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter
)(MessageTable);

export { temp as MessageTable };
