import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';

import rootReducer from '../_reducers';


const loggerMiddleware = createLogger();

let composeEnhancers = compose;
const middleWares = [thunkMiddleware];

if (process.env.REACT_APP_ENV == "test" || process.env.REACT_APP_ENV== "dev") {
    middleWares.push(loggerMiddleware);
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}
    

export const store = createStore(rootReducer, /* preloadedState, */ composeEnhancers(
    applyMiddleware(
        ...middleWares
    )
));