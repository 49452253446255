import { loadingConstant } from '../_constants';


export const loadingAction = {
    open,
    close
};

function open(message) {
    return { 
        type: loadingConstant.OPEN,
        message
    };
}

function close() {
    return { 
        type: loadingConstant.CLOSE,
    };
}