export const badgeColor = {
    CREATED: 'badge-warning',
    PENDING: 'badge-secondary',
    ACKNOWLEDGED: 'badge-info',
    REFUNDED: 'badge-primary',
    FAXED: 'badge-success',
    REJECTED: 'badge-danger',
    APPROVED: 'badge-success'
};

export const statusText = {
    CREATED: 'Created',
    PENDING: 'Pending',
    ACKNOWLEDGED: 'Acknowledged',
    REFUNDED: 'Refunded',
    FAXED: 'Faxed to pharmacy',
    REJECTED: 'Rejected',
    APPROVED: 'Approved'
};

export const uploadBucket = {
    profileImage: 'profile-image',
    healthDocument: 'health-document',
    messaging: 'messaging',
    prescription: 'prescription',
    problem: 'problem',
    signatureImage: 'signature-image',
};