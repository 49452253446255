import { messageConstant, userConstant } from '../_constants';

export function message(state = {}, action) {
    switch (action.type) {
        case messageConstant.CHANGE_TAB:
            return {
                ...state,
                unreadCount: state.unreadCount,
                tabValue: action.tabValue,
                messageObj:null,
            };
        case messageConstant.DISPLAY_INBOX:
            return {
                ...state,
                unreadCount: state.unreadCount,
                tabValue: state.tabValue,
                status: messageConstant.STATUS_DISPLAY_INBOX,
                messageList: [...action.messageList]
            };
        case messageConstant.DISPLAY_SENT:
            return {
                ...state,
                unreadCount: state.unreadCount,
                tabValue: state.tabValue,
                status: messageConstant.STATUS_DISPLAY_SENT,
                messageList: [...action.messageList]
            };
        case messageConstant.DISPLAY_ARCHIVE:
            return {
                ...state,
                unreadCount: state.unreadCount,
                tabValue: state.tabValue,
                status: messageConstant.STATUS_DISPLAY_ARCHIVE,
                messageList: [...action.messageList]
            };
        case messageConstant.OPEN:
        case messageConstant.SEND_REPLY_SUCCESS:
            return {
                ...state,
                unreadCount: state.unreadCount,
                tabValue: state.tabValue,
                messageObj: { ...action.messageObj }
            };
        case messageConstant.REPLY:
            return {
                ...state,
                status: messageConstant.STATUS_REPLYING
            };
        case messageConstant.CHANGE:
            return {
                ...state,
                sendMessageObj: { ...action.sendMessageObj }
            };
        case messageConstant.DISCARD:
            return {
                ...state,
                unreadCount: state.unreadCount,
                tabValue: state.tabValue,
                messageObj: { ...state.messageObj }
            };
        case messageConstant.SEND_REPLY_REQUEST:
            return {
                ...state,
                status: messageConstant.STATUS_SENDING
            };
        case messageConstant.COMPOSE:
            return {
                ...state,
                status: messageConstant.STATUS_COMPOSE
            };
        case messageConstant.COMPOSE_CLOSE:
            return {
                ...state,
                status: undefined
            };
        case messageConstant.COUNT_UNREAD:
            return {
                ...state,
                unreadCount: action.unreadCount
            };
        case messageConstant.BACKTOINBOX:
        case messageConstant.ARCHIVED:
            return {
                ...state,
                messageList: [...action.messageList]
            };
        case messageConstant.GET_CUSTOMERSERVICE_SUCCESS:
            return {
                ...state,
                customerServiceListIds:[...action.customerServiceListIds]
            };

        case messageConstant.SET_PAGE:
            return {
                ...state,
                page: action.page
            };
        
        case messageConstant.SET_ROWS:
            return {
                ...state,
                rows: action.rows
            };
        case messageConstant.SELECT_MESSAGE:
            return {
                ...state,
                selectMessageId:action.id
            }

        case userConstant.LOGOUT:
            return {};
        default:
            return state;
    }
}