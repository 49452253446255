import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Switch from '@material-ui/core/Switch';

import { isPatient, isDoctor, getPersonId } from '../../_helpers/authorized';
import { callService } from '../../_services';
import { alertAction } from '../../_actions';


class Dashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            msgError: null,
            msgSuccessMedicalInfo: false,
            isChecked: false
        };
    }

    async componentDidMount() {
        if (isDoctor()) {
            try {
                const personId = getPersonId();
                const lastPause = await callService.getLastPauseCall(personId);
                // console.log(call)
                this.setState({
                    isChecked: lastPause.isPaused
                });
            } catch (error) {
                this.props.alert("Error", ["Error on reading pause callback"]);
            }
        }
    }

    renderPatientPanel() {
        const { noMedicalInfo, noSleepInfo } = this.props;

        return (
            <div>
                {noMedicalInfo &&
                    <div className="alert alert-warning" role="alert">
                        Please add your medical information to unlock the other features.
                    </div>
                }
                <div className='row dashboard'>
                    <div className='col-md-4'>
                        {this.props.noMedicalInfo ?
                            <Link to="/request-prescription/medicalinfo" className='link-dashboard link-disabled' onClick={(e) => e.preventDefault()}>
                                <div className='div-link-dashboard'>
                                    <div className='container-icon-link-dashboard'>
                                        <img src={require('../../assets/img/prescription.svg')} className='icon-link-dashboard' alt='Prescription Icon' title='Prescription Icon' />
                                    </div>
                                    Request a prescription
                                </div>
                            </Link>
                            :
                            <Link to="/request-prescription/medicalinfo" className='link-dashboard'
                                data-test-id="dashboard__request-prescription"
                            >
                                <div className='div-link-dashboard'>
                                    <div className='container-icon-link-dashboard'>
                                        <img src={require('../../assets/img/prescription.svg')} className='icon-link-dashboard' alt='Prescription Icon' title='Prescription Icon' />
                                    </div>
                                    Request a prescription
                                </div>
                            </Link>
                        }
                    </div>
                    <div className='col-md-4'>
                        <Link to="/sleep-info" className="link-dashboard">
                            <div className='div-link-dashboard'>
                                <div className='container-icon-link-dashboard'>
                                    <img src={require('../../assets/img/lung.png')} className='icon-link-dashboard' alt='Sleep Info Icon' title='Sleep Info Icon' />
                                </div>
                                {/* {this.props.noSleepInfo ? 'Enter ' : 'Request'} */}
                                Request sleep referral
                            </div>
                        </Link>
                    </div>
                    <div className='col-md-4'>
                        <Link to="/medicalInfo/edit" className="link-dashboard">
                            <div className='div-link-dashboard'>
                                <div className='container-icon-link-dashboard'>
                                    <img src={require('../../assets/img/medicalinfo.svg')} className='icon-link-dashboard' alt='Medical Info Icon' title='Medical Info Icon' />
                                </div>
                                {this.props.noMedicalInfo ? 'Enter ' : 'Update '}
                                medical information
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        );
    }

    changeCheckbox = async () => {
        try {
            const personId = getPersonId();
            await callService.createPauseCallBack(personId, !this.state.isChecked);
            this.setState({
                isChecked: !this.state.isChecked
            });
        } catch (error) {
            this.props.alert("Error", ["Error on setting pause callback"]);
        }        
    }

    renderDoctorPanel(){
        // console.log(this.state.isChecked)
        return (
            <div className='row dashboard'>
                <div className='col-md-12'>
                    <h2> Call Settings </h2>
                    <p> The call can be controlled by the settings below</p>
                    <hr/>
                                 
                    {/* <input 
                        type="checkbox" 
                        id="checkbox"
                        className="custom-control-input"
                        onChange={this.changeCheckbox} 
                        checked={this.state.isChecked}
                    /> */}
                    {/* <label 
                        className="custom-control-label" 
                        htmlFor="checkbox"
                    >
                        Pause Callback
                    </label> */}
                    <table style={{width:"100%"}}>
                        <tr>
                            <td>
                                <strong>
                                    Pause Callback
                                </strong>
                            </td>
                            <td>
                                <Switch
                                    onChange={this.changeCheckbox} 
                                    checked={this.state.isChecked}
                                />
                            </td>
                        </tr>
                    </table>
                    
            
                </div>
            </div>
        )

    }

    render() {
        return (
            <div>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    {/* <h1 className="h2 h1-header">Dashboard</h1> */}
                </div>
                {isPatient() && this.renderPatientPanel()}
                {isDoctor() && this.renderDoctorPanel()}
            </div>
        );
    }
}


Dashboard.propTypes = {
    noMedicalInfo: PropTypes.bool
};


function mapStateToProps(state) {
    const { noMedicalInfo } = state.medicalInfo;
    const { noSleepInfo } = state.sleepInfo;

    return {
        noMedicalInfo,
        noSleepInfo
    };
}


function mapDispatchToProps(dispatch) {
    return {
        alert: (error, message) => dispatch(alertAction.error(error, message)),
    };
}


const temp = connect(mapStateToProps, mapDispatchToProps)(Dashboard);

export { temp as Dashboard };

