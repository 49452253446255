import { alertConstant, userConstant } from '../_constants';

export function alert(state = {}, action) {
    switch (action.type) {
        case alertConstant.SUCCESS:
            return {
                type: 'success',
                title: action.title,
                messages: action.messages,
                isOpen: true
            };
        case alertConstant.INFO:
            return {
                type: 'info',
                title: action.title,
                messages: action.messages,
                isOpen: true
            };
        case alertConstant.WARNING:
            return {
                type: 'warning',
                title: action.title,
                messages: action.messages,
                isOpen: true
            };
        case alertConstant.ERROR:
            return {
                type: 'danger',
                title: action.title,
                messages: action.messages,
                isOpen: true
            };
        case alertConstant.CLEAR:
            return {
                ...state,
                isOpen: false
            };
        case userConstant.LOGOUT:
            return {};
        default:
            return state;
    }
}