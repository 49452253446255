import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { registrationAction } from '../../_actions';
import { AgreementDialog } from './AgreementDialog';
import { TermsOfUseDialog } from '.';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { profileConstant } from '../../_constants';



class PrivacyPolicyDialog extends React.Component {
    state = { showFeesModal: false };
    constructor(props) {
        super(props);
    }

    handleFeesModalOpen = () => {
        this.setState({ showFeesModal: true });
    };

    handleFeesModalClose = () => {
        this.setState({ showFeesModal: false });
    };

    handleTermsOfUse = () => {
        const { dispatch } = this.props;
        dispatch(registrationAction.closePrivacyPolicy());
        dispatch(registrationAction.openTermsOfUse());
    }

    handleClose = (checked) => {
        const { dispatch } = this.props;
        dispatch(registrationAction.closePrivacyPolicy(checked));
    };

    buildContent() {
        return (
            <>
                <div className="title">
                <div>{profileConstant.DOMAIN_NAME}</div>
                    <div>PRIVACY POLICY</div>
                </div>
                <div className="section">
                    <div>{profileConstant.UPDATE_DATE_AND_VERSION}</div>
                    <div>
                        Welcome to {profileConstant.DOMAIN_NAME} (the <strong>“Portal”</strong>). This Portal is operated by {profileConstant.COMPANY_NAME}. (<strong>“{profileConstant.COMPANY_NAME},” “we,” “us,” “our”</strong>).
                        Our Portal allows patients to have virtual medical appointments with our physicians and receive
                        other services regarding your patient care needs.
                    </div>
                    <div>
                        {profileConstant.COMPANY_NAME} is committed to protecting the privacy of the patients who use this Portal (“you”, “yours”) and
                        seeks to provide you with a completely safe and secure user experience. We are pleased to
                        provide you with our Privacy Policy to inform you of our practices of how we collect, use,
                        disclose and safeguard your personally identifiable information during your use of this Portal
                        and the part that you play in safeguarding your personal information while using this Portal.
                    </div>
                    <div>
                        This Privacy Policy and our practices have been designed to comply with the Personal
                        Information Privacy Act, ch. 63 SBC 2003, as amended from time to time (<strong>“PIPA”</strong>) and to meet
                        or exceed the relevant guidelines and standards published by the Doctors of {profileConstant.PROVINCE}, the
                        College of Physicians and Surgeons of {profileConstant.PROVINCE}, and the Office of the Information and Privacy
                        Commissioner of British Columbia.
                    </div>
                </div>
                <div className="section">
                    <div className="title">
                        1. HOW WE COLLECT, USE AND DISCLOSE YOUR PERSONAL INFORMATION
                    </div>
                    <div>
                        The Portal provides a secure online platform which allows you to receive patient care services
                        (the “Services”) from one of our physicians licensed to practice medicine in the Province of
                        British Columbia and with the support of our staff. These Services are only available to patients
                        19 years old or older who are located in British Columbia at the time of seeking and obtaining
                        our Services, and who have provided his or her personal information and identification which we
                        require to create a patient user account with us (the <strong>“Patient User Account”</strong>), and who have
                        agreed to this Privacy Policy and <a href="#" onClick={this.handleTermsOfUse}>Terms of Use</a>.
                    </div>
                    <div>
                        We collect your personal information to create your Patient User Account and to verify your
                        identity as you use the Portal. Your Patient User Account shall not be accessed by anyone
                        other than the patient who created the Patient User Account. For clarity, a parent, family
                        member or any other third party may not access the Patient User Account on behalf of a patient.
                    </div>
                    <div>
                        {profileConstant.COMPANY_NAME} uses this Portal to collect, use and disclose your personal information, including your sensitive
                        personal health information, to provide you with our Services. Our Services include virtual
                        medical appointments and other communications to attend to your patient care needs. Our
                        Services also include using this Portal to communicate with you about our patient care related
                        inquiries, recommendations, and diagnosis, and to provide you with requisitions and referrals for
                        any specific laboratory services, imaging, tests, procedures, specialist referral, and treatment
                        referral, and to share test results with you. We will also use this Portal to provide you, and if you
                        request your Pharmacist, with prescriptions for your patient care. We also utilize the third party
                        payment processor, PayPal, to collect payment by you in accordance with our <Link to="#" onClick={this.handleFeesModalOpen}>Patient Fee Schedule</Link>.
                    </div>
                    <div>
                        We communicate with you through the applications available on this Portal such as messaging
                        and video-conferencing, as well as text messages and email communications.
                        To facilitate the use of our Services, {profileConstant.COMPANY_NAME} may automatically collect certain types of information when
                        you access the Portal, including your Internet Protocol (IP) address, location, browser, and
                        access times. This information is used to analyze the use of resources, prevent fraud,
                        troubleshoot, and also to improve our Services and this Portal.
                    </div>
                    <div>
                        We may disclose your personal information obtained through the use of this Portal to yourself
                        and to your Pharmacist for the purposes of filling your prescriptions. We may also disclose your
                        personal information to other health professionals if such disclosure is required to provide you
                        with our Services. For instance, we may disclose your personal information we obtain through
                        this Portal when answering inquiries by health care professionals pertaining to any requests for
                        laboratory services, imaging, testing, specialist referrals and treatment referrals we have made
                        as a result of you seeking our Services.
                    </div>
                    <div>
                        We will not otherwise disclose your personal information unless we have your consent or are
                        required or permitted by law to disclose your personal information without your expressed
                        consent. PIPA permits the disclosure of your personal information without expressed consent
                        where such disclosure is clearly in your interest and your consent cannot be obtained in a timely
                        way, or where the disclosure is necessary for your medical treatment and you do not have the
                        legal capacity to give consent. We may also be permitted or required to disclose your personal
                        information without your expressed consent in other exceptional circumstances, such as when
                        required by law or legal process (such as a Court order or subpoena), or to address actual or
                        threatened illegal or harmful conduct.
                    </div>
                    <div>
                        In general, we will limit the amount and type of personal information that we collect from you to
                        that which is necessary for providing our Services and attending to your patient care needs.
                    </div>
                </div>
                <div className="section">
                    <div className="title">
                        2. HOW WE WORK WITH YOU TO SAFEGUARD YOUR PERSONAL INFORMATION
                    </div>
                    <div>
                        {profileConstant.COMPANY_NAME} has imposed stringent safeguards to protect your personal information, including your personal
                        health information accessible on this Portal. Security measures have been integrated into the
                        design, implementation, and day-to-day operating practices of the Portal. We use a
                        combination of firewall barriers, encryption techniques (https) and authentication procedures,
                        among others, to maintain the security of your online communications and to protect accounts
                        and systems from unauthorized access. Communications are encrypted using the Secure
                        Socket Layer (SSL) system. Our physicians and staff will only log into the Portal through a secure 2-step authenticated network. Any payment transactions will be encrypted using SSL
                        technology. We utilize up-to-date anti-malware software as well as audit trails to record user
                        access. Our cloud services are secure and store your personal information within Canada.
                        Our employees are contractually required to maintain the confidentiality of your personal
                        information and may not use such personal information for any unauthorized purpose.
                    </div>
                    <div>
                        The contact information for our Privacy Officer is below and you should contact our Privacy
        Officer if you have any questions or concerns about how {profileConstant.DOMAIN_NAME} is handling your personal information.
                    </div>
                    <div>
                        We require, however, that you do your part to protect and safeguard your personal information
                        when using this Portal. As required by our Terms of Use, we require that you:
                    </div>
                    <ul>
                        <li>
                            Use only safe, encrypted internet access on each and every occasion that you log in to,
                            create and/or modify your Account. You are not to use unsecured public networks, such
                            as public wi-fi from a hotel or coffee shop, when creating or accessing your Patient User
                            Account;
                        </li>
                        <li>
                            Do not save your password for your Account to your phone, computer or other electronic
                            device;
                        </li>
                        <li>
                            Do not, at any time, share your password with another individual;
                        </li>
                        <li>
                            Notify us immediately of any unauthorized access to or use of your Patient User
                            Account, including your user name or password, or believe that there is a risk of the
                            potential unauthorized use of your Patient User Account including your user name and
                            password;

                        </li>
                        <li>
                            Ensure that you logout from your Patient User Account at the end of each session.
                            We are not responsible for any breaches of privacy resulting from your failure to comply with
                            these safeguards.
                        </li>
                    </ul>
                </div>
                <div className="section">
                    <div className="title">
                        3. YOUR CONSENT
                    </div>
                    <div>
                        By clicking that you agree to the terms of our Privacy Policy, and by continuing to access and
                        use the Portal, you consent and agree to our collection, use and disclosure of your personal
                        information in accordance with this Privacy Policy. We require that you carefully read 1. How
                        we collect, use and disclose your personal information, and 2. How we work with you to
                        safeguard your personal information before agreeing to our Privacy Policy so as to ensure that
                        you are providing informed and meaningful consent. If you do not agree to the Privacy Policy,
                        you must not access or use the Portal.
                    </div>
                    <div>
                        You may change or withdraw your consent at any time. All communications with respect to such
                        withdrawal or variation of consent should be in writing and addressed to our Privacy Officer. If
                        you withdraw or do not provide us with your consent to our collection, use, and disclosure of
                        your personal information, you may limit or prevent us from being able to provide you with our
                        Services.
                    </div>
                </div>
                <div className="section">
                    <div className="title">
                        4. HOW TO ACCESS AND MODIFY YOUR PERSONAL INFORMATION
                    </div>
                    <div>
                        {profileConstant.COMPANY_NAME} believes in the right of individuals to access their health information and manage their
                        information. You have the right, upon submitting a request to us, to be informed of the
                        existence, use and disclosure of your personal information under our control, subject to certain
                        exceptions set out in applicable law. Please direct your requests for access to your personal
                        information to our Privacy Officer. We may request that you provide us with sufficient detail and
                        information to verify your identity and to enable us to adequately respond to your request. We
                        will attempt to respond to requests promptly and, in any event, within such timelines as may be
                        stipulated by applicable law. We may charge you a minimal fee to access your personal
                        information; however, we will advise you of any fee in advance. In the event we cannot provide
                        you with access to your personal information, we will endeavour to inform you of the reasons
                        why, subject to any legal or regulatory restrictions.
                    </div>
                    <div>
                        It is important that your personal information we collect is accurate and complete. Having
                        accurate information about you enables us to provide you with the best possible patient care.
                        You may update, correct, and otherwise revise personal information that you provide to us by
                        accessing your Patient User Account or by contacting our Privacy Officer.
                    </div>
                    <div>
                        Despite our best efforts, errors may occur. If a patient demonstrates to us that his or her
                        personal information is inaccurate, incomplete, or not up-to-date in our records, we will revise
                        our records accordingly and, where relevant, make reasonable efforts to advise relevant third
                        parties to whom that information has been disclosed. Please direct all requests for revision of
                        personal information to our Privacy Officer.
                    </div>
                </div>
                <div className="section">
                    <div className="title">
                        5. OUR RECORD RETENTION AND DESTRUCTION PRACTICES
                    </div>
                    <div>
                        We retain your medical records for a minimum period of 16 years from either the date of the last
                        entry or from the age of majority (whichever is later), in accordance with our obligations under
                        PIPA and the regulatory requirements established by the Doctors of {profileConstant.PROVINCE} and the
        College of Physicians and Surgeons of {profileConstant.PROVINCE} {profileConstant.DOMAIN_NAME} has appropriate procedures in place with respect to
                        the secure destruction, deletion, and disposition of your personal information when we are no
                        longer legally obliged to retain your personal information.
                    </div>
                </div>
                <div className="section">
                    <div className="title">
                        6. PRIVACY BREACH RESPONSE
                    </div>
                    <div>
                        Despite our best efforts to ensure that third parties will not access or obtain collected personal
                        information in contravention of this Privacy Policy, we cannot and do not guarantee complete
                        confidentiality and security of your personal information, as communication via the internet is
                        subject to interception, loss, or alteration. You acknowledge and agree that any information you
                        communicate to us over the internet is at your own risk.
                    </div>
                    <div>
                        The security of your personal information is important to us; please advise our Privacy Officer
                        immediately of any incident involving the loss or unauthorized access to or disclosure of
                        Personal Information that is in our custody or control.
                    </div>
                    <div>
                        Employees of {profileConstant.COMPANY_NAME} are required to immediately report any breach of privacy, or suspected breach of
        privacy, to {profileConstant.COMPANY_NAME}’s Privacy Officer. A breach of privacy includes unauthorized external or internal
                        access to our physical records or electronic records, misdirected communications, including
                        mail, fax, and electronic communications, and the loss or theft of physical records or electronic
                        records stored on data storage devices.
                    </div>
                    <div>
                        The {profileConstant.COMPANY_NAME} Privacy Officer will coordinate a review of the matter with our management team and
                        investigate all reported privacy breaches or suspected privacy breaches. If we become aware
                        of a privacy breach concerning your personal information we will notify you in accordance with
                        our obligations under PIPA, and may report the breach to the Office of the Information and
                        Privacy Commissioner or to the appropriate authorities, including the police.
                    </div>
                </div>
                <div className="section">
                    <div className="title">
                        7. CONCERNS OR COMPLAINTS RELATED TO YOUR PERSONAL INFORMATION
                    </div>
                    <div>
                        {profileConstant.COMPANY_NAME} has designated a Privacy Officer who is responsible for coordinating {profileConstant.COMPANY_NAME}’s compliance with this Privacy Policy and with {profileConstant.COMPANY_NAME}’s obligations generally under PIPA. The contact information for our
                        Privacy Officer is as follows:
                    </div>
                    <div className="sub-section">
                        <div>
                            Attention: Ying Pang, Privacy Officer
                        </div>
                        <div>
                            Email: <a href="mailto:info@askmedication.com">info@askmedication.com</a>
                        </div>
                        <div>
                            Address: 4651 No. 3 Road, Richmond, B.C. V6X 2C4
                        </div>
                    </div>
                    <div>
                        We are committed to having an accessible and responsible complaint handling process in place
                        to ensure you can express concerns about compliance with PIPA and other regulatory
                        requirements. Any complaints, disputes, or other feedback concerning our privacy practices,
                        procedures, and this Privacy Policy can be submitted directly to our Privacy Officer. Where
                        appropriate, we will provide a written response to you and may amend our privacy practices and
                        procedures or this Privacy Policy.
                    </div>
                    <div>
                        If you have raised a concern with our Privacy Officer and are not satisfied in how performed its
        duties under PIPA, or wish to seek a review of {profileConstant.COMPANY_NAME}’s response to your privacy concern, access or
                        correction request you can contact the Office of the Information and Privacy Commissioner.
                    </div>
                    <div className="sub-section">
                        <div>
                            WebPortal: <a href="https://www.oipc.bc.ca">www.oipc.bc.ca</a>
                        </div>
                        <div>
                            Phone number: (250) 387-5629
                        </div>
                        <div>
                            Email: <a href="mailto:info@oipc.bc.ca">info@oipc.bc.ca</a>
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="title">
                        8. UPDATES TO OUR PRIVACY POLICY
                    </div>
                    <div>
                        This Privacy Policy may be updated from time to time and you may obtain this Privacy Policy
                        and any related information from our website at <a href="https://www.askmedication.com">AskMedication.com</a> or upon request by contacting us at <a href="mailto:info@askmedication.com">info@askmedication.com</a>.
                        Any and all such modifications are effective immediately upon posting and apply
                        to all access to and continued use of the Portal. You should periodically review this Privacy
                        Policy in order to be aware of any such modifications. Your continued use of the Portal
                        constitutes your acceptance of such modifications.
                    </div>
                    <div>
                        This Privacy Policy does not create or confer upon any individual any rights, or impose upon {profileConstant.COMPANY_NAME} any rights or obligations outside of, or in addition to, any rights or obligations imposed by PIPA
                        and any other federal or provincial privacy laws, as applicable. Should there be, in a specific
                        case, any inconsistency between this Privacy Policy and any such laws, this Privacy Policy shall
                        be interpreted in respect of that case, to give effect to, and comply with, such privacy laws. 
                    </div>
                </div>
                <TermsOfUseDialog />
                <Dialog
                    open={this.state.showFeesModal}
                    onClose={this.handleFeesModalClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Fees"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                        {/* Please note that given the COVID-19 pandemic ALL our services are free if you have a valid B.C. Services Card (CareCard) or equivalent provincial health card (Alberta, Ontario, etc.). */}
                        Please note that given the COVID-19 pandemic ALL our services are free if you have a valid B.C. Services Card (CareCard). Usually this service would be private pay and cost $20 for a prescription containing up to 2 medications and $10 more for each subsequent medication.
                        </DialogContentText>
                        {/* <hr />
                        <DialogContentText>
                            <span className="m-3">1st prescription: <strong>$20.00</strong></span>
                        </DialogContentText>
                        <DialogContentText>
                            <span className="m-3">Subsequent prescription(s): <strong>$10.00</strong></span>
                        </DialogContentText> */}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleFeesModalClose} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }

    render() {
        const { openPrivacyPolicy } = this.props;

        return (
            <AgreementDialog
                open={openPrivacyPolicy}
                title="Privacy Policy"
                content={this.buildContent()}
                onClose={(checked) => this.handleClose(checked)}
            />
        );
    }
}

PrivacyPolicyDialog.propTypes = {
    dispatch: PropTypes.func,
    openPrivacyPolicy: PropTypes.bool
};


function mapStateToProps(state) {
    let { openPrivacyPolicy } = state.registration;

    if (openPrivacyPolicy === undefined) {
        openPrivacyPolicy = false;
    }

    return {
        openPrivacyPolicy
    };
}

const temp = connect(mapStateToProps)(PrivacyPolicyDialog);

export { temp as PrivacyPolicyDialog };