import React from 'react';
import { connect } from 'react-redux';


class FAQ extends React.Component{
    constructor(props){
        super(props);
        this.email = "info@askmedication.com";
        this.list = [{
                q: <React.Fragment>
                    Do I need a cell phone (mobile phone) to use AskMedication.com?
                </React.Fragment>,
                a: <React.Fragment>
                    It is best if you provide a cell phone that is able to receive text messages when registering for AskMedication.com. If you ever forget your password, you will be able to reset your password using this cell phone number. Therefore, please do not provide a landline as your cell phone number when you register for AskMedication.com.
                </React.Fragment>
            },
            {
                q: <React.Fragment>
                    I forgot my password. What do I do?
                </React.Fragment>,
                a: <React.Fragment>
                    Please use the “Forgot your password?” function found on the login page of <a href="https://askmedication.com/">AskMedication.com</a> to reset your password. This will require you to obtain a text message on the provided cell phone. If you did not provide the correct cell phone number or cannot receive text messages, you will not be able to reset your password on your own. You will have to get in touch with us at {this.email}.
                </React.Fragment>
            },
            {
                q: <React.Fragment>
                    What do I do if I have trouble signing up or logging into AskMedication.com?    
                </React.Fragment>,
                a: <React.Fragment>
                    Signing up and logging into AskMedication.com should be straightforward. However, if you encounter any problems, please email us at {this.renderEmailLink()}. It will be a pleasure to assist you. 
                </React.Fragment>
            },
            {   
                q: <React.Fragment>
                    Why do I need to provide my phone number(s) when registering for AskMedication.com?
                </React.Fragment>,
                a: <React.Fragment>
                    We ask you to provide us the best number(s) to contact you because a doctor will need to speak with you in order to authorize your prescription. Only after the doctor speaks to you can we send the prescription to the pharmacy.
                </React.Fragment>
            },
            {   
                q: <React.Fragment>
                    Can minors use AskMedication.com?
                </React.Fragment>,
                a: <React.Fragment>
                    Sorry, AskMedication.com is only available for users aged 19 years or above.
                </React.Fragment>
            },         
            {   
                q: <React.Fragment>
                    Can I refill any medication using AskMedication.com?
                </React.Fragment>,
                a: <React.Fragment>
                    Please note that a variety of medications cannot be refilled using AskMedication.com. Examples include controlled substances such as opiates (“pain pills”) or benzodiazepines (“sleeping pills”). The medications that cannot be refilled appear in red and crossed-out when you search medications on AskMedication.com. A doctor will also review your requested medications and may not be able to authorize them. We apologize in advance should this happen. Most of this time, this is done in order to ensure the safety of our users.
                </React.Fragment>
            },            
            {   
                q: <React.Fragment>
                    When will my prescription be sent to the pharmacy?
                </React.Fragment>,
                a: <React.Fragment>
                    A doctor will call you back on your phone shortly after you submit your medication request on AskMedication.com. After talking with you, the doctor may authorize your prescription. It will be usually immediately faxed to the pharmacy of your choice. Please note that faxes are sometimes not properly received by the pharmacy. This can happen when the receiving fax machine is busy. Should your prescription not be available at your pharmacy within 24 hours of your phone call with the doctor, please email us back at {this.renderEmailLink()} with your name and preferred pharmacy. We will gladly resend your prescription.
                </React.Fragment>
            },          
            {   
                q: <React.Fragment>
                    Can I just send text messages to the doctor to authorize my prescription?
                </React.Fragment>,
                a: <React.Fragment>
                    Sorry, at the present time text messaging is insufficient to authorize a prescription. You will need to speak with a doctor over the phone.
                </React.Fragment>
            },      
            {   
                q: <React.Fragment>
                    Can I receive urgent medical advice using this service?
                </React.Fragment>,
                a: <React.Fragment>
                    Should you have an acute or severe medical condition, we suggest you reach your usual medical provider. Should you not be able to do this, please call 911 or head to your nearest hospital emergency department.
                </React.Fragment>
            },            
            {   
                q: <React.Fragment>
                    Can I refill antibiotics?
                </React.Fragment>,
                a: <React.Fragment>
                    This service is mostly for refilling regularly taken medication. Exceptionally, especially in the context of COVID-19, we may be able to accommodate some other conditions such as those requiring antibiotics. However, this will only take place on a case by case basis.
                </React.Fragment>
            }
        ];
    }

    renderEmailLink = () => {
        return <a href={`mailto:${this.email}`}>{this.email}</a>;
    }

    renderText = () => {
        return (
            <div>
                <h1>Frequently Asked Questions (FAQ)</h1>
                <div className="content-container">
                    <div className="item">
                        <p className="title"><span className="question">1)</span>Do I need a cell phone (mobile phone) to use AskMedication.com?</p>
                        <p className="text"><span className="answer">Answer:</span>It is best if you provide a cell phone that is able to receive text messages when registering for AskMedication.com. If you ever forget your password, you will be able to reset your password using this cell phone number. Therefore, please do not provide a landline as your cell phone number when you register for AskMedication.com.</p>
                    </div>
                    <div className="item">
                        <p className="title"><span className="question">2)</span>I forgot my password. What do I do?</p>
                        <p className="text"><span className="answer">Answer:</span>Please use the “Forgot your password?” function found on the login page of <a href="https://askmedication.com/">AskMedication.com</a> to reset your password. This will require you to obtain a text message on the provided cell phone. If you did not provide the correct cell phone number or cannot receive text messages, you will not be able to reset your password on your own. You will have to get in touch with us at info@askmedication.com.</p>
                    </div>
                    <div className="item">
                        <p className="title"><span className="question">3)</span>
                    What do I do if I have trouble signing up or logging into AskMedication.com?
                    </p>
                        <p className="text"><span className="answer">Answer:</span>Signing up and logging into AskMedication.com should be straightforward. However, if you encounter any problems, please email us at info@askmedication.com. It will be a pleasure to assist you. </p>
                    </div>
                    <div className="item">
                        <p className="title"><span className="question">4)</span>
                    Why do I need to provide my phone number(s) when registering for AskMedication.com?
                    </p>
                        <p className="text"><span className="answer">Answer:</span>
                    We ask you to provide us the best number(s) to contact you because a doctor will need to speak with you in order to authorize your prescription. Only after the doctor speaks to you can we send the prescription to the pharmacy.
                    </p>
                    </div>
                    <div className="item">
                        <p className="title"><span className="question">5)</span>Can I refill any medication using AskMedication.com?</p>
                        <p className="text"><span className="answer">Answer:</span>Please note that a variety of medications cannot be refilled using AskMedication.com. Examples include controlled substances such as opiates (“pain pills”) or benzodiazepines (“sleeping pills”). The medications that cannot be refilled appear in red and crossed-out when you search medications on AskMedication.com. A doctor will also review your requested medications and may not be able to authorize them. We apologize in advance should this happen. Most of this time, this is done in order to ensure the safety of our users.</p>
                    </div>
                    <div className="item">
                        <p className="title"><span className="question">6)</span>
                    When will my prescription be sent to the pharmacy?
                    </p>
                        <p className="text"><span className="answer">Answer:</span>
                    A doctor will call you back on your phone shortly after you submit your medication request on AskMedication.com. After talking with you, the doctor may authorize your prescription. It will be usually immediately faxed to the pharmacy of your choice. Please note that faxes are sometimes not properly received by the pharmacy. This can happen when the receiving fax machine is busy. Should your prescription not be available at your pharmacy within 24 hours of your phone call with the doctor, please email us back at info@askmedication.com with your name and preferred pharmacy. We will gladly resend your prescription.
                    </p>
                    </div>
                    <div className="item">
                        <p className="title"><span className="question">7)</span>
                    Can I just send text messages to the doctor to authorize my prescription?
                    </p>
                        <p className="text"><span className="answer">Answer:</span>
                    Sorry, at the present time text messaging is insufficient to authorize a prescription. You will need to speak with a doctor over the phone.
                    </p>
                    </div>
                    <div className="item">
                        <p className="title"><span className="question">8)</span>
                    Can I receive urgent medical advice using this service?
                    </p>
                        <p className="text"><span className="answer">Answer:</span>
                    Should you have an acute or severe medical condition, we suggest you reach your usual medical provider. Should you not be able to do this, please call 911 or head to your nearest hospital emergency department.
                    </p>
                    </div>
                    <div className="item">
                        <p className="title"><span className="question">9)</span>
                    Can I refill antibiotics?
                    </p>
                        <p className="text"><span className="answer">Answer:</span>
                    This service is mostly for refilling regularly taken medication. Exceptionally, especially in the context of COVID-19, we may be able to accommodate some other conditions such as those requiring antibiotics. However, this will only take place on a case by case basis.
                    </p>
                    </div>



                </div>
            </div>

        )
    }

    renderContent = () => {
        return <React.Fragment>
            <h1>Frequently Asked Questions (FAQ)</h1>
            <div className="content-container">
                {this.renderList()}
            </div>
        </React.Fragment>
    }

    renderList = () => {
        return this.list.map(({q, a}, index) => {
            return this.renderItem(q, a, index + 1);
        })
    }

    renderItem = (q, a, number) => {
        return <div className="item">
            <p className="title">
                <span className="question">
                    {number})
                </span>
                {q}
            </p>
            <p className="text">
                <span className="answer">Answer:</span>
                {a}
            </p>
        </div>
    }    

    render(){
        return(
            <section className="faq-container">
                
                {this.renderContent()}
                {/*this.renderText()*/}
            </section>
        )
    }

}

const temp = connect()(FAQ);
export {temp as FAQ}