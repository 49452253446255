import { faxService } from '../_services';
import { errorHandling, getFaxNumber } from '../_helpers';
import getUrlFromEle from '../_helpers/getUrlFromMultiCanvas'; 
import getUrlFromPages from '../_helpers/getUrlFromPages';
import { faxConstant } from '../_constants';
import { alertAction, loadingAction, messageAction } from './';
import config from '../config';


export const faxAction = {
    queuePrescriptionFax,
    queueSleepAssessmentFax,
    checkStatus
};


function queuePrescriptionFax(obj, url) {
    return async dispatch => {
        // dispatch(loadingOpen());
        // dispatch(alertAction.info('Queue prescription fax in progress...'));

        try {
            const dataURL = url;// await captureForm('prescription-form');

            let faxNumber = obj.pharmacy.fax_number;
            if (obj.drPharmacy) 
                faxNumber = obj.drPharmacy.fax_number;
            
            faxNumber = getFaxNumber(faxNumber);
            const res = await faxService.queuePrescriptionFax(faxNumber, dataURL, obj._id, obj.person._id);
            dispatch({
                type: faxConstant.QUEUE_PRESCRIPTION_FAX_SUCCESS,
                prescriptions: res.data
            });
            dispatch(alertAction.success('Queue prescription fax success.'));
        } catch (error) {
            errorHandling('fax.action.queuePrescription', error, dispatch);
        } finally {
            // dispatch(loadingClose());
        }
    };
}

function queueSleepAssessmentFax(obj) {
    return async dispatch => {
        dispatch(loadingOpen());
        dispatch(alertAction.info('Queue sleep assessment fax in progress...'));

        try {
            const dataURL = await captureForm('sleep-assessment-form');
            const faxNumber = getFaxNumber(obj.sleepClinic.faxNumber);
            const res = await faxService.queueSleepAssessmentFax(faxNumber, dataURL, obj._id, obj.person._id);

            dispatch({
                type: faxConstant.QUEUE_SLEEP_ASSESSMENT_FAX_SUCCESS,
                pendingList: res.data
            });
            dispatch(messageAction.countUnread());
            dispatch(alertAction.success('Queue sleep assessment fax success.'));
        } catch (error) {
            errorHandling('fax.action.queueSleepAssessment', error, dispatch);
        } finally {
            dispatch(loadingClose());
        }
    };
}

async function captureForm(formId) {
    const div = document.getElementById(formId);
    return getUrlFromEle(div);
}

async function captureTags(tagIds) {
    const tags = tagIds.map(tagId => document.getElementById(tagId));
    return await getUrlFromPages(tags);
}

// currently not used
function checkStatus() {
    return dispatch => {
        faxService.checkStatus()
            .then(
                () => {
                },
                error => {
                    errorHandling('manage-data.action.checkFax', error, dispatch);
                }
            )
            .finally(
            );
    };
}


function loadingOpen() { return loadingAction.open(); }
function loadingClose() { return loadingAction.close(); }