import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { profileAction, alertAction } from '../../_actions';
import { ProfileValidator } from '../../_validator';
import { getPersonId, formatDate, uploadBucket, appType, isDoctor, isPatient, isCustomerService, getS3ImageUrl, setStringCapital, getFormattedPhoneNumber } from '../../_helpers';
import { profileConstant } from '../../_constants';
import { CustomImage, RequiredLabel, DateOfBirth, ProvinceSelect } from '../../components';

class PersonalInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            edit: false,
            editImage: false
        };

        this.changeValue = this.changeValue.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleEditImage = this.handleEditImage.bind(this);
    }

    section = profileConstant.SECTION_PERSONAL_INFO;

    /**
     * Get the value from the inputs form
     */
    changeValue = (event) => {
        const { dispatch, user } = this.props;

        let value = event.target.value;
        // if (event.target.name == "PhoneNumber")
        //     value = value.replace(/-/g, '');

        user[event.target.name] = value;
        dispatch(profileAction.change(user));
    }

    changeDateOfBirth = (date) => {
        const { dispatch, user } = this.props;

        user.DateOfBirth = date;
        dispatch(profileAction.change(user));
    }

    /**
     * Get the file on the input file
     */
    changeFile = (files) => {
        const { dispatch, user } = this.props;

        user.profileImageFile = files && files[0];
        dispatch(profileAction.change(user));
    }

    /**
     * Get the signature of doctor
     */
    changeSignature = (files) => {
        const { dispatch, user } = this.props;
        user.signatureImage = files && files[0];
        dispatch(profileAction.change(user));
    }

    /**
     * validate the confirm field
     */
    validateConfirmField(field) {
        const { dispatch, user } = this.props;

        if (user[field] && user[`confirm${field}`] &&
            user[field] !== user[`confirm${field}`]) {
            user[`confirm${field}Error`] = true;
        } else {
            user[`confirm${field}Error`] = false;
        }

        dispatch(profileAction.change(user));
    }

    renderConfirmFieldError(field) {
        if (this.props.user[`confirm${field}Error`]) {
            return (
                <div className="confirm-error-div">
                    <label>{`Confirm ${field} doesn't match ${field}`}</label>
                </div>
            );
        }

        return '';
    }

    /**
     * Create the formData to send data and upload the file
     */
    createFormData() {
        const { user } = this.props;
        const formData = new FormData();

        formData.append('personId', getPersonId());
        formData.append('FirstName', user.FirstName);
        formData.append('LastName', user.LastName);
        formData.append('DateOfBirth', user.DateOfBirth);
        formData.append('PhoneNumber', user.PhoneNumber);
        formData.append('BestPhoneNumber', user.BestPhoneNumber);
        formData.append('Address', user.Address);
        formData.append('Province', user.Province);
        formData.append('PostalCode', user.PostalCode);
        formData.append('City', user.City);
        formData.append('file', user.profileImageFile);
        formData.append('signature', user.signatureImage);
        formData.append('Role', user.Role);

        return formData;
    }

    /**
     * Submit the form sending a post request to the API
     */
    handleSave = (event) => {
        event.preventDefault();
        const { dispatch, user } = this.props;
        const { Role } = user;

        if (Role === "patient") {
            const validator = new ProfileValidator();
            validator.personalInfo(user, this.state.editImage);

            if (validator.validate()) {
                //user.personId = getPersonId();
                const formData = this.createFormData();
                dispatch(profileAction.updatePersonalInfo(formData));
            } else {
                dispatch(alertAction.error('Form validation errors', validator.errorMsgs));
            }
        } else {
            const formData = this.createFormData();
            dispatch(profileAction.updatePersonalInfo(formData));
        }



        // const validator = new ProfileValidator();
        // validator.personalInfo(user, this.state.editImage);

        // if (validator.validate()) {
        //     //user.personId = getPersonId();
        //     const formData = this.createFormData();
        //     dispatch(profileAction.updatePersonalInfo(formData));
        // } else {
        //     dispatch(alertAction.error('Form validation errors', validator.errorMsgs));
        // }

    }

    handleEdit() {
        const { dispatch } = this.props;
        dispatch(profileAction.editSection(this.section));
    }

    handleCancel() {
        const { dispatch } = this.props;
        dispatch(profileAction.editSection(undefined));
        this.setState({
            editImage: false
        });
    }

    handleEditImage() {
        this.setState({
            editImage: true
        });
    }

    renderRegister() {
        const { user, edit } = this.props;
        const imageKey = `${uploadBucket.profileImage}/${user.profileImageKey}`;
        const width = profileConstant.IS_PROFILE_UPLOAD_ALLOWED ? 4 : 6;

        if (appType() === 'office' || (appType() === undefined && window.location.pathname == '/office-registration')) {
            return (<div>
                <div className="d-flex justify-content-between align-items-center">
                    <h4 className="my-3">Personal Information</h4>
                </div>
                {edit && <div className="description mb-3">
                    <p>1) Please enter your legal name. It should match exactly the name on your government issued identification. Please do not use nicknames or other names.</p>
                    <p>2) Please note that for security purposes you will not be able to change your phone number. Therefore, please ensure to enter the correct information.</p>
                </div>}
                {edit && <div className="row">
                    <div className="col-md-4 col-sm-6 mb-3">
                        <RequiredLabel htmlFor="FirstName">
                            First name
                        </RequiredLabel>
                        <input type="text" className="form-control" id="FirstName" name="FirstName"
                            value={user.FirstName || ''} onChange={this.changeValue} required />
                        <div className="invalid-feedback">
                            Valid first name is required.
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6 mb-3">
                        <RequiredLabel htmlFor="LastName">
                            Last name
                        </RequiredLabel>
                        <input type="text" className="form-control" id="LastName" name="LastName"
                            value={user.LastName || ''} onChange={this.changeValue} required />
                        <div className="invalid-feedback">
                            Valid last name is required.
                        </div>
                    </div>
                    {/* <div className="col-md-4 col-sm-6 mb-3">
                        <RequiredLabel htmlFor="PhoneNumber">
                            Permanent cell phone number
                        </RequiredLabel>
                        <input type="text" className="form-control" id="PhoneNumber" name="PhoneNumber"
                            value={this.getFormattedPhoneNumber()} onChange={this.changeValue} required disabled={!edit} />
                    </div> */}
                </div>}

            </div>);
        } else if (appType() === 'client' || (appType() === undefined && window.location.pathname != '/office-registration')) {
            return (
                <div>
                    <div className="d-flex justify-content-between align-items-center">
                        <h4 className="my-3">Personal Information</h4>
                    </div>
                    {edit && <div className="description mb-3">
                        <p>1) Please enter your legal name. It should match exactly the name on your government issued identification. Please do not use nicknames or other names.</p>
                        <p>2) Please note that for security purposes you will not be able to change your cell phone number. Therefore, please ensure to enter the correct information.</p>
                    </div>}
                    {edit && <div className="row">
                        <div className={`col-md-${width} col-sm-6 mb-3`}>
                            <RequiredLabel htmlFor="FirstName">
                                First name
                        </RequiredLabel>
                            <input type="text" className="form-control" id="FirstName" name="FirstName"
                                value={user.FirstName || ''} onChange={this.changeValue} required />
                            <div className="invalid-feedback">
                                Valid first name is required.
                        </div>
                        </div>
                        <div className={`col-md-${width} col-sm-6 mb-3`}>
                            <RequiredLabel htmlFor="LastName">
                                Last name
                        </RequiredLabel>
                            <input type="text" className="form-control" id="LastName" name="LastName"
                                value={user.LastName || ''} onChange={this.changeValue} required />
                            <div className="invalid-feedback">
                                Valid last name is required.
                        </div>
                        </div>
                        {profileConstant.IS_PROFILE_UPLOAD_ALLOWED && <div className="col-md-4 col-sm-6 mb-3">
                            <RequiredLabel htmlFor="image">
                                Please upload a current colour photo of yourself clearly showing your face
                        </RequiredLabel>
                            <CustomImage imageKey={imageKey}
                                edit={edit}
                                onEdit={this.handleEditImage}
                                onChange={this.changeFile}
                            />
                        </div>}
                    </div>}
                    <div className="row">
                        {edit && <div className="col-md-6 col-sm-6 mb-3">
                            <RequiredLabel htmlFor="DateOfBirth">
                                Date of birth
                            </RequiredLabel>
                            <DateOfBirth value={user.DateOfBirth} onChange={this.changeDateOfBirth} />
                            <div className="invalid-feedback">
                                Date of birth is required.
                            </div>
                        </div>}
                        {/* <div className="col-md-4 col-sm-6 mb-3">
                        <RequiredLabel htmlFor="PhoneNumber">
                          Permanent cell phone number
                        </RequiredLabel>
                        <input type="text" className="form-control" id="PhoneNumber" name="PhoneNumber"
                            value={this.getFormattedPhoneNumber()} onChange={this.changeValue} required disabled={!edit} />
                    </div> */}
                        <div className="col-md-6 col-sm-6 mb-3">
                            <RequiredLabel htmlFor="Address">
                                Street Address
                        </RequiredLabel>
                            <input type="text" className="form-control" id="Address"
                                value={user.Address || ''} onChange={this.changeValue} name="Address" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 col-sm-6 mb-3">
                            <RequiredLabel htmlFor="City">
                                City
                             </RequiredLabel>
                            <input type="text" className="form-control" id="City" name="City"
                                value={user.City || ''} onChange={this.changeValue} />
                        </div>
                        <div className="col-md-4 col-sm-6 mb-3">
                            <RequiredLabel htmlFor="Province">
                                Province
                        </RequiredLabel>
                            <ProvinceSelect id="Province" name="Province"
                                value={user.Province || ''} onChange={this.changeValue} />
                        </div>
                        <div className="col-md-4 col-sm-6 mb-3">
                            <RequiredLabel htmlFor="PostalCode">
                                Postal Code
                        </RequiredLabel>
                            <input type="text" className="form-control" id="PostalCode" name="PostalCode"
                                value={user.PostalCode || ''} onChange={this.changeValue} />
                        </div>
                    </div>
                </div>
            )
        };
    }

    renderEdit() {
        const { user, edit } = this.props;
        const imageKey = `${uploadBucket.profileImage}/${user.profileImageKey}`;
        const SignatureKey = `${uploadBucket.signatureImage}/${user.signatureImageKey}`
        if (isDoctor() || isCustomerService()) {
            return (<div>
                <div className="d-flex justify-content-between align-items-center">
                    <h4 className="my-3">Personal Information</h4>
                    {!edit &&
                        <div>
                            <button className="btn btn-outline-secondary btn-sm mr-2" type="button" onClick={this.handleCancel}>Cancel</button>
                            <button className="btn btn-outline-success btn-sm" type="submit" onClick={this.handleSave}>Save</button>
                        </div>
                    }
                </div>
                {!edit && <div className="row">
                    <div className="col-md-4 col-sm-6 mb-3">
                        <label className="title">First name</label>
                        <div>{setStringCapital(user.FirstName)}</div>
                    </div>
                    <div className="col-md-4 col-sm-6 mb-3">
                        <label className="title">Last name</label>
                        <div>{setStringCapital(user.LastName)}</div>
                    </div>
                    {/* <div className="col-md-4 col-sm-6 mb-3">
                        <RequiredLabel htmlFor="PhoneNumber">
                          Permanent cell phone number
                        </RequiredLabel>
                        <input type="text" className="form-control" id="PhoneNumber" name="PhoneNumber"
                            value={this.getFormattedPhoneNumber()} onChange={this.changeValue} required disabled={!edit} />
                    </div> */}
                </div>}
                {!edit && isDoctor() && <div className="row">
                    <div className="col-md-6 col-sm-6 mb-3">
                        <RequiredLabel htmlFor="image">
                            Please upload your signature
                        </RequiredLabel>
                        <CustomImage imageKey={SignatureKey}
                            edit={true}
                            onEdit={this.handleEditImage}
                            onChange={this.changeSignature}
                        />
                    </div>


                </div>}
            </div>);
        } else if (isPatient()) {
            return (
                <div>
                    <div className="d-flex justify-content-between align-items-center">
                        <h4 className="my-3">Personal Information</h4>
                        {!edit &&
                            <div>
                                <button className="btn btn-outline-secondary btn-sm mr-2" type="button" onClick={this.handleCancel}>Cancel</button>
                                <button className="btn btn-outline-success btn-sm" type="submit" onClick={this.handleSave}>Save</button>
                            </div>
                        }
                    </div>
                    {!edit && <div className="description mb-3">We do not expect your name or date of birth to change. Email such requests with an explanation to <a href="mailto:info@askmedication.com?subject=Login Support for AskMedication.com">info@askmedication.com</a>.</div>}
                    {!edit && <div className="row">
                        <div className="col-md-4 col-sm-6 mb-3">
                            <label className="title">First name</label>
                            <div>{setStringCapital(user.FirstName)}</div>
                        </div>
                        <div className="col-md-4 col-sm-6 mb-3">
                            <label className="title">Last name</label>
                            <div>{setStringCapital(user.LastName)}</div>
                        </div>
                        {/* <div className="col-md-4 col-sm-6 mb-3">
                        <label className="title">Profile picture</label>
                        <CustomImage imageKey={imageKey} />
                    </div> */}
                        <div className="col-md-4 col-sm-6 mb-3">
                            <label className="title">Date of birth</label>
                            <div>{formatDate(user.DateOfBirth)}</div>
                        </div>
                    </div>}
                    <div className="row">
                        {/* {!edit &&  <div className="col-md-4 col-sm-6 mb-3">
                        <label className="title">Date of birth</label>
                        <div>{formatDate(user.DateOfBirth)}</div>
                    </div>}   */}
                        <div className="col-md-4 col-sm-6 mb-3">
                            <RequiredLabel htmlFor="BestPhoneNumber">
                                 Best Phone Number for Doctor to Reach You
                             </RequiredLabel>
                            <input type="text" className="form-control" id="BestPhoneNumber" name="BestPhoneNumber"
                                value={user.BestPhoneNumber|| ''} onChange={this.changeValue} />
                        </div>
                        <div className="col-md-4 col-sm-6 mb-3">
                            <label className="title">Cell Phone Number</label>
                            <div>{user.PhoneNumber}</div>
                        </div>
                        {/* <div className="col-md-4 col-sm-6 mb-3">
                        <RequiredLabel htmlFor="PhoneNumber">
                          Permanent cell phone number
                        </RequiredLabel>
                        <input type="text" className="form-control" id="PhoneNumber" name="PhoneNumber"
                            value={user.PhoneNumber} onChange={this.changeValue} required disabled={!edit} />
                    </div> */}
                        <div className="col-md-4 col-sm-6 mb-3">
                            <RequiredLabel htmlFor="Address">
                                Address
                        </RequiredLabel>
                            <input type="text" className="form-control" id="Address"
                                value={user.Address || ''} onChange={this.changeValue} name="Address" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 col-sm-6 mb-3">
                            <RequiredLabel htmlFor="City">
                                City
                        </RequiredLabel>
                            <input type="text" className="form-control" id="City" name="City"
                                value={user.City || ''} onChange={this.changeValue} />
                        </div>
                        <div className="col-md-4 col-sm-6 mb-3">
                            <RequiredLabel htmlFor="Province">
                                Province
                        </RequiredLabel>
                            <ProvinceSelect id="Province" name="Province"
                                value={user.Province || ''} onChange={this.changeValue} />
                        </div>
                        <div className="col-md-4 col-sm-6 mb-3">
                            <RequiredLabel htmlFor="PostalCode">
                                Postal Code
                        </RequiredLabel>
                            <input type="text" className="form-control" id="PostalCode" name="PostalCode"
                                value={user.PostalCode || ''} onChange={this.changeValue} />
                        </div>
                    </div>
                </div>
            )
        };
    }

    renderDisplay() {
        const { user } = this.props;
        const imageKey = `${uploadBucket.profileImage}/${user.profileImageKey}`;
        const signatureKey = `${uploadBucket.signatureImage}/${user.signatureImageKey}`
        const url = getS3ImageUrl(signatureKey);
        if (isDoctor() || isCustomerService()) {
            return (
                <div>
                    <div className="d-flex justify-content-between align-items-center">
                        <h4 className="my-3">
                            Personal Information
                    </h4>
                        <div>
                            <button className="btn btn-outline-primary btn-sm" type="button" onClick={this.handleEdit}>Edit</button>
                        </div>
                    </div>
                    <div className="description mb-3">We do not expect your name or cell phone number to change. Email such requests with an explanation to <a href="mailto:info@askmedication.com?subject=Login Support for AskMedication.com">info@askmedication.com</a>.</div>
                    <div className="row">
                        <div className="col-md-4 col-sm-6 mb-3">
                            <label className="title">First name</label>
                            <div>{setStringCapital(user.FirstName)}</div>
                        </div>
                        <div className="col-md-4 col-sm-6 mb-3">
                            <label className="title">Last name</label>
                            <div>{setStringCapital(user.LastName)}</div>
                        </div>
                        <div className="col-md-4 col-sm-6 mb-3">
                            <label className="title">Cell Phone Number</label>
                            <div>{getFormattedPhoneNumber(user.PhoneNumber)}</div>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-md-4 col-sm-6 mb-3">
                            <label className="title">signature Image</label>
                            {/* {user.signatureKey && <CustomImage imageKey={signatureKey} />} */}
                            <img src={url} className="pointer" style={{ width: "200px", height: "50px" }} />
                        </div>

                    </div>


                </div>
            )
        } else if (isPatient()) {
            return (
                <div>
                    <div className="d-flex justify-content-between align-items-center">
                        <h4 className="my-3">
                            Personal Information
                    </h4>
                        <div>
                            <button className="btn btn-outline-primary btn-sm" type="button" onClick={this.handleEdit}>Edit</button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 col-sm-6 mb-3">
                            <label className="title">First name</label>
                            <div>{setStringCapital(user.FirstName)}</div>
                        </div>
                        <div className="col-md-4 col-sm-6 mb-3">
                            <label className="title">Last name</label>
                            <div>{setStringCapital(user.LastName)}</div>
                        </div>
                        {/* <div className="col-md-4 col-sm-6 mb-3">
                        <label className="title">Profile picture</label>
                        {user.profileImageKey && <CustomImage imageKey={imageKey} />}
                    </div> */}
                        <div className="col-md-4 col-sm-6 mb-3">
                            <label className="title">Date of birth</label>
                            {user.DateOfBirth && <div>{formatDate(user.DateOfBirth)}</div>}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 col-sm-6 mb-3">
                            <label className="title">Best Phone Number for Doctor to Reach You</label>
                            <div>{getFormattedPhoneNumber(user.BestPhoneNumber)}</div>
                        </div>
                        <div className="col-md-4 col-sm-6 mb-3">
                            <label className="title">Cell Phone Number</label>
                            <div>{getFormattedPhoneNumber(user.PhoneNumber)}</div>
                        </div>
                        <div className="col-md-4 col-sm-6 mb-3">
                            <label className="title">Street Address</label>
                            <div>{user.Address}</div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 col-sm-6 mb-3">
                            <label className="title">City</label>
                            <div>{user.City}</div>
                        </div>
                        <div className="col-md-4 col-sm-6 mb-3">
                            <label className="title">Province</label>
                            <div>{user.Province}</div>
                        </div>
                        <div className="col-md-4 col-sm-6 mb-3">
                            <label className="title">Postal Code</label>
                            <div>{user.PostalCode}</div>
                        </div>
                    </div>
                </div>

            )
        };
    }

    getFormattedPhoneNumber() {
        let { PhoneNumber = '' } = (this.props || {}).user || {};

        const len = PhoneNumber.length;
        if (len > 6)
            return PhoneNumber.slice(0, 3) + '-' + PhoneNumber.slice(3, 6) + '-' + PhoneNumber.slice(6);

        if (len > 3)
            return PhoneNumber.slice(0, 3) + '-' + PhoneNumber.slice(3);

        return PhoneNumber;
    }

    /**
     * Render the HTML template
     */
    render() {
        const { user, edit, editSection } = this.props;

        if (user === undefined) return '';

        if (edit) {
            // edit mode, use in Registration page
            return this.renderRegister();
        } else {
            if (editSection === this.section) {
                return (
                    <form className="w-100" onSubmit={this.handleSave} noValidate>
                        {this.renderEdit()}
                    </form>
                );
            } else {
                return this.renderDisplay();
            }
        }
    }
}


PersonalInfo.propTypes = {
    dispatch: PropTypes.func.isRequired,
    user: PropTypes.object,
    edit: PropTypes.bool,
    editSection: PropTypes.string
};


function mapStateToProps(state) {
    const { editSection, user } = state.profile;

    return {
        editSection,
        user: { ...user }
    };
}

const temp = connect(mapStateToProps)(PersonalInfo);

export { temp as PersonalInfo };
