import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { formatDateTime, multilineText } from '../../_helpers';
import { PersonIcon } from '../../components';
import { noteAction } from '../../_actions';


class NoteReply extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    acknowledge = () => {
        const { dispatch, note } = this.props;
        dispatch(noteAction.acknowledgeReply(note));
    }

    renderAcknowledgeBtn() {
        const { note } = this.props;
        const { message } = note;

        if (!message.isRead) {
            return (
                <button className="btn btn-warning btn-xs" onClick={this.acknowledge}>Acknowledge</button>
            );
        } else {
            return '';
        }
    }

    render() {
        const { note } = this.props;
        const { message } = note;

        if (message && message.replies.length > 0) {
            return (
                <div className="note-reply">
                    <div className="title">Replies</div>
                    {
                        message.replies.map((reply, index) => {
                            return (
                                <div key={index} className="reply">
                                    <div className="from">
                                        <PersonIcon className="person" person={reply.from} />
                                        <span className="replied-at">{formatDateTime(reply.repliedAt)}</span>
                                    </div>
                                    <div className="message">
                                        <div>
                                            {multilineText(reply.message)}
                                        </div>
                                        <div className="ml-2">
                                            {this.renderAcknowledgeBtn()}
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            );
        } else {
            return '';
        }
    }
}


NoteReply.propTypes = {
    dispatch: PropTypes.func.isRequired,
    note: PropTypes.object
};


const temp = connect()(NoteReply);

export { temp as NoteReply };