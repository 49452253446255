import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { appType } from  '../../_helpers'
import queryString from 'query-string';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { DialogActions } from '@material-ui/core';

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state= {
            isDialogOneOpen:false,
            isDialogTwoOpen:false,
            isDialogThreeOpen:false,
            showFeesModal: false,
        }
    }

    handleFeesModalOpen = () => {
        this.setState({ showFeesModal: true });
    };

    handleFeesModalClose = () => {
        this.setState({ showFeesModal: false });
    };
    
    componentDidMount() {
        this.redirect(this.props);
    }

    redirect(props) {
        const { location } = props;
        let params = queryString.parse(location.search);
        if (typeof params !== 'undefined' && params.redirect) {
            if (params.redirect == "recovery/password")
                props.history.push(`/recovery/password?redirect=${params.redirect}&token=${params.token}`);
            else
                props.history.push(`/registration?redirect=${params.redirect}&token=${params.token}`);
        }
    }

    redirectOfficeRegister(props) {
        const { location }  = props;
        let params = queryString.parse(location.search);
        if(typeof params !== 'undefined' && params.redirect) {
            props.history.push(`/registration?redirect=${params.redirect}&token=${params.token}`);
        }
    }

    handleClickOneOpen = () => {
        this.setState({
            isDialogOneOpen:true,
        })
    }

    handleClickTwoOpen = () => {
        this.setState({
            isDialogTwoOpen:true,
        })
    }

    handleClickThreeOpen = () => {
        this.setState({
            isDialogThreeOpen:true,
        })
    }

    handleCloseOne = () => {
        this.setState({
            isDialogOneOpen:false,
        })

    }

    handleCloseTwo = () => {
        this.setState({
            isDialogTwoOpen:false,
        })

    }

    handleCloseThree = () => {
        this.setState({
            isDialogThreeOpen:false,
        })

    }
    handleStyle = () => {
        return {
         fontSize:"20px",
        }
    }

    
    renderDialogOne = () => {
        const { isDialogOneOpen } = this.state;
        return (
            <>
            <Dialog 
                fullWidth={true}
                maxWidth="md"
                open={isDialogOneOpen}
                onClose={this.handleCloseOne}
            >
                <DialogContent>
                    <div style={{height:"400px"}}>
                            
                           {/* <p style={this.handleStyle()}>AskMedication.com allows you to connect with a doctor who will carefully review your prescription requests. A private fee is incurred. Please see our <Link to="#" onClick={this.handleFeesModalOpen}>Patient Fee Schedule</Link> for details. 
                            Please note that some medications, such as controlled substances, cannot be obtained using AskMedication.com. If we cannot complete your prescription request, we will refund 100% of your fees. Once approved, we will submit your prescription(s) to the pharmacy of your choice within British Columbia.
                            </p>
                            <p style={this.handleStyle()}>Our aim is to increase the efficiency of healthcare. Please note that the services provided by AskMedication.com are only available to individuals aged 19 years or older who are physically located in British Columbia, Canada.</p> */}

                            {/* <p style={this.handleStyle()}>AskMedication.com allows you to connect with a doctor who will carefully review your prescription requests. Given the COVID-19 pandemic ALL our services are free if you have a valid B.C. Services Card (CareCard) or equivalent provincial health card (Alberta, Ontario, etc.). Please note that some medications, such as controlled substances, cannot be obtained using AskMedication.com. Once approved, we will submit your prescription(s) to the pharmacy of your choice within British Columbia.
                            </p> */}
                            <p style={this.handleStyle()}>AskMedication.com allows you to connect with a doctor who will carefully review your prescription requests. Given the COVID-19 pandemic ALL our services are free if you have a valid B.C. Services Card (CareCard). Usually this service would be private pay and cost $20 for a prescription containing up to 2 medications and $10 more for each subsequent medication. Please note that some medications, such as controlled substances, cannot be obtained using AskMedication.com. Once approved, we will submit your prescription(s) to the pharmacy of your choice within British Columbia.
                            </p>
                            <p style={this.handleStyle()}>Our aim is to increase the efficiency of healthcare. Please note that the services provided by AskMedication.com are only available to individuals aged 19 years or older who are physically located in British Columbia, Canada.</p>
                            {this.renderSignButtons()}
                            {this.renderFeesDialog()}
                    </div>
                </DialogContent>
                <DialogActions>
                <button className="btn btn-outline-primary btn-sm mt-2 mr-2" onClick={this.handleCloseOne}>
                     Close
                </button>
                </DialogActions>
            </Dialog>
            </>

        )
    }

    renderDialogTwo = () => {

        const { isDialogTwoOpen } = this.state;
        return (
            
            <Dialog 
                fullWidth={true}
                maxWidth="md"
                open={isDialogTwoOpen}
                onClose={this.handleCloseTwo}
            >
                <DialogContent>
                    <div style={{ height: "400px" }}>
                        <p style={this.handleStyle()}>Do you snore? Is your sleep not restful? Are you fatigued during the day? AskMedication.com allows you to request a home sleep study for from your preferred sleep clinic. The sleep study will take place at your home. The sleep clinic will provide you a testing machine that you will use for one night. </p>
                        <p style={this.handleStyle()}>Please note that a private fee is incurred for this service.  Please see our <Link to="#" onClick={this.handleFeesModalOpen}>Patient Fee Schedule</Link> for details. The AskMedication.com doctor will ask you questions about your health and will instruct you to obtain blood pressure readings at home or at a location such as a pharmacy.</p>
                        <p style={this.handleStyle()}>Our aim is to increase the efficiency of healthcare. Please note that the services provided by AskMedication.com are only available to individuals aged 19 years or older who are physically located in British Columbia, Canada.
                        </p>
                        {this.renderSignButtons()}
                        {this.renderFeesDialog()}
                    </div>
                </DialogContent>
                <DialogActions>
                <button className="btn btn-outline-primary btn-sm mt-2 mr-2" onClick={this.handleCloseTwo}>
                     Close
                </button>
                </DialogActions>
            </Dialog>
            

        )
    }
    renderDialogThree = () => {

        const { isDialogThreeOpen } = this.state;
        return (
            
            <Dialog 
                fullWidth={true}
                maxWidth="md"
                open={isDialogThreeOpen}
                onClose={this.handleCloseThree}
            >
                <DialogContent>
                    <div style={{ height: "400px" }}>
                        <p style={this.handleStyle()}>
                            Patients will be able to obtain a referral to a medical specialist in British Columbia using <Link to="#" style={{ textDecoration: "underline" }}>AskMedication.com</Link>. Some restriction will apply.
                        </p>
                        {this.renderSignButtons()}
                    </div>
                </DialogContent>
                <DialogActions>
                <button className="btn btn-outline-primary btn-sm mt-2 mr-2" onClick={this.handleCloseThree}>
                     Close
                </button>
                </DialogActions>
            </Dialog>
            

        )
    }
    renderSignButtons = () => {
        return <div style = {{
            fontSize        : "30px",
            display         : "flex",
            justifyContent  : "space-evenly", 
            marginTop       : "30px"
        }}>
            <Link to="/login">Sign in</Link>
            <Link to="/registration">Create Account</Link>
        </div>
    }
    renderFeesDialog = () => {
        return (
            <>
                <Dialog
                    open={this.state.showFeesModal}
                    onClose={this.handleFeesModalClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Fees"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                        {/* Please note that given the COVID-19 pandemic ALL our services are free if you have a valid B.C. Services Card (CareCard) or equivalent provincial health card (Alberta, Ontario, etc.). */}
                        Please note that given the COVID-19 pandemic ALL our services are free if you have a valid B.C. Services Card (CareCard). Usually this service would be private pay and cost $20 for a prescription containing up to 2 medications and $10 more for each subsequent medication.
                        </DialogContentText>
                        {/* <hr />
                        <DialogContentText>
                            <span className="m-3">1st prescription: <strong>$20.00</strong></span>
                        </DialogContentText>
                        <DialogContentText>
                            <span className="m-3">Subsequent prescription(s): <strong>$10.00</strong></span>
                        </DialogContentText>
                        <DialogContentText>
                            <span className="m-3">Sleep apnea assessment request: <strong>$20.00</strong></span>
                        </DialogContentText> */}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleFeesModalClose} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }

    renderJumbotron = () => {
        return (
            <div className="jumbotron hero">
                <div className="container-fluid">
                    <div className="row ">
                        <div className="col learn-more-container">
                            <h2>Obtain a Doctor's Prescription Online</h2>
                            <p className="lead text">Skip the wait at the medical clinic and obtain your prescription online. Pick up your medication at any pharmacy in <span className="location">British Columbia</span>.</p>
                            <button className="btn btn-outline-primary btn-xs mt-2 mr-2" onClick={this.handleClickOneOpen}>
                            Learn More
                            </button>
                            {this.renderDialogOne()}
                        </div>
                        <div className="col with-logo"></div>
                        <div className = "col learn-more-container">
                            <h2>Sleep Apnea Assessments</h2>
                            <p className="lead text"> Let your preferred sleep clinic in <span className="location">British Columbia</span> know that you require a home assessment for sleep apnea.</p>
                            <br/>
                            <button className="btn btn-outline-primary btn-xs mt-2 mr-2" onClick={this.handleClickTwoOpen}>
                            Learn More
                            </button>
                            {this.renderDialogTwo()}
                        </div>
                        {/* <div className = "col learn-more-container">
                            <h2>Specialist Referral Service</h2>
                            <p className="lead text">Coming soon</p>
                            <button className="btn btn-outline-primary btn-xs mt-2 mr-2" onClick={this.handleClickThreeOpen}>
                            Learn More
                            </button>
                            {this.renderDialogThree()}
                        </div> */}

                    </div>
                    {/* <div className="logo-mobile-only">
                        <img width="175" src={require('../../assets/img/stethoscope-transparent.png')}className="pointer"/>
                    </div> */}
                </div>
            </div>
        );
    }

    renderStats () {
        return (
            <div className="stats-info container-fluid">
                <div className="row ">
                    <div className="accolade col-sm-3 mx-auto">
                        <div class="icon-header">
                            <i className="fas fa-prescription-bottle-alt"></i>
                            <p className="accolade-header">Prescription on demand</p>
                            <p>Refill most of your regularly taken medications.</p>            
                        </div>
                    </div>
                    <div className="accolade col-sm-3 mx-auto">
                        <div class="icon-header">
                            <i className="far fa-clock"></i>
                            <p className="accolade-header">No more waiting in line</p>
                            <p>We refund you if we cannot process your request.</p>
                        </div>
                    </div>
                    <div className="accolade col-sm-3 mx-auto">
                        <div class="icon-header">
                            <i className="fas fa-bed"></i>
                            <p className="accolade-header">Sleep Assessments</p>
                            <p>Provide a sleep apnea assessment request to your favorite sleep provider.</p>
                        </div>
                    </div>
                </div>
            </div> 
        );
    }

    renderInfo () {
        return (
            <div className="jumbotron info jumbotron-fluid">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col with-logo"></div>
                        <div className="col">
                            <h1 id="section-2" className="hero-header">Our Mission.</h1>
                            <p className="lead">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderSteps () {
        return (
            <div className="jumbotron steps jumbotron-fluid">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col">
                            <h1 id="section-2" className="hero-header">See what is available.</h1>
                            <p className="lead">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderText () {
    if (appType() === undefined || appType() === 'client') {
        return(
             <>
            <h1 className="jumbotron-heading mb-3">
                Medication Renewal
           </h1>
            <div className="lead text-muted">
                Skip the wait at the medical clinic and renew your medication online
          </div>
            <div className="lead text-muted">
                Pick up your medication at any pharmacy in British Columbia
         </div>
         </>)
   
    }  else if (appType() === 'office') {
        return (
            <h1 className="jumbotron-heading mb-3">
            Staff Login
            </h1>
        )   
    }
 }

    render() {
        return (
            <section className={"home-container " + (appType() === 'office' && ' home-container--office')} >
                {this.renderJumbotron()}
                {this.renderStats()}
                {/* Commenting Out for now as there is not enough content */}
                {/* {this.renderSteps()} */}
                {/* {this.renderInfo()} */}
                <div className="container">
                   {/* {this.renderText()}
                    <div className="mt-4">
                        <Link to="/login" className="btn btn-primary my-2 btn-home btn-lg">Login</Link>
                        <Link to="/registration" className="btn btn-secondary my-2 btn-home btn-lg">Register</Link>
                    </div> */}
                    <div className="col-12">
                        <div className="btn-bar mt-4 text-center lead text-muted">
                            If you require help logging in, please email us at <a href="mailto:info@askmedication.com?subject=Login Support for AskMedication.com">info@askmedication.com</a>.
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}


const temp = connect()(Home);

export { temp as Home };
