import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { registrationAction } from '../../_actions';
import { RequiredLabel } from '../../components';

class Confirm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: null,
            verificationCode: null
        };
    }

    handleChange = (event) => {
        const { dispatch, confirm } = this.props;

        confirm[event.target.name] = event.target.value;
        dispatch(registrationAction.confirmChange(confirm));
    }

    checkIsActivated = (event) => {
        const { dispatch } = this.props;
        const email = event.target.value;

        if (email !== '') {
            dispatch(registrationAction.isActivated(event.target.value));
        }
    }

    /**
     * Submit the form sending a post request to the API
     */
    submitForm = (event) => {
        event.preventDefault();

        const { dispatch, confirm } = this.props;
        dispatch(registrationAction.activate(confirm.email, confirm.verificationCode));
    }

    renderVerificationCode() {
        const { isActivated } = this.props;

        return (
            <div>
                <div className="row">
                    <div className="col-md-12 mb-3">
                        <RequiredLabel htmlFor="verificationCode">Verification code</RequiredLabel>
                        <input type="number" className="form-control" id="verificationCode" name="verificationCode"
                            onChange={this.handleChange} required />
                        <div className="invalid-feedback">
                            Verification code is required.
                        </div>
                    </div>
                </div>
                <div className="btn-bar mt-4">
                    <button className="btn btn-primary btn-lg" type="submit">Confirm</button>
                </div>
            </div>
        );
    }

    /**
     * Render the HTML template
     */
    render() {
        return (
            <div className="container">
                <div className="py-5 text-center">
                    <h2>E-mail Verification</h2>
                    <p className="lead">Please check your e-mail, and enter the provided verification code to confirm your registration.</p>
                </div>
                <div className="row">
                    <div className="col-md-6 offset-md-3">
                        <form className="needs-validation" onSubmit={this.submitForm}>
                            <div className="row">
                                <div className="col-md-12 mb-3">
                                    <RequiredLabel htmlFor="email">Email</RequiredLabel>
                                    <input type="email" className="form-control" id="email" name="email"
                                        onChange={this.handleChange} onBlur={this.checkIsActivated} required />
                                    <div className="invalid-feedback">
                                        Email is required.
                                    </div>
                                </div>
                            </div>
                            {this.renderVerificationCode()}
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}


Confirm.propTypes = {
    dispatch: PropTypes.func.isRequired,
    isActivated: PropTypes.bool,
    confirm: PropTypes.object
};


function mapStateToProps(state) {
    const { isActivated } = state.registration;
    let { confirm } = state.registration;

    if (confirm === undefined) {
        confirm = {};
    }

    return {
        isActivated,
        confirm
    };
}

const temp = connect(mapStateToProps)(Confirm);

export { temp as Confirm };