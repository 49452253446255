export const noteConstant = {
    GET_BY_PERSON: 'NOTE_GET_BY_PERSON',
    CREATED: 'NOTE_CREATED',
    EDIT: 'NOTE_EDIT',
    CANCEL_EDIT: 'NOTE_CANCEL_EDIT',
    CHANGE: 'NOTE_CHANGE',
    SAVED: 'NOTE_SAVED',
    SEND_NOTE_SUCCESS: 'NOTE_SEND_NOTE_SUCCESS',
    OPEN_REVISION: 'NOTE_OPEN_REVISION',
    CLOSE_REVISION: 'NOTE_CLOSE_REVISION',
    ACKNOWLEDGE_REPLY_SUCCESS: 'NOTE_ACKNOWLEDGE_REPLY_SUCCESS',
    GO_TO_NOTE: 'NOTE_GO_TO_NOTE'
};
