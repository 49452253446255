import React from 'react';
import ReactDomServer from 'react-dom/server';
import { Provider } from 'react-redux';

import { store } from './';
import getCanvasFromEle from './getCanvasFromEle';


export default async function getCanvasFromComp(comp) {
    const elem = document.createElement('div');
    const html = ReactDomServer.renderToStaticMarkup(
        <Provider store={store}>
            {comp}
        </Provider>
    );
    elem.innerHTML = html;
    elem.style.position = 'relative';
    elem.style.top = window.innerHeight + 'px';
    elem.style.width = 'fit-content';

    document.body.appendChild(elem);
    const canvas = await getCanvasFromEle(elem);
    document.body.removeChild(elem);

    return canvas;
}
