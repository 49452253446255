import { drugSearchConstant, userConstant } from '../_constants';

export function drugSearch(state = {}, action) {
    switch (action.type) {
        case drugSearchConstant.PRELOAD:
        case drugSearchConstant.ADD_DRUG:
        case drugSearchConstant.REMOVE_DRUG:
        case drugSearchConstant.UPDATE_DRUG_USAGE:
            return {
                ...state,
                drugs: [...action.drugs]
            };
        case drugSearchConstant.CHANGE:
            return {
                ...state,
                searchKey: action.searchKey
            };
        case drugSearchConstant.SEARCH_SUCCESS:
            return {
                ...state,
                searchList: [...action.searchList]
            };
        case drugSearchConstant.MULTIPLE_DRUG:
            return {
                ...state,
                multipleDrugs: [...action.multipleDrugs],
                drug: { ...action.drug },
                searchList: []
            };
        case drugSearchConstant.SHOW_USAGE:
            return {
                ...state,
                drug: { ...action.drug },
                searchList: [],
                multipleDrugs: [],
                showUsage: true
            };
        case drugSearchConstant.CLEAR_SEARCH:
            return {
                ...state,
                searchList: [],
                medicaitons: [],
                drug: null,
                showUsage: false
            };
        case drugSearchConstant.CLEAR_SEARCH_KEY:
            return {
                ...state,
                searchKey: ''
            };
        case drugSearchConstant.INIT:
        case userConstant.LOGOUT:
            return {};
        default:
            return state;
    }
}