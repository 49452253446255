class BaseValidator {
    errorMsgs;

    constructor() {
        this.errorMsgs = [];
    }
    
    validate = () => {
        if (this.errorMsgs.length === 0) {
            return true;
        } else {
            return false;
        }
    }

    validateField = (condition, msg)  =>{
        if (condition) this.errorMsgs.push(`${msg}`);
    }
    
}

export  { BaseValidator };