import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { messageAction } from '../../_actions';
import { messageConstant } from '../../_constants';
import { FileUploader } from '../../components';
import { getPersonId } from '../../_helpers';


const styles = {
    form: {
        padding: 20
    }
};


class NewMessageDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(messageAction.getCustomerService()); 
    }

    handleClose = () => {
        const { dispatch } = this.props;

        dispatch(messageAction.composeClose());
    }

    handleChange = event => {
        const { dispatch, sendMessageObj } = this.props;

        sendMessageObj[event.target.name] = event.target.value;

        // dispatch messageAction change
        dispatch(messageAction.change(sendMessageObj));
    }

    /**
     * Get the file on the input file
     */
    changeFile = (files) => {
        const { dispatch, sendMessageObj } = this.props;
        sendMessageObj.files = files;
        dispatch(messageAction.change(sendMessageObj));
    }


    handleSubmit(event) {
        event.preventDefault();

        const { dispatch, sendMessageObj, tabValue, messageList } = this.props;
        const reload = tabValue === messageConstant.TAB_SENT && messageList !== undefined;
        const from = getPersonId();
        const { to, subject, message, files } = sendMessageObj;
        for (let i = 0; i < to.length; i++) {
            dispatch(messageAction.create(from, to[i], subject, message, files, reload));
        }     
    }

    displayContent = () => {
        const { classes } = this.props;

        return (
            <form className={classes.form} noValidate onSubmit={this.handleSubmit}>
                <div className="form-group">
                    <label htmlFor="to">To</label>
                    <select className="form-control" id="to" name="to"  >
                        <option>Customer Service</option>
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="subject">Subject</label>
                    <input className="form-control" type="text" id="subject" name="subject" placeholder="subject" onChange={this.handleChange} autoFocus />
                </div>
                <div className="form-group">
                    <label htmlFor="message">Message</label>
                    <textarea className="form-control" id="message" name="message" rows="4" placeholder="message ..." onChange={this.handleChange} ></textarea>
                </div>                
                <FileUploader multiple={true} onChange={this.changeFile} accept="application/pdf, image/*" />
                <br />
                <button type="submit" className="btn btn-primary mt-4">Send</button>
            </form>
        );
    }

    render() {
        const { status } = this.props;


        return (
            <Dialog
                fullWidth={true}
                maxWidth="md"
                open={status === messageConstant.STATUS_COMPOSE}
                onClose={this.handleClose}
            >
                <DialogTitle className="no-print">
                    <span>Compose Message</span>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleClose}>
                        <i className="fa fa-close mx-2"></i>
                    </button>
                </DialogTitle>
                <DialogContent>
                    {this.displayContent()}
                </DialogContent>
            </Dialog>
        );
    }
}


NewMessageDialog.propTypes = {
    dispatch: PropTypes.func.isRequired,
    sendMessageObj: PropTypes.object,
    tabValue: PropTypes.string,
    messageList: PropTypes.array,
    classes: PropTypes.object,
    status: PropTypes.string
};


function mapStateToProps(state) {
    const { status, tabValue, messageList, customerServiceListIds} = state.message;
    let { sendMessageObj } = state.message;

    // if (sendMessageObj === undefined) {
        sendMessageObj = {
            ...sendMessageObj,
            to: customerServiceListIds          // current hard code value, may change to dropdown value in the future
        };
    //}

    return {
        status,
        tabValue,
        messageList,
        sendMessageObj
    };
}

const temp = connect(mapStateToProps)(withStyles(styles)(NewMessageDialog));

export { temp as NewMessageDialog };
