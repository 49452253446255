import { noteService } from '../_services';
import { errorHandling, formatDateTime } from '../_helpers';
import { noteConstant } from '../_constants';
import { loadingAction, messageAction } from '../_actions';
import { getUser } from '../_helpers';

export const noteAction = {
    getByPerson,
    edit,
    cancelEdit,
    change,
    save,
    sendNote,
    openRevision,
    closeRevision,
    acknowledgeReply,
    goToNote
};

function getByPerson(personId) {
    return dispatch => {
        noteService.getByPerson(personId)
            .then(
                res => {
                    const notes = res.data;

                    dispatch({
                        type: noteConstant.GET_BY_PERSON,
                        personId,
                        notes
                    });
                },
                error => {
                    errorHandling('note.action.getByPerson', error, dispatch);
                }
            )
            .finally(
            );
    };
}

function edit(editNoteId) {
    return {
        type: noteConstant.EDIT,
        editNoteId
    };
}

function cancelEdit() {
    return {
        type: noteConstant.CANCEL_EDIT
    };
}

function change(text) {
    return {
        type: noteConstant.CHANGE,
        text
    };
}

function save(noteId, personId, from, text) {
    return dispatch => {
        text = markText(text, 'Signed', from);

        noteService.save(noteId, personId, from._id, text)
            .then(
                res => {
                    const notes = res.data;

                    dispatch({
                        type: noteConstant.SAVED,
                        notes
                    });

                    dispatch(cancelEdit());
                },
                error => {
                    errorHandling('note.action.save', error, dispatch);
                }
            )
            .finally(
            );
    };
}

function sendNote(noteId, fromObj, to, subject, lastVersion) {
    return dispatch => {
        dispatch(loadingOpen());
        lastVersion.text = markText(lastVersion.text, 'Sent', fromObj);

        noteService.sendNote(noteId, fromObj._id, to, subject, lastVersion)
            .then(
                res => {
                    const notes = res.data;

                    dispatch({
                        type: noteConstant.SEND_NOTE_SUCCESS,
                        notes
                    });
                },
                error => {
                    errorHandling('note.action.sendNote', error, dispatch);
                }
            )
            .finally(
                () => { dispatch(loadingClose()); }
            );
    };
}

function openRevision(note) {
    return {
        type: noteConstant.OPEN_REVISION,
        note
    };
}

function closeRevision() {
    return {
        type: noteConstant.CLOSE_REVISION
    };
}

function markText(text, action, from) {
    text = text.replace('\n[Draft]', '');
    text = `${text}\n[${action} by ${from.FirstName} ${from.LastName} on ${formatDateTime(Date.now())}]`;

    return text;
}

function acknowledgeReply(note) {
    return dispatch => {
        const reply = note.message.replies[0];
        const replyText = markText(reply.message, 'Acknowledged', getUser().Person);

        noteService.acknowledgeReply(note.message._id, note.person._id, reply._id, replyText)
            .then(
                res => {                
                    const notes = res.data;

                    dispatch({
                        type: noteConstant.ACKNOWLEDGE_REPLY_SUCCESS,
                        notes
                    });
                    dispatch(messageAction.countUnread());
                },
                error => {
                    errorHandling('note.action.acknowledgeReply', error, dispatch);
                }
            );
    };
}

function goToNote(selectedNoteId) {
    return {
        type: noteConstant.GO_TO_NOTE,
        selectedNoteId
    };
}


function loadingOpen() { return loadingAction.open(); }
function loadingClose() { return loadingAction.close(); }