import { paymentConstant } from '../_constants';
import { paymentService } from '../_services';
import { loadingAction } from '../_actions';
import { history, errorHandling } from '../_helpers';

export const paymentAction = {
    checkout,
    getCardByCharge
};

function checkout(prescription) {
    return dispatch => {
        // checkout first
        dispatch({
            type: paymentConstant.CHECKOUT,
            prescription
        });

        // then go to payment page
        history.push('/request-prescription/payment');
    };
}


function getCardByCharge(chargeId) {
    return dispatch => {
        dispatch(loadingOpen());

        paymentService.getCardByCharge(chargeId)
            .then(
                res => {
                    dispatch({
                        type: paymentConstant.GET_CHARGE_SUCCESS,
                        card: res.data
                    });
                },
                error => {
                    errorHandling('payment.action.getCardByCharge', error, dispatch);
                }
            )
            .finally(
                () => { dispatch(loadingClose()); }
            );
    };
}


function loadingOpen() { return loadingAction.open(); }
function loadingClose() { return loadingAction.close(); }
