import { userService, appService } from '../_services';
import { userConstant } from '../_constants';
import { history } from '../_helpers';
import { loadingAction, appAction } from './';
import { errorHandling } from '../_helpers';
import { locStore } from '../_helpers/locStore';
import config from '../config';

export const userAction = {
    login,
    logout,
    refreshPage,
    updateEmail,
};

function login(username, password, role, code) {
    return async dispatch => {
        dispatch(request({ username }));
        dispatch(loadingOpen());

        let ipObj = null;
        // console.log(config.isIp)
        if (config.isIp) {
            try {
                ipObj = await appService.getIpObj();
            } catch (error) {
                ipObj = {};
            }
        }
            
        let storedCode = null;
        if (role != "patient" && !code) {
            storedCode = locStore.getItem("code");
        }
        await userService.login(username, password, role, ipObj, code, storedCode)
            .then(
                res => {
                    if (res.data.verifymfa) {
                        dispatch(verifymfa());
                    } else {
                        const token = res.data.token;
                        const user = res.data.user;
                        const lastLogin = res.data.lastLogin;

                        // saving the user information on local storage
                        sessionStorage.setItem('token', token);
                        if (code)
                            locStore.setItem("code", code);
                        //sessionStorage.setItem('user', JSON.stringify(user));

                        dispatch(success(user));
                        dispatch(appAction.init());
                        dispatch(appAction.setLastLogin(lastLogin,user));
                        history.push('/dashboard');             // redirect to dashboard
                    }
                },
                error => {
                    errorHandling('user.action.login', error, dispatch);
                    dispatch(failure());
                    throw(error)
                }
            )
            .finally(
                () => { dispatch(loadingClose()); }
            );
    };

    function request(user) { return { type: userConstant.LOGIN_REQUEST, user }; }
    function success(user) { return { type: userConstant.LOGIN_SUCCESS, user }; }
    function failure() { return { type: userConstant.LOGIN_FAILURE }; }
    function verifymfa() { return { type: userConstant.LOGIN_VERIFY }; }
}

function logout(user) {
    return async dispatch => {
        const res = await userService.logout(user);
        dispatch(setIsLogout())
    }
}

function setIsLogout() {
    sessionStorage.clear();
    return { type: userConstant.LOGOUT };
}

function loadingOpen() { return loadingAction.open(); }
function loadingClose() { return loadingAction.close(); }

function refreshPage() {
    return async dispatch => {
        try {
            dispatch(setIsLogging(true));
            const Email = await userService.getUserEmailByToken();
            const user = await userService.getUserByEmail(Email);
            dispatch(setIsLogging(false));
            dispatch(refresh(user));
        } catch (error) {
            dispatch(setIsLogging(false));
            dispatch(setIsTried())
            throw (error)
        }
        
    }

    function refresh(user) { return { type: userConstant.REFRESH, user }; }

}

function setIsLogging(isLogging) {
    return { 
        type: userConstant.IS_LOGGING,
        isLogging
    }
}

function setIsTried() {
    return { 
        type: userConstant.IS_TRIED,
    }
}

function updateEmail(email) {
    return {
        type: userConstant.UPDATE_EMAIL,
        email
    }
}
