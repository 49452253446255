import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Medications, Medication } from '.';
import { alertAction, drugSearchAction } from '../_actions';
import { drugService } from '../_services/drug.service';

class DrugSearchDumb extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchKey: (props.item||{}).name,
            drugs: [],
            manyDrugs: [],
            selectedDrug: props.item
        };
    }

    componentDidMount() {
        // const { dispatch, preload } = this.props;

        // if (preload) {
        //     dispatch(drugSearchAction.preload(preload));
        // } else {
        //     dispatch(drugSearchAction.init());
        // }
    }

    handleSearch = async (event) => {
        const searchKey = event.target.value;
        console.log(searchKey);
        this.setState({
            searchKey,
            manyDrugs: []
        });
        if (searchKey.length > 2) {
            const { data : drugs = [] } = await drugService.getByNameOrIngredient(searchKey);

            if (drugs.length > 0)
                this.setState({
                    drugs
                });
            else 
                this.setState({
                    drugs: []
                });
        } else {
            this.setState({
                drugs: []
            });
        }
        
        
        // const { dispatch } = this.props;
        // const searchKey = event.target.value;

        // dispatch(drugSearchAction.change(searchKey));
        // dispatch(drugSearchAction.clearSearch());

        // if (searchKey.length > 3) {
        //     dispatch(drugSearchAction.search(searchKey));
        // }
    }

    isDrugExisted = (drugName) => {
        const { drugs } = this.props;
        const existed = drugs.find(o => o.name === drugName) !== undefined;

        return existed;
    }

    handleSelect = async (drug) => {
        /*const { dispatch } = this.props;

        if (!this.isDrugExisted(drug.name)) {
            dispatch(drugSearchAction.selectDrug(drug));
        } else {
            dispatch(alertAction.warning('This drug is already added.'));
        }*/
        console.log(drug);
        

        const { data: manyDrugs = []} = await drugService.getByName(drug.name);
        // if (manyDrugs.length > 0)
        //     this.setState({
        //         manyDrugs
        //     });
        const updatedState = {
            searchKey: drug.name,
            manyDrugs,
            drugs: []
        };

        if (manyDrugs.length == 1) {
            this.props.onSelect(manyDrugs[0]);
            updatedState.selectedDrug = manyDrugs[0];
        } 

        this.setState(updatedState);
    }

    handleAdd = (usage) => {
        const { dispatch, drug, drugs, onMedicationsChanged } = this.props;

        if (!this.isDrugExisted(drug.name)) {
            drug.usages = [usage];
            const newDrugs = [...drugs, drug];

            dispatch(drugSearchAction.addDrug(newDrugs));
            onMedicationsChanged(newDrugs);
        } else {
            dispatch(alertAction.warning('This drug is already added.'));
        }
    }

    handleCancel = () => {
        const { dispatch } = this.props;
        dispatch(drugSearchAction.clearSearch());
    }

    handleEditUsage = (drugName, usage) => {
        let { dispatch, drugs, onMedicationsChanged } = this.props;
        drugs = JSON.parse(JSON.stringify(drugs));  // deep copy so it won't affect the medicalInfo currently taken medication

        const newDrugs = [...drugs].map(o => {
            if (o.name === drugName) {
                o.usages = [...o.usages, usage];
            }

            return o;
        });

        dispatch(drugSearchAction.updateDrugUsage(newDrugs));
        onMedicationsChanged(newDrugs);
    }

    /**
     * Delete a tag item
     * @param {item} i 
     */
    handleDelete = (drug) => {
        const { dispatch, drugs, onMedicationsChanged } = this.props;
        const newDrugs = drugs.filter((o) => o.name !== drug.name);

        dispatch(drugSearchAction.removeDrug(newDrugs));
        onMedicationsChanged(newDrugs);
    }

    /**
     * Ask the medication's frequency
     */
    handleClickOnOption = (drug) => {
        // const { dispatch } = this.props;
        // dispatch(drugSearchAction.showUsage(drug));
        console.log(drug)
        this.setState({
            searchKey: drug.name,
            manyDrugs: [],
            selectedDrug: drug
        });
        this.props.onSelect(drug);
    }

    renderDrugs() {
        const { drugs, hideDuration, allowEdit } = this.props;

        if (drugs && drugs.length > 0) {
            return (
                <Medications items={drugs} handleDelete={this.handleDelete} hideDuration={hideDuration}
                    allowEdit={allowEdit} onEditUsage={(drugName, usage) => this.handleEditUsage(drugName, usage)}

                />
            );
        } else {
            return '';
        }
    }

    renderIngredients = () => {
        const { ingredients = [] } = this.state.selectedDrug;
        return (
            <div className="ingredients">
                {ingredients.map((ingredient) => {
                    return ' ' + ingredient.ingredient_name + ' ' + ingredient.strength + ' ' + ingredient.strength_unit + '; ';
                })}
            </div>
        );
    }

    renderSearchList() {
        const { isCrossOut } = this.props;
        const {drugs: searchList = [], 
            } = this.state;

        if (searchList && searchList.length > 0) {
            return (
                <div className="search-list">
                    <ul className="ul-suggestions">
                        {
                            searchList.map((item, index) => {
                                let classes = '';
                                let disabled = false;

                                // cross out medication, red and cross out
                                if (item.filter === 'CROSS_OUT' && isCrossOut) {
                                    classes = `${classes} cross-out`;
                                    disabled = true;
                                }

                                return <li key={index} className={classes} onClick={(event) => {
                                    if (disabled) {
                                        event.preventDefault();
                                    } else {
                                        this.handleSelect(item);
                                    }
                                }}>
                                    <span className="medication">{item.name}</span>
                                    <span className="">
                                        Ingredients:
                                        {
                                            item.ingredients.map((ing) => {
                                                return ' ' + ing.ingredient_name + '; ';
                                            })
                                        }
                                    </span>
                                </li>;
                            })
                        }
                    </ul>
                </div>
            );
        } else {
            return '';
        }
    }

    renderMultipleDrugs() {
        const { manyDrugs: multipleDrugs, searchKey } = this.state;
        
        if (multipleDrugs && multipleDrugs.length > 1 && searchKey) {
            return (
                <div className="multiple-drugs">
                    <div>
                        <div className="select-drug">{searchKey}</div>
                        <div>
                            Select the specific medication based on Strength, Administration and Formulation:
                        </div>
                    </div>
                    <ul>
                        {
                            multipleDrugs.map((item, index) => {
                                return <li key={index} onClick={() => this.handleClickOnOption(item)}>
                                    <span className="ingredients-medication">
                                        Ingredients:
                                        {
                                            item.ingredients.map((ing) => {
                                                return ' ' + ing.ingredient_name + ' - ' + ing.strength + ' ' + ing.strength_unit + '; ';
                                            })
                                        }
                                    </span>
                                    <span className="administration">
                                        Administration: {item.administrations[0]} | Formulation: {item.formulations[0]}
                                    </span>
                                </li>;
                            })
                        }
                    </ul>
                </div>
            );
        } else {
            return '';
        }
    }

    renderUsage() {
        const { drug, showUsage, hideDuration } = this.props;

        if (showUsage) {
            return (
                <Medication item={drug} hideDuration={hideDuration}
                    onAddUsage={(usage) => this.handleAdd(usage)}
                    onCancelUsage={this.handleCancel}
                />
            );
        } else {
            return '';
        }
    }

    render() {
        const { searchKey, selectedDrug = {} } = this.state;
        const { drugs } = this.props;
        const placeholder = drugs.length > 0 ? 'Add another medication' : 'Add a medication';
        console.log(this.state.selectedDrug);
        return (
            <div className="drug-search-dumb">
                {/*this.renderDrugs()*/}
                {<div className="drug-search-dumb__title">Select a medication:</div>}
                <input type="text" className="form-control drug-search-dumb__input" placeholder={placeholder}
                    value={searchKey} onChange={this.handleSearch} onFocus={this.handleSearch} />
                {this.renderSearchList()}
                {searchKey == selectedDrug.name && <div className="drug-search-dumb__drug-details">
                    {`(${(selectedDrug.administrations || [])[0] || ''}/${(selectedDrug.formulations || [])[0] || ''})`}
                    {this.renderIngredients()}
                </div>}
                {this.renderMultipleDrugs()}
                {/*this.renderUsage()*/}
            </div>
        );
    }
}


DrugSearchDumb.propTypes = {
    dispatch: PropTypes.func,
    onMedicationsChanged: PropTypes.func,
    searchKey: PropTypes.string,
    searchList: PropTypes.array,
    showUsage: PropTypes.bool,
    multipleDrugs: PropTypes.array,
    drug: PropTypes.object,
    drugs: PropTypes.array,
    preload: PropTypes.array,
    hideDuration: PropTypes.bool,
    allowEdit: PropTypes.bool,
    isCrossOut: PropTypes.bool,
};


function mapStateToProps(state) {
    const { searchList, multipleDrugs, showUsage, drug } = state.drugSearch;
    let { searchKey, drugs } = state.drugSearch;

    if (searchKey === undefined) {
        searchKey = '';
    }

    if (drugs === undefined) {
        drugs = [];
    }

    return {
        searchKey,
        searchList,
        multipleDrugs,
        showUsage,
        drug,
        drugs
    };
}

const temp = connect(mapStateToProps)(DrugSearchDumb);

export { temp as DrugSearchDumb };