import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { PrivateRoute, PublicRoute } from './components/CustomRoute';
import { Confirm, Home, Login, Dashboard, Recovery, RecoveryPassword, Fees,FAQ, AboutUs } from './pages/Public';
import { Prescriptions, Prescription } from './pages/Prescription';
import { Messaging } from './pages/Messaging';
import { MedicalInfoPage, EditMedicalInfoPage } from './pages/MedicalInfo';
import { PrescriptionMedicalInfo, PrescriptionForm, PrescriptionPayment } from './pages/RequestPrescription';
import { RegistrationPage, ProfilePage } from './pages/Profile';
import { ProblemPage } from './pages/Problem';
import { OfficeInvitePage } from './pages/OfficeInvite';
import { PatientsPage, PatientPage } from './pages/Patient';
import { AuditPage } from './pages/Audit';
import { isAuth, isDoctor, isPatient, isAdmin, isCustomerService, env } from './_helpers';
import { ManageDataPage } from './pages/ManageData';
import { MedicationFilterPage } from './pages/MedicationFilter';
import { SleepInfoPage, EditSleepInfoPage, SleepClinicPage, SleepClinicBranchPage } from './pages/SleepReferral';
import { PersonalInfo } from "./pages/personal-info";


const Routes = () => (
    <Switch>
        <PublicRoute exact path='/' component={Home} />
        <PublicRoute exact path='/registration' component={RegistrationPage} />
        <PublicRoute exact path='/faq' component={FAQ} />
        <PublicRoute exact path='/about-us' component={AboutUs} />
        <PublicRoute exact path='/login' key="login" component={Login} />
        {env() === 'dev' && <PublicRoute exact path='/office-login' key="office-login" component={Login} />}
        {env() === 'dev' && <PublicRoute exact path='/office-registration' key="office-registration" component={RegistrationPage} />}
        <PublicRoute exact path='/confirm' component={Confirm} />
        <PublicRoute exact path='/recovery' component={Recovery} />
        <PublicRoute exact path='/recovery/password' component={RecoveryPassword} />
        <PublicRoute exact path='/fees' component={Fees} />

        <PrivateRoute exact path='/dashboard' component={Dashboard} />
        {isAuth() && !isCustomerService() && <PrivateRoute exact path='/prescriptions' component={Prescriptions} />}
        {isAuth() && !isCustomerService() && <PrivateRoute exact path='/prescription/:id' component={Prescription} />}
        <PrivateRoute exact path='/messaging' key={Date.now()} component={Messaging} />
        <PrivateRoute exact path='/profile' component={ProfilePage} />

        {isAuth() && isPatient() && <PrivateRoute exact path='/request-prescription/medicalinfo' component={PrescriptionMedicalInfo} />}
        {isAuth() && isPatient() && <PrivateRoute exact path='/request-prescription/form' component={PrescriptionForm} />}
        {isAuth() && isPatient() && <PrivateRoute exact path='/request-prescription/payment/' component={PrescriptionPayment} />}
        {isAuth() && isPatient() && <PrivateRoute exact path='/medicalInfo' component={MedicalInfoPage} />}
        {isAuth() && isPatient() && <PrivateRoute exact path='/medicalInfo/edit' component={EditMedicalInfoPage} />}
        {isAuth() && isPatient() && <PrivateRoute exact path='/sleep-info' component={SleepInfoPage} />}
        {isAuth() && isPatient() && <PrivateRoute exact path='/sleep-info/edit' component={EditSleepInfoPage} />}
        {isAuth() && isPatient() && <PrivateRoute exact path='/sleep-clinic' component={SleepClinicPage} />}
        {isAuth() && isPatient() && <PrivateRoute exact path='/sleep-clinic/:name' component={SleepClinicBranchPage} />}

        {isAuth() && isAdmin() && <PrivateRoute exact path='/audit' component={AuditPage} />}
        {isAuth() && isAdmin() && <PrivateRoute exact path='/problems' component={ProblemPage} />}
        {isAuth() && isAdmin() && <PrivateRoute exact path='/office-invite' component={OfficeInvitePage} />}
        {isAuth() && ( isDoctor() || isCustomerService() ) && <PrivateRoute exact path='/patients' component={PatientsPage} />}
        {isAuth() && ( isDoctor() || isCustomerService() ) && <PrivateRoute exact path='/patient/:id' component={PatientPage} />}
        {isAuth() && ( isDoctor() || isCustomerService() ) && <PrivateRoute exact path='/medicalInfo/edit/:id' component={EditMedicalInfoPage} />}
        {isAuth() && isDoctor() && <PrivateRoute exact path='/personal-info/:id/edit' component={PersonalInfo} />}
        {isAuth() && isAdmin() && <PrivateRoute exact path='/manage-data' component={ManageDataPage} />}
        {isAuth() && isAdmin() && <PrivateRoute exact path='/medication-filter' component={MedicationFilterPage} />}

        <Redirect to="/dashboard" />
    </Switch>
);

export default Routes;