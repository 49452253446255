import { env } from './_helpers/environment';

const dev = {
    stripePublicKey: 'pk_test_u9MQdjuwQOO92KqJ9LSOGdss',
    reCaptchaSiteKey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
    apiHost: 'http://localhost:3000',
    uploadBucket: 'https://s3.ca-central-1.amazonaws.com/paco-s3-askmed-uploads-dev-bucket-cac1',
    ipApi: 'https://api.ipdata.co?api-key=9112f3f045d8eda991ed2223fc31d40bf07f3c73796513e177c5f960',
    payPalClientId: 'AfasSsxI0Kz8-3SIaYst3CB5GGAt5qEbwihriay92Spl0ymhIUf42BKv26g0KHtqxIpFN7IlNSIHxJIn',
    isIp: true
};

const test = {
    stripePublicKey: 'pk_test_u9MQdjuwQOO92KqJ9LSOGdss',
    reCaptchaSiteKey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
    apiHost: 'https://api.test.askmedication.com',
    uploadBucket: 'https://s3.ca-central-1.amazonaws.com/ne-askmed-test-app-askmed-backend-uploads-bucket-cac1',
    ipApi: 'https://api.ipdata.co?api-key=cacaf886c43154f8ca5d53ade0f70b01923f9c87d5a2f51621eb8a69',
    payPalClientId: 'AfasSsxI0Kz8-3SIaYst3CB5GGAt5qEbwihriay92Spl0ymhIUf42BKv26g0KHtqxIpFN7IlNSIHxJIn',
    isIp: true
};

const feature1 = {...test, apiHost: 'https://api.feature1.askmedication.com'};

const prod = {
    stripePublicKey: 'pk_live_aA3Iz3Kp2JlgmwIiemE226XK',
    reCaptchaSiteKey: '6LdBgZAUAAAAAGgqyJifwFwiluy6-yhw61piqVXs',
    apiHost: 'https://api.askmedication.com',
    uploadBucket: 'https://s3.ca-central-1.amazonaws.com/ne-askmed-prod-app-askmed-backend-uploads-bucket-cac1',
    ipApi: 'https://api.ipdata.co?api-key=cacaf886c43154f8ca5d53ade0f70b01923f9c87d5a2f51621eb8a69',
    payPalClientId: 'AdRyR1BlpW4L2GzstknZ9fsQzKvAH7EKqizvb-pAMy0lGfgEU-ikDTdLTOhwkWCKj0NddbhXjT_KYstu',
    isIp: true
};


// const config = env() === 'dev' ? dev : (env() === 'test' ? test : prod);

const config = {
    dev,
    test,
    feature1,
    prod
}

export default config[env()];
