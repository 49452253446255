import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Routes from './Routes';
import { NavbarDashboard, PopupDialog, ApiNotAvailable, Alert, Loading } from './components';
import { connect } from 'react-redux';
import { Router } from 'react-router-dom';
import IdleTimer from 'react-idle-timer';
import { userAction } from './_actions';
import { history, env, getToken, timeChecker } from './_helpers';
import { Screenshot } from './pages/Problem';
import { Footer, LastLoginDialog } from './pages/App';

class App extends Component {
    constructor(props) {
        super(props);
        this.idleTimer = null;
        this.onIdle = this.onIdle.bind(this);
    }

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(userAction.refreshPage());
    }

    render() {
        let { loggedIn, isloggingIn, isTried, user, dispatch } = this.props;
        const token = getToken();
        /*
        if (
            getToken() && (!(user || {})._id)
            // old logic getToken() && (!loggedIn || isloggingIn). but we want to remove condition getToken() && !loggedIn && !isloggingIn
        )
            return null;*/

        // Auto Logout after 5 Minute
        document.onmousemove = () => {
            let timeStamp = new Date();
            sessionStorage.setItem("lastTimeStamp",timeStamp);
        }

        document.onkeypress = () => {
            let timeStamp = new Date();
            sessionStorage.setItem("lastTimeStamp",timeStamp);
        }

        if (loggedIn) {
            timeChecker(dispatch, user);
        }

        if (
            !token
            || (token && isTried)
        ) {

        
            const classes = loggedIn ? 'col-md-10' : '';

            // 5 minutes timeout
            return (
                <>
                    <LastLoginDialog />
                    <ApiNotAvailable />
                    <Loading />
                   {loggedIn && <Screenshot />}
                    <Alert />
                    <PopupDialog />
                    <IdleTimer
                        ref={ref => { this.idleTimer = ref; }}
                        element={document}
                        onIdle={this.onIdle}
                        timeout={1000 * 60 * 5} />
                    <Router history={history}>
                        <>
                            <NavbarDashboard className="no-print" />
                            <main role="main" className={classes + ' ' + (loggedIn ? 'logged-in' : 'logged-out') + ' no-print'}>
                                <Routes />                            
                            </main>
                            {loggedIn && <Footer />}
                        </>
                    </Router>
                </>
            );
        } else {
            return null;
        }
    }

    onIdle() {
        const { dispatch, loggedIn } = this.props;

        // logout when it is login and production environment
        if (env() !== 'dev' && loggedIn) {
            dispatch(userAction.logout());
        }
    }
}


App.propTypes = {
    dispatch: PropTypes.func.isRequired,
    loggedIn: PropTypes.bool
};


function mapStateToProps(state) {
    const { loggedIn, isloggingIn, isTried, user } = state.authentication;

    return {
        loggedIn,
        isloggingIn,
        isTried,
        user
    };
}

export default connect(mapStateToProps)(App);