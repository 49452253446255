import React from 'react';

import { formatDate, getPersonName, getS3ImageUrl } from '../../../_helpers';
import { SleepInfo } from '../../SleepReferral';


const Content = props => {
    const { sleepAssessmentObj, medicalInfoObj, options = {} } = props;

    if (sleepAssessmentObj && medicalInfoObj) {
        const person = sleepAssessmentObj.person;
        const sleepInfoObj = sleepAssessmentObj.sleepInfo;
        const sleepClinicObj = sleepAssessmentObj.sleepClinic;
        const signatureUrl = getS3ImageUrl('signature/balint.PNG');

        return (
            <div id="sleep-assessment-form">
                <div className="header">
                    <div>Sleep/Respiratory Assessment Form</div>
                        { options.isPreliminary 
                            && <span className={'header__comment'}> (Preliminary, to be reviewed by a health care provider) </span>
                        }
                    <div>Company: {sleepClinicObj.name}</div>
                    <div>Location requested: {sleepClinicObj.address}</div>
                </div>
                <div className="personal-info">
                    <div className="col">
                        <div>
                            <span className="field-name">Last Name:</span>
                            <span>{person.LastName}</span>
                        </div>
                        <div>
                            <span className="field-name">First Name:</span>
                            <span>{person.FirstName}</span>
                        </div>
                        <div>
                            <span className="field-name">Address:</span>
                            <span>{person.Address}</span>
                        </div>
                        <div>
                            <span className="field-name">City:</span>
                            <span>{person.City}</span>
                        </div>
                        <div>
                            <span className="field-name">Province:</span>
                            <span>{person.Province}</span>
                        </div>
                    </div>
                    <div className="col">
                        <div>
                            <span className="field-name">Postal Code:</span>
                            <span>{person.PostalCode}</span>
                        </div>
                        <div>
                            <span className="field-name">Phone:</span>
                            <span>{person.PhoneNumber}</span>
                        </div>
                        <div>
                            <span className="field-name">Health Card:</span>
                            <span>{person.HealthDocuments[person.HealthDocuments.length - 1].DocumentNumber}</span>
                        </div>
                        <div>
                            <span className="field-name">Date of Birth:</span>
                            <span>{formatDate(person.DateOfBirth)}</span>
                        </div>
                        <div>
                            <span className="field-name">Gender:</span>
                            <span>{medicalInfoObj && medicalInfoObj.Sex}</span>
                        </div>
                    </div>
                </div>
                <div className="sleep-info">
                    <div className="title">
                        Please see above patient for Sleep Apnea Level III testing. The patient reports the following:
                        </div>
                    <SleepInfo sleepInfoObj={sleepInfoObj} />
                </div>
                <div className="footer">
                    <div className="title">
                        <span>Thank you very much. Best regards</span>
                        <span>{formatDate(new Date())}</span>
                    </div>
                    <div>
                        {!options.isAnonymous && <div>
                            <div>
                                <span className="field-name">Practitioner Name:</span>
                                <span>{getPersonName()}</span>
                            </div>
                            <div>
                                <span className="field-name">Signature:</span>
                                {/* <span><img className="signature" src={signatureUrl} crossOrigin="anonymous" /></span> */}
                            </div>
                        </div>}
                        <div>
                            <div>
                                <span className="field-name">Clinic Name:</span>
                                <span>AskMedication.com Medical Clinic</span>
                            </div>
                            <div>
                                <span className="field-name">Fax number:</span>
                                <span>(604) 398-5999</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return '';
    }
}

export default Content;