import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CardElement, injectStripe } from 'react-stripe-elements';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { prescriptionAction, alertAction, profileAction, sleepAssessmentAction } from '../../_actions';
import { history, getPersonName, getFaxNumber } from '../../_helpers';
import config from '../../config';
import PaypalButton from "../../components/paypalButton";
import getCompUrl from '../../_helpers/getCompUrl';
import { default as SleepContent } from '../Patient/SleepAssessmentDialogFirst/content';
class CheckoutFormSleepment extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
       // this.handlePay = this.handlePay.bind(this);
    }

    componentDidMount() {
        const { dispatch, user } = this.props;

        if (!user) {
            dispatch(profileAction.get());
        }
    }


    // async handlePay() {
    //     const { dispatch, prescription } = this.props;
    //     const { token } = await this.props.stripe.createToken({ name: getPersonName() });

    //     if (token) {
    //         dispatch(prescriptionAction.charge(token.id, prescription));
    //     } else {
    //         dispatch(alertAction.error('Please enter valid card information!'));
    //     }
    // }

    renderPayPalSelf() {
        const { user } = this.props;
        if (typeof user == 'undefined') 
            return <div/>

        return <div className="mb-5">
            <p>Please pay with one of the options below</p>
    
            <PaypalButton
                order={{
                    payer: {
                        name: {
                            given_name: user.FirstName, // Paypal
                            surname: user.LastName // Customer
                        },
                        address: {
                            address_line_1: user.Address, // '123 ABC Street',
                            // address_line_2: 'Apt 2',
                            admin_area_2: user.City, // 'Vancouver',
                            admin_area_1: user.Province, // 'BC',
                            postal_code: user.PostalCode, // 'V5H 3Z7',
                            country_code: 'CA'
                        },
                        email_address: user.Email, // 'a@a.com',
                        phone: {
                            phone_number: {
                                national_number: getFaxNumber(user.PhoneNumber) // '7789691234'
                            }
                        }
                    },
                    purchase_units:[{
                        amount: {
                            value: '20.00',
                            currency_code: 'CAD'
                        },
                        /*
                        shipping: {
                            address: {
                                address_line_1: '123 ABC Street',
                                address_line_2: 'Apt 2',
                                admin_area_2: 'Vancouver',
                                admin_area_1: 'BC',
                                postal_code: 'V5H 3Z7',
                                country_code: 'CA'
                            }
                        }*/
                    }]
                }}
                onApprove={async (data, details) => {
                    const { dispatch, sleepInfoObj, person, medicalInfoObj, clinicObj, onClose} = this.props;
                    const sleepAssessmentObj = {
                        sleepInfo: sleepInfoObj,
                        sleepClinic:clinicObj,
                        person
                    }
                    const url = await getCompUrl(<SleepContent
                        sleepAssessmentObj={sleepAssessmentObj}
                        medicalInfoObj={medicalInfoObj}
                        options={{
                            isPreliminary:true,
                            isAnonymous:true
                        }}
                    />);
                     dispatch(sleepAssessmentAction.create(sleepInfoObj, clinicObj, data.orderID, url));
                     onClose();
                     
                }}
                onError={(err) => {
                    this.props.dispatch(alertAction.error('Please enter valid card information!'));
                }}
            />
        </div>
    }

    render() {
        return (
            <div className="checkout">
                {this.renderPayPalSelf()}
            </div>
        );
    }
}


CheckoutFormSleepment.propTypes = {
    dispatch: PropTypes.func.isRequired,
    prescription: PropTypes.object,
    stripe: PropTypes.object
};


function mapStateToProps(state) {
    const { prescription } = state.payment;
    const { user } = state.profile;
    const { sleepInfoObj } = state.sleepInfo;
    const { Person: person } = state.authentication.user;
    const { medicalInfoObj } = state.medicalInfo;
    return {
        prescription,
        user,
        sleepInfoObj,
        person,
        medicalInfoObj,
    };
}

const temp = compose(
    connect(mapStateToProps),
    // injectStripe,
    withRouter
)(CheckoutFormSleepment);

export { temp as CheckoutFormSleepment };