import { userConstant, problemConstant } from '../_constants';

export function problem(state = {}, action) {
    switch (action.type) {
        case problemConstant.GET_ALL:
        case problemConstant.SUBMIT_SUCCESS:
        case problemConstant.ACKNOWLEDGE_SUCCESS:
            return {
                ...state,
                problems: [...action.problems],
                unreadCount: action.problems.filter(o => o.isRead === false).length
            };
        case problemConstant.CAPTURE_REQUEST:
            return {
                ...state,
                capturing: true
            };
        case problemConstant.OPEN_DIALOG:
            return {
                ...state,
                dataURL: action.dataURL,
                message: '',
                isOpen: true
            };
        case problemConstant.CHANGE:
            return {
                ...state,
                message: action.message
            };
        case problemConstant.CLOSE_DIALOG:
            return {
                ...state,                
                capturing: false,            
                isOpen: false
            };
        case problemConstant.COUNT_UNREAD:
            return {
                ...state,
                unreadCount: action.unreadCount
            };
        case userConstant.LOGOUT:
            return {};
        default:
            return state;
    }
}