import axios from 'axios';
import config from '../config';
import { getToken } from '../_helpers';


export const patientService = {
    getPatients,
    getPatient
};

function getPatients() {
    const url = `${config.apiHost}/user/patients`;
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.get(url);
}

function getPatient(personId) {
    const url = `${config.apiHost}/person/${personId}`;
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.get(url);
}