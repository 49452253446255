import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import { BaseTable } from '../../components';

class SleepClinicCompany extends BaseTable {
    constructor(props) {
        super(props);
        this.state = {
            order:'asc',
            orderBy: 'name',
            page: 0,
            rowsPerPage: 10,
            open: false
        };
    }

 
    render() {
        const { rowsPerPage, page, order, orderBy } = this.state;
        const companyObjs = [{name:"Clinical Sleep Solutions", to: "/sleep-clinic/solutions"}, 
                             {name:"Coastal Sleep", to: "/sleep-clinic/coastal"}, 
                             {name:"Mainland Sleep", to: "/sleep-clinic/mainland"}, 
                             {name:"SleepWorks", to:"/sleep-clinic/sleepworks"}, 
                             {name:"Test", to: "/sleep-clinic/test"},
                             ];
            return (
                <Paper>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell> Clinic Company Name</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.stableSort(companyObjs, this.getSorting(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map(obj => {
                                    return (
                                        <TableRow className="row">
                                            <TableCell>
                                                {obj.name}
                                            </TableCell>
                                            <TableCell>
                                                <Link className="btn btn-sm btn-outline-primary my-2 ml-4" to={obj.to}><span>Select</span></Link>
                                            </TableCell>
                                        </TableRow>
                                    )
                                  }
                                )}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[10, 15, 20]}
                        component="div"
                        count={companyObjs.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        backIconButtonProps={{
                            'aria-label': 'Previous Page',
                        }}
                        nextIconButtonProps={{
                            'aria-label': 'Next Page',
                        }}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />
                </Paper>
            );
        } 
    }


SleepClinicCompany.propTypes = {
    dispatch: PropTypes.func,
};


export { SleepClinicCompany };

