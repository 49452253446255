import React from 'react';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import { connect } from 'react-redux';
import { problemAction } from '../../_actions';
import { formatDate, uploadBucket } from '../../_helpers';
import { BaseTable, CustomImage } from '../../components';


class ProblemPage extends BaseTable {
    constructor(props) {
        super(props);
        this.state = {
            order: 'desc',
            orderBy: 'createdAt',
            page: 0,
            rowsPerPage: 10,
            open: false
        };
    }

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(problemAction.getAll());
    }

    acknowledge = (obj) => {
        const { dispatch } = this.props;
        dispatch(problemAction.acknowledge(obj._id));
    }

    renderScreenshot(problem) {
        const imageKey = `${uploadBucket.problem}/${problem._id}_problem.png`;

        return (
            <CustomImage imageKey={imageKey} />
        );
    }

    renderTable() {
        const { problems } = this.props;
        const { order, orderBy, rowsPerPage, page } = this.state;

        if (problems) {
            return (
                <Paper>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Problem</TableCell>
                                <TableCell align="center">Screenshot</TableCell>
                                <TableCell align="center">Report By</TableCell>
                                <TableCell align="center">Created</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.stableSort(problems, this.getSorting(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map(obj => {
                                    return (
                                        <TableRow key={obj._id} className="row">
                                            <TableCell className="message">
                                                <span>{obj.message}</span>
                                            </TableCell>
                                            <TableCell className="screenshot">
                                                {this.renderScreenshot(obj)}
                                            </TableCell>
                                            <TableCell className="person">
                                                {/* <span>{`${(obj.person||{}).FirstName} ${(obj.person ||{}).LastName}`}</span> */}
                                                <span>{ obj.person && (`${obj.person.FirstName} ${obj.person.LastName}`)}</span>
                                            </TableCell>
                                            <TableCell className="created-at">
                                                {formatDate(obj.createdAt)}
                                            </TableCell>
                                            <TableCell>
                                                {!obj.isRead && <button className="btn btn-warning btn-sm" onClick={() => this.acknowledge(obj)}>Acknowledge</button>}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 15, 20]}
                        component="div"
                        count={problems.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        backIconButtonProps={{
                            'aria-label': 'Previous Page',
                        }}
                        nextIconButtonProps={{
                            'aria-label': 'Next Page',
                        }}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />
                </Paper>
            );
        } else {
            return '';
        }
    }

    render() {
        return (
            <div className="problem-page">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h1 className="h2">Problems</h1>
                </div>
                <div>
                    {this.renderTable()}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { problems } = state.problem;

    return {
        problems
    };
}

const temp = connect(mapStateToProps)(ProblemPage);

export { temp as ProblemPage };

