import { userAction } from '../_actions';
import { env } from '../_helpers';


let timeCheckerInterval;

export const timeChecker = (dispatch, user) => {
    timeCheckerInterval = setInterval(function()
    {
        var storedTimeStamp = sessionStorage.getItem("lastTimeStamp");
        timeCompare(storedTimeStamp, dispatch, user);
    },10000);
}

export const timeCompare = (timeString, dispatch, user) => {
    var currentTime = new Date();
    var pastTime = new Date(timeString);
    var timeDiff = currentTime - pastTime;
    var minPast = Math.floor( (timeDiff/60000) );

    if ( minPast > 5 && env() !== 'dev') //Greater than 5 minute
    {
        sessionStorage.removeItem("lastTimeStamp");
        dispatch(userAction.logout(user));
        clearInterval(timeCheckerInterval);
    }
}