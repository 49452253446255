import axios from 'axios';
import config from '../config';
import { getToken } from '../_helpers';


export const noteDraftService = {
    getByPerson,
    update,
    sign
};

function getByPerson(personId) {
    const url = `${config.apiHost}/notedraft/person/${personId}`;
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.get(url);
}

async function update(id, text) {
    const url = `${config.apiHost}/notedraft`;
    const body = {
        id,
        text
    };
    axios.defaults.headers.common['x-access-token'] = getToken();

    return await axios.put(url, body);
}


function sign(id, personId, from, text) {
    const url = `${config.apiHost}/notedraft/sign`;
    const body = {
        id,
        personId,
        from,
        text
    };
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.put(url, body);
}