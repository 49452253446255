import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { registration } from './registration.reducer';
import { profile } from './profile.reducer';
import { alert } from './alert.reducer';
import { message } from './message.reducer';
import { medicalInfo } from './medicalInfo.reducer';
import { prescription } from './prescription.reducer';
import { pharmacy } from './pharmacy.reducer';
import { loading } from './loading.reducer';
import { problem } from './problem.reducer';
import { note } from './note.reducer';
import { noteDraft } from './noteDraft.reducer';
import { payment } from './payment.reducer';
import { patient } from './patient.reducer';
import { app } from './app.reducer';
import { audit } from './audit.reducer';
import { manageData } from './manage-data.reducer';
import { medicationFilter } from './medication-filter.reducer';
import { popup } from './popup.reducer';
import { drugSearch } from './drug-search.reducer';
import { sleepInfo } from './sleep-info.reducer';
import { sleepClinic } from './sleep-clinic.reducer';
import { sleepAssessment } from './sleep-assessment.reducer';
import { office } from './office.reducer';


const rootReducer = combineReducers({
    authentication,
    registration,
    profile,
    medicalInfo,
    prescription,
    pharmacy,
    message,
    alert,
    loading,
    problem,
    note,
    noteDraft,
    payment,
    patient,
    app,
    audit,
    manageData,
    medicationFilter,
    popup,
    drugSearch,
    sleepInfo,
    sleepClinic,
    sleepAssessment,
    office
});

export default rootReducer;