import { manageDataConstant, userConstant } from '../_constants';

export function manageData(state = {}, action) {
    switch (action.type) {
        case manageDataConstant.DISPLAY_DATA_SUCCESS:
            return {
                json: { ...action.json }
            };
        case userConstant.LOGOUT:
            return {};
        default:
            return state;
    }
}