import { alertConstant } from '../_constants';

export const alertAction = {
    success,
    info,
    warning,
    error,
    clear,
    prescriptionSuccess
};

function timeout(action) {
    return dispatch => {
        dispatch(action);

        // it will clear the alert after 10 seconds
        setTimeout(() => {
            dispatch(clear());
        }, 15000);
    };
}

function success(title, messages) {
    return timeout({ 
        type: alertConstant.SUCCESS,
        title, 
        messages 
    });
}

function prescriptionSuccess(title, messages) {
    return {
        type: alertConstant.SUCCESS,
        title, 
        messages 
    }
}



function info(title, messages) {
    return timeout({ 
        type: alertConstant.INFO,
        title, 
        messages 
    });
}

function warning(title, messages) {
    return timeout({ 
        type: alertConstant.WARNING,
        title, 
        messages 
    });
}

function error(title, messages) {
    return timeout({ 
        type: alertConstant.ERROR,
        title, 
        messages 
    });
}

function clear() {
    return { type: alertConstant.CLEAR };
}