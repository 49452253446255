import axios from 'axios';
import { getToken } from '../_helpers';
import config from '../config';

export const callService = {
    createPauseCallBack,
    getLastPauseCall,
    updateTwilioCallRanges,
};

function createPauseCallBack(personId, isPaused) {
    const url = `${config.apiHost}/twilioCall/pause-call`;
    axios.defaults.headers.common['x-access-token'] = getToken();
    const body = {
        personId, isPaused
    };

    return axios.post(url, body);
}

async function getLastPauseCall(personId) {
    const url = `${config.apiHost}/twilioCall/last-pause/${personId}`;
    axios.defaults.headers.common['x-access-token'] = getToken();
    const call = (await axios.get(url)).data.call;
    
    if (call == null) return {}
    return call;
}

async function updateTwilioCallRanges(ranges, prescriptionId){
    const url = `${config.apiHost}/twilioCall/update/${prescriptionId}`;
    axios.defaults.headers.common['x-access-token'] = getToken();
    const body = {
        ranges
    }

    return axios.post(url,body);

}
