import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { DialogActions } from '@material-ui/core';
import Countdown from "react-countdown";
import moment from 'moment';
import { v4 } from 'uuid';

import { PharmacySelect, DrugSearch, RequiredLabel, ErrorMsgField, FormatPhoneNumber } from '../../components';
import { history, getPersonId } from '../../_helpers';
import { prescriptionAction, alertAction, drugSearchAction, profileAction, userAction } from '../../_actions';
import { PrescriptionFormValidator, ProfileValidator } from '../../_validator';
import { PrescriptionCost } from './';
import { drugService, prescriptionService, callService } from '../../_services';
import { teleplanService } from '../../_services/tele-plan.service';
import { prescriptionConstant } from '../../_constants/prescription.constant';
import { paymentConstant } from '../../_constants/payment.constant';

class PrescriptionForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenMessage: false,
            isDialogContent: false,
            isOpenSuccessMeg: false,
        };
        this.prescriptionId = null;
        this.submitForm = this.submitForm.bind(this);
        this.ModifyPhone = this.ModifyPhone.bind(this);
        this.handleUpdateMobile = this.handleUpdateMobile.bind(this);
        this.schedule = [
            {start: "9", end: "12"},
            {start: "12", end: "15"},
            {start: "15", end: "18"},
            {start: "18", end: "21"},
        ].map((item) => {
            return {...item, id: v4()}
        });

        this.scheduleWeekEnd = [
            {start: "10", end: "15"},
            {start: "15", end: "20"}
        ];
        this.ranges = [];
    }

    /**
     * Get the value from the inputs form
     */
    handleChange = (event) => {
        const { dispatch, prescriptionObj } = this.props;
        prescriptionObj[event.target.name] = event.target.value;
        dispatch(prescriptionAction.change(prescriptionObj));
    }

    /**
     * Get the drugs items from the component
     */
    handleMedicationsChanged = (medications) => {
        const { dispatch, prescriptionObj } = this.props;
        prescriptionObj.Medications = [...medications];
        dispatch(prescriptionAction.change(prescriptionObj));
    }

    handleCallDelay = async () => {
        await prescriptionService.handleTwilioCallDelay(this.prescriptionId);
        this.handleMessageClose();
    }

    loadMedication = async (event) => {
        const { dispatch, prescriptionObj, medicalInfoObj } = this.props;
        let newMedications;

        if (event.target.checked) {
            // remove the same medications in currentlyTaken, so the add won't be duplicated same drug
            const temp = medicalInfoObj.MedicationsCurrentlyTaken.filter(o => {
                if (prescriptionObj.Medications.find(m => m.name === o.name)) {
                    return false;
                } else {
                    return true;
                }
            });
            newMedications = [...prescriptionObj.Medications, ...temp];
        } else {
            // remove currentlyTaken from medications
            newMedications = prescriptionObj.Medications.filter(o => {
                if (medicalInfoObj.MedicationsCurrentlyTaken.find(m => m.name === o.name)) {
                    return false;
                } else {
                    return true;
                }
            });
        }

        const names = newMedications.map(({name = ''}) => name);
        if (names.length > 0) {
            const withFilters = await drugService.getByNames(names);
            newMedications = newMedications.filter((med) => {
                const withFilter = withFilters.find((filter) => filter.name == med.name);
                return withFilter.filter[0].status != 'CROSS_OUT';
            })
        }

        dispatch(drugSearchAction.preload(newMedications));

        this.handleMedicationsChanged(newMedications);
    }

    validation() {
        const { prescriptionObj } = this.props;

        if (prescriptionObj.pharmacy === undefined) return false;
        if (prescriptionObj.pharmacy === null) return false;
        if (prescriptionObj.Medications === undefined) return false;
        if (prescriptionObj.Medications.length === 0) return false;
        if (prescriptionObj.Person === undefined) return false;

        const nonDurationDrug = prescriptionObj.Medications.filter(o => {
            const usage = [...o.usages].pop();

            if (usage.duration === '') {
                return true;
            } else {
                return false;
            }
        });

        if (nonDurationDrug.length > 0) return false;

        return true;
    }

    async isEligible() {
        const { user } = this.props;
        const person = user.Person;
        if ( (person.HealthDocuments[0] || { }).Type == 'BC Card') {
            const  hic = person.HealthDocuments[0].DocumentNumber;
            const { DateOfBirth } = person;
            const { _id } = person;
            const eligibility = await teleplanService.eligibility(hic, DateOfBirth, _id);
            return eligibility.status == "valid" || eligibility.status == "pending";
        }

        return false;
    }

    /**
     * Submit the form sending a post request to the API
     */
    submitForm = async (event) => {
        event.preventDefault();

        const { dispatch, prescriptionObj } = this.props;

        const validator = new PrescriptionFormValidator();
        validator.form(prescriptionObj);            // this.state is usage object

        if (validator.validate()) {
            let prescription;
            const isEligible = await this.isEligible();
            if (isEligible){
                prescription = await this.props.createAndPending(prescriptionObj);
            } else {
                await dispatch(prescriptionAction.create(prescriptionObj));
            }
            
            if (prescription) {

                this.setState({
                    isOpenMessage: true
                });
                this.prescriptionId = prescription._id;
            }
        } else {
            dispatch(alertAction.warning('Validation', validator.errorMsgs));
        }
    }

    handleMessageClose = () => {
        this.setState({ isOpenMessage: false, isOpenSuccessMeg: false });
        history.push(`/prescription/${this.prescriptionId}`);
    }

    saveRanges = async () => {
        const { dispatch } = this.props;
        await dispatch(prescriptionAction.updateTwilioCallRanges(this.ranges, this.prescriptionId));
        this.setState({ isOpenMessage: false, isOpenSuccessMeg: false });
        history.push(`/prescription/${this.prescriptionId}`);
    }


    callToPatientFromTwilio = () => {
        const { dispatch, user, prescriptionObj } = this.props;
        const userBestNumber = user && user.BestPhoneNumber ? user.BestPhoneNumber : user.Person.BestPhoneNumber;
        dispatch(prescriptionAction.TwilioCallToPatients(prescriptionObj._id, user._id,  userBestNumber));
        this.setState({ isOpenMessage: false, isOpenSuccessMeg: true });
        this.confirmedPrescriptionMsg();
    }

    confirmedPrescriptionMsg = () => {
        const { isOpenSuccessMeg } = this.state;
        const timeRange = new Array(12).fill(null);
        const dayRange = new Array(3).fill(null);
        return (
            <>
            <Dialog 
                fullWidth={true}
                maxWidth="md"
                open={isOpenSuccessMeg && (this.props.nextCallTime || typeof(this.props.isCallAvailable) != "undefined")}
            >
                {!this.props.isCallAvailable/*true*/ && <DialogTitle style={{textAlign: "center"}}>
                    Please select one or more time options below for the provider to call you back
                </DialogTitle>}
                <DialogContent >
                    <div style={{fontSize:"20px"}}>
                        <div>
                            {this.props.isCallAvailable /*false*/
                                ? <Countdown 
                                    date={this.props.nextCallTime}
                                    renderer={({minutes, seconds, completed}) => {
                                        let text = "";
                                        if (completed) {
                                            text = "right now";
                                        } else {
                                            let timer = `${seconds} seconds`;
                                            if (minutes) timer = `${minutes} minute ${timer}`
                                            
                                            text = "in " + timer;
                                        }

                                        return (
                                            
                                            <div>
                                            <p>{`AskMedication.com will call you ${text}. If you do not receive a phone call, please let us know by writing us at info@askmedication.com.`}</p>
                                            <div className="float-right">
                                                    {(completed || (seconds == 0))
                                                        ? <button 
                                                            type="button" 
                                                            className="btn btn-outline-primary btn-sm mt-2 mr-2" 
                                                            onClick={this.handleMessageClose}
                                                        >OK</button>
                                                        : <button 
                                                            type="button" 
                                                            className="btn btn-outline-danger btn-sm mt-2 mr-2" 
                                                            onClick={this.handleCallDelay}
                                                        >Do not call me now</button>
                                                    }
                                            </div>
                                            </div>

                                        )
                                     
                                    }}
                                ></Countdown>
                                : <div className="col-md-12">
                                    <table style={{width:"100%"}}>
                                        <tr>
                                            <th style={{
                                                    textAlign: "center",
                                                    backgroundColor: "lightGrey",
                                                    borderColor: "white",
                                                    borderWidth: "2px",
                                                    borderStyle: "solid",
                                                    color: "white"
                                                }}>
                                            </th>
                                            {dayRange.map((value, index) => {
                                                const day = index + 0; 
                                                return <th style={{
                                                    textAlign: "center",
                                                    backgroundColor: "lightGrey",
                                                    borderColor: "white",
                                                    borderWidth: "2px",
                                                    borderStyle: "solid",
                                                    color: "white"
                                                }}>
                                                    <div style={{fontSize: "20px"}}>
                                                        {moment().add(day, 'day').format('dddd')}
                                                    </div>
                                                    <div style={{fontSize: "16px"}}>
                                                        {moment().add(day, 'day').format('YYYY-MMM-DD')}
                                                    </div>
                                                    
                                                </th>
                                            })}
                                            
                                            

                                        </tr>
                                        {timeRange.map((val, indexTime) => {
                                            const hour = indexTime + 9;

                                            const items = dayRange.map((value, index) => {
                                                const day = index + 0;
                                                // check day is week day or week end
                                                let schedule = this.schedule;
                                                
                                                const isWeekEnd = ([0, 6]).includes(moment().add(day, "day").day());
                                                if (isWeekEnd)
                                                    schedule = this.scheduleWeekEnd;

                                                if ( 
                                                    hour < Number(schedule[0].start)
                                                    || hour >= Number(schedule[schedule.length - 1].end)
                                                )
                                                    return {
                                                        start: hour,
                                                        end: hour + 1
                                                    };
                                                

                                                return schedule.find((range) => {
                                                    return range.start == hour;
                                                });
                                            })

                                            return <tr>
                                                <td style={{
                                                    textAlign: "center",
                                                    backgroundColor: indexTime%2 == 1 ? "lightGrey": "white"
                                                }}>
                                                    {`${hour}:00`}
                                                </td>
                                                {items.map((item, index) => {
                                                    const day = index + 0;
                                                    if (!item) return;
                                                    if (item.start == item.end - 1)
                                                        return <td
                                                        style={{
                                                            // textAlign: "center",
                                                            backgroundColor: indexTime%2 == 1 ? "lightGrey" : "white"
                                                        }}
                                                        ></td>
                                                    const uid = v4();

                                                    
                                                    const isPassed = (moment().add(day, 'day').set({hour: item.end, minute: 0, second: 0, millisecond: 0}).isBefore(moment()));

                                                    const style = {
                                                        borderColor: "white",
                                                        borderWidth: "2px",
                                                        borderStyle: "solid",
                                                        backgroundColor: "grey",
                                                        margin: "1px" ,
                                                        color: isPassed ? "red" : "white",
                                                        textAlign: "center"
                                                    };
                                                    if (isPassed) {
                                                        style.backgroundImage=`linear-gradient(to bottom right, transparent calc(50% - 1px), red, transparent calc(50% + 1px))`; 
                                                        style.backgroundColor="pink";
                                                    }

                                                    return item && <td 
                                                        rowspan={(item.end - item.start).toString()}
                                                        style={style}
                                                    >
                                                        { !isPassed && <div className="custom-control custom-checkbox">
                                                            <input 
                                                                disabled={isPassed} 
                                                                type="checkbox" 
                                                                name={uid}
                                                                id={uid}
                                                                className="custom-control-input"
                                                                onChange={(e) => {
                                                                    // e.preventDefault();
                                                                    const start = moment().add(day, 'day').set({hour: item.start, minute: 0, second: 0, millisecond: 0}).toISOString();
                                                                    const end = moment().add(day, 'day').set({hour: item.end, minute: 0, second: 0, millisecond: 0}).toISOString();
                                                                    if (e.target.checked) {
                                                                        this.ranges.push({
                                                                            start, 
                                                                            end
                                                                        });
                                                                    } else {
                                                                        this.ranges = this.ranges.filter((range) => {
                                                                            return range.start != start && range.end != end;
                                                                        });
                                                                    }
    
                                                                    console.log(this.ranges);
                                                                }} 
                                                            />
                                                            <label 
                                                                className="custom-control-label" 
                                                                htmlFor={uid}
                                                                style={{color: "white"}}
                                                            >
                                                                {`${moment().add(day, 'day').hour(item.start).format('h a')} to ${moment().add(day, 'day').hour(item.end).format('h a')}`}
                
                                                            </label>
                                                        </div>}
                                                    </td>
                                                })}
                                                
                                            </tr>
                                        })}
                                    </table>
                                    <div style={{
                                        textAlign: "end"
                                    }}>
                                        <button 
                                            className="btn btn-primary btn-ms mt-2 mr-2" 
                                            onClick={this.saveRanges}
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>
                            }
                        </div>
                        {/* <DialogActions>
                            <button type="button" className="btn btn-outline-primary btn-sm mt-2 mr-2" onClick={this.handleMessageClose}>OK</button>
                            <button type="button" className="btn btn-outline-danger btn-sm mt-2 mr-2" onClick={this.handleCallDelay}>Do not call me now</button>
                        </DialogActions> */}
                    </div>
                </DialogContent>
            </Dialog>
            </>
        )
    }

    ModifyPhone = () => {
        this.setState({
            isDialogContent: !this.state.isDialogContent
        })
    }

    changeBestPhoneNumber = ( number, numbers ) => {
        const { dispatch, user } = this.props;
        user.BestPhoneNumber = number;
        user.BestPhoneNumbers = numbers;
        dispatch(profileAction.change(user));  
    }

    changeConfirmBestPhoneNumber = ( number, numbers ) => {
        const { dispatch, user } = this.props;
        user.confirmBestPhoneNumber = number;
        user.confirmBestPhoneNumbers = numbers;
        dispatch(profileAction.change(user));
    }

    validateConfirmField(field) {
        const { dispatch, user } = this.props;
        if (user[field] && user[`confirm${field}`] &&
            user[field] !== user[`confirm${field}`]) {
            user[`confirm${field}Error`] = true;
        } else {
            user[`confirm${field}Error`] = false;
        }

        dispatch(profileAction.change(user));
    }

    handleUpdateMobile = (event) => {
        event.preventDefault();
        const { dispatch, user } = this.props;
        const { Role } = user;
        if (Role === "patient") {
            const validator = new ProfileValidator();
            validator.bestPhoneNumber(user);

            if (validator.validate()) {
                //user.personId = getPersonId();
                const formData = this.createFormData();
                dispatch(profileAction.updatePersonalInfo(formData));
                this.ModifyPhone();
            } else {
                dispatch(alertAction.error('Form validation errors', validator.errorMsgs));
            }
        } else {
            const formData = this.createFormData();
            dispatch(profileAction.updatePersonalInfo(formData));
            this.ModifyPhone();
        }
    }

    createFormData() {
        const { user } = this.props;
        const formData = new FormData();

        formData.append('personId', getPersonId());
        formData.append('BestPhoneNumber', user.BestPhoneNumber);
        formData.append('Role', user.Role);
        formData.append('DateOfBirth', user.Person.DateOfBirth);
        formData.append('Address', user.Person.Address);
        formData.append('City', user.Person.City);
        formData.append('Province', user.Person.Province);
        formData.append('PostalCode', user.Person.PostalCode);
        return formData;
    }

    renderMessage = (user) => {
        const { isOpenMessage } = this.state;
        return (
            <>
            <Dialog 
                fullWidth={true}
                maxWidth="md"
                open={isOpenMessage}
            >
                <DialogContent >
                    <div style={{fontSize:"20px"}}>
                        {!this.state.isDialogContent &&
                            <div>
                                <p>Please confirm the best phone number to reach you. Ensure that you have access to this phone as AskMedication.com will call you right now.</p>
                                <p>Your best phone number : {user && user.BestPhoneNumber ? user.BestPhoneNumber : user.Person.BestPhoneNumber}</p>
                                <DialogActions>
                                    <button type="button" className="btn btn-outline-primary btn-sm mt-2 mr-2" onClick={this.callToPatientFromTwilio}>Confirm my phone number</button>
                                    <button type="button" className="btn btn-outline-primary btn-sm mt-2 mr-2" onClick={this.ModifyPhone}>Modify my phone number</button>
                                </DialogActions>
                            </div>
                        }
                        {this.state.isDialogContent && 
                            <div>
                                <p>Here you can modify your number</p>
                                <form className="w-100" onSubmit={this.handleUpdateMobile} noValidate>
                                    <div className="row">
                                        <div className="col-md-6 col-sm-6 mb-3">
                                            <RequiredLabel htmlFor="BestPhoneNumber">
                                                Best Phone Number for Doctor to Reach You
                                            </RequiredLabel>
                                            <FormatPhoneNumber value={user.BestPhoneNumber}
                                                                values = {user.BestPhoneNumbers}
                                                                onChange = {this.changeBestPhoneNumber}  
                                                                onBlur={() => this.validateConfirmField('BestPhoneNumber')} 
                                                                required />
                                            <div className="invalid-feedback">
                                                Best reach phone number is required.
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-6 mb-3">
                                            <RequiredLabel htmlFor="ConfirmBestPhoneNumber">
                                                Confirm Best Phone Number for Doctor to Reach You
                                            </RequiredLabel>
                                            <FormatPhoneNumber value={user.confirmBestPhoneNumber}
                                                                values = {user.confirmBestPhoneNumbers}
                                                                onChange = {this.changeConfirmBestPhoneNumber}  
                                                                onBlur={() => this.validateConfirmField('BestPhoneNumber')} 
                                                                required />
                                            <div className="invalid-feedback">
                                                Best reach phone number is required.
                                            </div>
                                        </div>
                                    </div>
                                    <DialogActions>
                                        <button type="button" className="btn btn-outline-primary btn-sm mt-2 mr-2" onClick={this.ModifyPhone}>Cancel</button>
                                        <button className="btn btn-outline-primary btn-sm mt-2 mr-2" type="button" onClick={this.handleUpdateMobile}>Confirm my phone number</button>
                                    </DialogActions>
                                </form>
                            </div>
                        }
                    </div>
                </DialogContent>
            </Dialog>
            </>
        )
    }

    render() {
        const { medicalInfoObj, prescriptionObj, user } = this.props;
        let medications;

        if (prescriptionObj) {
            medications = prescriptionObj.Medications;
        }

        return (
            <div className="prescription-form">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h1 className="h2">Request a Prescription</h1><hr />
                </div>
                <form className="form" onSubmit={this.submitForm}>
                    <div>
                        <div className="d-flex justify-content-between mb-2">
                            <div className="sub-title">
                                <div>
                                    <h1 className="h5 font-weight-normal">
                                        Select the medications to be refilled
                                    </h1>
                                    {!prescriptionConstant.IS_UNLIMITED && <div className="mb-3">
                                        (At most <span style={{fontWeight: "bold"}}>3</span> medications are allowed per request. Please split into multiple requests if you need more)
                                    </div>}
                                </div>
                                {
                                    medicalInfoObj && medicalInfoObj.MedicationsCurrentlyTaken.length > 0 &&
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" id="is-load-medication" className="custom-control-input"
                                            onChange={this.loadMedication} />
                                        <label className="custom-control-label" htmlFor="is-load-medication">
                                            Do you want to load your currently taken medications?
                                        </label>
                                    </div>
                                }
                            </div>
                            {/*<PrescriptionCost medications={medications} />*/}
                        </div>
                        {prescriptionObj.Medications &&
                            <DrugSearch onMedicationsChanged={this.handleMedicationsChanged} allowEdit={true}  isCrossOut={true}/>
                        }
                        <hr />
                        <h2 className="h5 mb-3 font-weight-normal">
                            Select your Pharmacy
                        </h2>
                        {prescriptionObj.pharmacy !== undefined &&
                            <PharmacySelect id="pharmacy" name="pharmacy" value={prescriptionObj.pharmacy} onChange={this.handleChange} />
                        }                        
                        <hr className="mb-4" />
                        <div className="d-flex justify-content-between">
                            <button className="btn btn-outline-secondary btn-lg" type="button" onClick={() => history.goBack()}>Back</button>
                            <button 
                                className="btn btn-primary btn-lg" 
                                type="submit"
                                data-test-id="prescription-form__proceed"
                            >Proceed</button>
                        </div>
                    </div>
                </form>
                {this.renderMessage(user)}
                {this.confirmedPrescriptionMsg()}
            </div>
        );
    }
}


PrescriptionForm.propTypes = {
    dispatch: PropTypes.func.isRequired,
    prescriptionObj: PropTypes.object,
    medicalInfoObj: PropTypes.object
};


function mapStateToProps(state) {
    const { medicalInfoObj } = state.medicalInfo;
    let { prescriptionObj, nextCallTime, isCallAvailable } = state.prescription;
    let { user } = state.authentication;

    // init prescriptionObj
    if (prescriptionObj === undefined) {
        prescriptionObj = {
            Person: getPersonId()
        };
    }

    if (prescriptionObj.Person === undefined) {
        prescriptionObj.Person = getPersonId();
    }

    // init pharmacy
    if (prescriptionObj.pharmacy === undefined) {
        prescriptionObj.pharmacy = medicalInfoObj ? medicalInfoObj.defaultPharmacy : undefined;
    }

    // init Medications
    if (prescriptionObj.Medications === undefined) {
        prescriptionObj.Medications = [];
    }

    return {
        medicalInfoObj,
        prescriptionObj,
        user,
        nextCallTime,
        isCallAvailable
    };
}

const mapDispatchToProps = dispatch => {
    return {
        createAndPending: async (prescriptionObj = {}) => await dispatch(prescriptionAction.createAndPending(prescriptionObj)),
        dispatch
    }
}

const temp = connect(mapStateToProps, mapDispatchToProps)(withRouter(PrescriptionForm));

export { temp as PrescriptionForm };