import { BaseValidator } from './';
import { PHNCheck, appType } from '../_helpers';
import { validPhoneNumber } from '../_helpers/validPhoneNumber';
import { profileConstant } from '../_constants/profile.constant';


class ProfileValidator extends BaseValidator {
    constructor(props) {
        super(props);
    }

    personalInfo = (user, editImage = true) => {
        if (appType() === 'client' || (appType() === undefined && window.location.pathname != '/office-registration')) {
            this.validateField(!user.FirstName, 'First Name is Required');
            this.validateField(!user.LastName, 'Last Name is Required');
            this.validateField(!user.PhoneNumber, 'Phone Number is Required');
            this.validateField(!validPhoneNumber(user.PhoneNumber), 'Please Enter a Valid Phone Number');
            this.validateField(!user.BestPhoneNumber, 'Best Phone Number to reach you is Required');
            this.validateField(!validPhoneNumber(user.BestPhoneNumber), 'Please Enter a Valid Best Phone Number to reach you');
            this.validateField(!user.Address, 'Address is Required');
            this.validateField(!user.City, 'City is Required');
            this.validateField(!user.Province, 'Province is Required');
            this.validateField(!user.PostalCode, 'PostalCode is Required');

            // date of birth validation
            this.validateField(user.DateOfBirth === undefined, 'Date Of Birth is Required');
            this.validateField(user.DateOfBirth === null, 'You must be 19 years or older to access AskMedication.com');

            if (profileConstant.IS_PROFILE_UPLOAD_ALLOWED)
                this.validateField(editImage && !user.profileImageFile, 'Profile Image is Required');
        } else if (appType() === 'office' || (appType() === undefined && window.location.pathname == '/office-registration')) {
            this.validateField(!user.FirstName, 'First Name is Required');
            this.validateField(!user.LastName, 'Last Name is Required');
            this.validateField(!user.PhoneNumber, 'Phone Number is Required');
             this.validateField(!validPhoneNumber(user.PhoneNumber), 'Please Enter a Valid Phone Number');
        }
    }

    healthDoc = (user) => {
        if (!profileConstant.IS_OTHER_PROV_ALLOWED || user.selectBCCard === 'Yes') {
            this.validateField(!user.BCCardNumber, 'BC Card Number is Required');
            this.validateField(!user.confirmBCCardNumber, 'Confirm BC Card Number is Required');
            this.validateField(!PHNCheck(user.BCCardNumber), 'Invalid BC Card Number');
        }

        if (user.selectHealthCard === 'Yes') {
            this.validateField(!user.HealthCardNumber, 'Health Card Number is Required');
            this.validateField(!user.confirmHealthCardNumber, 'Confirm Health Card Number is Required');
            this.validateField(!user.HealthCardProvince, 'Health Card Province is Required');
        }

        if (user.selectPassport === 'Yes') {
            this.validateField(!user.PassportNumber, 'Passport Number is Required');
            this.validateField(!user.confirmPassportNumber, 'Confirm Passport Number is Required');
            this.validateField(!user.PassportCountryId, 'Passport Country is Required');
        }

        if (user.BCCardNumber && user.confirmBCCardNumber) {
            this.validateField(user.BCCardNumber !== user.confirmBCCardNumber, 'Confirm BC Card Number doesn\'t match BC Card Number');
        }

        if (user.HealthCardNumber && user.confirmHealthCardNumber) {
            this.validateField(user.HealthCardNumber !== user.confirmHealthCardNumber, 'Confirm Health Card Number doesn\'t match Health Card Number');
        }

        if (user.PassportNumber && user.confirmPassportNumber) {
            this.validateField(user.PassportNumber !== user.confirmPassportNumber, 'Confirm Passport Number doesn\'t match Passport Number');
        }
        
        if (profileConstant.IS_BC_CARD_UPLOAD_ALLOWED)
            this.validateField(!user.healthDocFile, 'Upload Card Image is Required');
    }

    userCredential = (user) => {
        this.validateField(!user.Email, 'Email is Required');
        this.validateField(!user.confirmEmail, 'Confirm Email is Required');
        this.validateField(!user.Password, 'Password is Required');
        this.validateField(!user.confirmPassword, 'Confirm Password is Required');

        // confirmation field validation
        if (user.Email && user.confirmEmail) {
            this.validateField(user.Email !== user.confirmEmail, 'Confirm Email doesn\'t match Email');
        }

        if (user.Password && user.confirmPassword) {
            this.validateField(user.Password !== user.confirmPassword, 'Confirm Password doesn\'t match Password');
        }
    }

    updateEmail = (user) => {
        this.validateField(!user.Email, 'Email is Required');
        this.validateField(!user.confirmEmail, 'Confirm Email is Required');
        this.validateField(!user.Password, 'Password is Required');

        // confirmation field validation
        if (user.Email && user.confirmEmail) {
            this.validateField(user.Email !== user.confirmEmail, 'Confirm Email doesn\'t match Email');
        }
    }

    password = (user) => {
        this.validateField(!user.password, 'Password is Required');
        this.validateField(!user.confirmPassword, 'Confirm Password is Required');
        if (user.password && user.confirmPassword) {
            this.validateField(
                user.password !== user.confirmPassword, 
                'Confirm Password doesn\'t match Password'
            );
        }
    }

    // validation for bestphonenumber
    bestPhoneNumber = (user) => {
        this.validateField(!user.BestPhoneNumber, 'Best Phone Number to reach you is Required');
        this.validateField(!validPhoneNumber(user.BestPhoneNumber), 'Please Enter a Valid Best Phone Number to reach you');
        this.validateField(!user.confirmBestPhoneNumber, 'Confirm Best Phone Number to reach you is Required');
        this.validateField(!validPhoneNumber(user.confirmBestPhoneNumber), 'Please Enter a Valid Confirm Best Phone Number to reach you');
        if (user.BestPhoneNumber && user.confirmBestPhoneNumber) {
            this.validateField(
                user.BestPhoneNumber !== user.confirmBestPhoneNumber,
                'Best Phone Number doesn\'t match'
            );
        }
    }
}

export { ProfileValidator };