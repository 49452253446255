import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { medicationFilterAction } from '../../_actions';
import { MedicationFilterTable, AddMedicationFilter } from './';


class MedicationFilterPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(medicationFilterAction.get());
    }

    render() {
        return (
            <div>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h1 className="h2">Medication Filter</h1>
                </div>
                <div>
                    <AddMedicationFilter />
                    <MedicationFilterTable />
                </div>
            </div>
        );
    }
}


MedicationFilterPage.propTypes = {
    dispatch: PropTypes.func.isRequired
};


const temp = connect()(MedicationFilterPage);

export { temp as MedicationFilterPage };

