import '../node_modules/jquery/dist/jquery';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap';
import '../node_modules/bootstrap/js/dist/util';
import './assets/css/main.scss';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as Sentry from '@sentry/browser';
import { Provider } from 'react-redux';
import { store } from './_helpers';

if (process.env.REACT_APP_ENV !== 'dev') {
    Sentry.init({dsn: "https://22499b2b4cb941c39f6709b702deeff7@sentry.io/1784887"});
}

// disable react-dev-tools for prod
if (process.env.REACT_APP_ENV != 'dev' && process.env.REACT_APP_ENV != 'test' &&  typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__ === "object") {
    for (let [key, value] of Object.entries(window.__REACT_DEVTOOLS_GLOBAL_HOOK__)) {
        window.__REACT_DEVTOOLS_GLOBAL_HOOK__[key] = typeof value == "function" ? () => { } : null;
    }
}

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root')
);