
import axios from 'axios';
import config from '../config';
import { getPersonId, getToken } from '../_helpers';


export const prescriptionService = {
    getById,
    getFaxedOrRefunded,
    getByPerson,
    getPendingByPerson,
    create,
    updateMedicationStatus,
    updateMedicationUsage,    
    acknowledge,
    print,
    paymentCompleted,
    refundSuccess,
    setPay,
    setPayV2,
    createAndPending,
    saveText,
    updateMedicationAndUsage,
    updateDrPharmacy,
    CheckPrescriptionDuplicted,
    TwilioCallToPatients,
    handleTwilioCallDelay

};

function getById(prescriptionId) {
    const url = `${config.apiHost}/prescription/${prescriptionId}`;
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.get(url);
}

function getFaxedOrRefunded() {
    const url = `${config.apiHost}/prescription/faxed-or-refunded`;
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.get(url);
}


function getByPerson(personId) {
    const url = `${config.apiHost}/prescription/person/${personId}`;
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.get(url);
}

function CheckPrescriptionDuplicted(personId,drug) {
    const url = `${config.apiHost}/prescription/person/duplicate/${personId}`;
    axios.defaults.headers.common['x-access-token'] = getToken();
    const body = {
        drug
    }

    return axios.post(url, body);
}

function getPendingByPerson(personId) {
    const url = `${config.apiHost}/prescription/pending/person/${personId}`;
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.get(url);
}

function create(prescriptionObj) {
    const url = `${config.apiHost}/prescription`;
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.post(url, prescriptionObj);
}

async function createAndPending(prescriptionObj) {
    const url = `${config.apiHost}/prescription/create-and-pending`;
    axios.defaults.headers.common['x-access-token'] = getToken();

    return await axios.post(url, prescriptionObj);
}

function updateMedicationStatus(prescription, medication, status) {
    const url = `${config.apiHost}/prescription/medication/updateStatus`;
    const body = {
        prescription,
        medication,
        status
    };
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.put(url, body);
}

function updateMedicationUsage(prescription, medication, usage) {
    const url = `${config.apiHost}/prescription/medication/usage`;
    const body = {
        prescription,
        medication,
        usage
    };
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.put(url, body);
}

function updateMedicationAndUsage(prescription, medication, usage) {
    const url = `${config.apiHost}/prescription/medication/medication-usage`;
    const body = {
        prescription,
        medication,
        usage
    };
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.put(url, body);
}

function updateDrPharmacy(prescription){
    const url =`${config.apiHost}/prescription/dr-pharmacy`;
    const body = {
        prescription,
    };
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.put(url, body);
}

function acknowledge(prescriptionId, messageId, personId) {
    const url = `${config.apiHost}/prescription/acknowledge`;
    const body = {
        prescriptionId,
        messageId,
        personId,
        acknowledgedBy: getPersonId()
    };
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.put(url, body);
}

function print(prescriptionId, personId) {
    const url = `${config.apiHost}/prescription/print`;
    const body = {
        prescriptionId,        
        personId,
        faxedBy: getPersonId()
    };
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.put(url, body);
}

function paymentCompleted(id, personId, paymentId) {
    const url = `${config.apiHost}/prescription/payment-completed`;
    const body = {
        id,
        personId,
        paymentId
    };
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.put(url, body);
}

function refundSuccess(id, personId) {
    const url = `${config.apiHost}/prescription/refund-success`;
    const body = {
        id,
        personId,
        refundedBy: getPersonId()
    };
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.put(url, body);
}

function setPay(prescriptionId, stripeTokenId, amount) {
    const url = `${config.apiHost}/prescription/set-pay`;
    const body = {
        prescriptionId,
        stripeTokenId,
        amount
    };
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.put(url, body);
}

function setPayV2(prescriptionId, orderID, amount) {
    const url = `${config.apiHost}/prescription/set-pay`;
    const body = {
        prescriptionId,
        orderID,
        amount
    };
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.put(url, body);
}

function saveText(prescriptionId, text = '') {
    const url = `${config.apiHost}/prescription/set-text`;
    axios.defaults.headers.common['x-access-token'] = getToken();
    return axios.post(url, {data: {prescriptionId, text}});
}

function TwilioCallToPatients(prescriptionId, userId, userBestPhone) {
    const url = `${config.apiHost}/twilioCall`;
    axios.defaults.headers.common['x-access-token'] = getToken();
    const body = {
        userBestPhone,
        userId,
        prescriptionId
    }
    return axios.post(url, body);
}

function handleTwilioCallDelay(prescriptionId) {
    const url = `${config.apiHost}/twilioCall/delay`
    axios.defaults.headers.common['x-access-token'] = getToken();
    const body = {
        prescriptionId
    }
    return axios.post(url, body);
}