import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import moment from 'moment';
import { connect } from 'react-redux';
import queryString from 'query-string';

import config from '../../config';
import { ProfileValidator } from '../../_validator';
import { alertAction } from '../../_actions';


// const isNativeDatePicker = false;
class RecoveryPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    /**
     * Get the value from the inputs form
     */
    changeValue = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }


    /**
     * Submit the form sending a post request to the API - Recovering the password
     */

    submitFormPassword = (e) => {
        e.preventDefault();

        const validator = new ProfileValidator();
        validator.password({ 
            password: this.state.Password, 
            confirmPassword: this.state.confirmPassword 
        });
        if (!validator.validate()) {
            this.props.alertError('Form validation errors', validator.errorMsgs);
            return;
        }
            
        const { location } = this.props;
        let params = queryString.parse(location.search);
        let passwordRecoveryToken;
        if(params.token){
            passwordRecoveryToken = params.token;
        }
        const url = `${config.apiHost}/user/recovery/password`;

        axios.post(url, {
            passwordRecoveryToken,
            Password: this.state.Password
        }).then(() => {
            this.setState({ ErrorMessage: null });
            this.props.history.push('/login');
        }).catch(error => {
            this.setState({ ErrorMessage: error.response.data.errors 
                ? this.toStringErrors(error.response.data.errors) 
                : error.response.data.message 
            });
        });
    }

    /**
     * To string of an array of errors from API
     */
    toStringErrors(errors) {
        if (errors !== null && errors.length > 0) {
            let stringErrors = 'Error: ';
            errors.map(item => stringErrors += item.message + ' ');
            return stringErrors;
        } else {
            return 'Sorry. Unknown error. Contact the administrator.';
        }
    }

    removeError = (e) => {
        e.preventDefault();
        this.setState({
            ErrorMessage: null
        });
    }
    /**
     * Render the HTML template
     */
    render() {
        let ErrorAlert = <div/>;

        if (this.state.ErrorMessage) {
            ErrorAlert =
                <div className="alert alert-danger alert-register alert-dismissible fade show" /*role="alert"*/>
                    {this.state.ErrorMessage}
                    <button type="button" className="close" /*data-dismiss="alert"*/ aria-label="Close"
                        onClick={this.removeError}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>;
        }

        return (
            <div className="container">
                <div className="py-5 text-center">
                    <h2>Password Recovery</h2>
                    <p className="lead">Enter the new password below</p>
                </div>
                <div className="row">
                    <div className="col-md-10 offset-md-1">
                        {ErrorAlert}
                        <form className="needs-validation form-register">
                            <div>
                                {/* <hr className="mb-4" /> */}
                                <div className="row">

                                    <div className="col-md-6 mb-2">
                                        <label htmlFor="Password">New Password *</label>
                                        <input type="password" className="form-control" id="Password" name="Password" onChange={this.changeValue} />
                                        <div className="invalid-feedback">
                                            Password is required.
                                         </div>
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <label htmlFor="confirmPassword">Confirm Password *</label>
                                        <input type="password" className="form-control" id="confirmPassword" name="confirmPassword" onChange={this.changeValue} />
                                        <div className="invalid-feedback">
                                            Confirm password is required.
                                         </div>
                                    </div>

                                </div>

                                <div className="btn-bar">
                                    <button className="btn btn-success btn-lg btn-sign-up" type="submit" onClick={this.submitFormPassword}>Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}


RecoveryPassword.propTypes = {
    history: PropTypes.object
};

function mapDispatchToProps(dispatch) {
    return {
        alertError: (title, content) => dispatch(alertAction.error(title, content))
    };
}
const temp = connect(null, mapDispatchToProps)(RecoveryPassword);

export { temp as RecoveryPassword };