import { patientConstant } from '../_constants';

export function patient(state = {}, action) {
    switch (action.type) {
        case patientConstant.GET_PATIENTS_SUCCESS:
            return {
                ...state,
                users: [...action.users]
            };
        case patientConstant.GET_PATIENT_SUCCESS:
            return {
                ...state,
                person: { ...action.person }
            };
        case patientConstant.SET_PAGE:
            return {
                ...state,
                page: action.page
            };
        case patientConstant.SET_ROWS:
            return {
                ...state,
                rows: action.rows
            };
        case patientConstant.SELECT_PATIENT:
            return {
                ...state,
                selectId: action.id
            };
        default:
            return state;
    }
}