import React from 'react';
import { connect } from 'react-redux';


class AboutUs extends React.Component{
    constructor(props){
        super(props);
        this.email = "info@askmedication.com";
    }
    renderEmailLink = () => {
        return <a href={`mailto:${this.email}`}>{this.email}</a>;
    }

    render(){
        return(
            <div className="text-container">
            <h1>About Us</h1>
            <p className="text">AskMedication.com is based in Richmond, British Columbia, Canada.
            <br/>
             We are an online medical service and you can always reach us at {this.renderEmailLink()}. 
             <br/>
             Our medical office assistants will be glad to discuss with you any concern that you may have.</p>
            <div className="contact-container">
                <h4>Contact Us</h4>
                <p>Address: 4651 No. 3 Road, Richmond, BC V6X 2C4</p>
                <p>Email: {this.renderEmailLink()}</p>
                <p>Fax: (604) 398-5999</p>

            </div>
        </div>
        )
    }

}

const temp = connect()(AboutUs);
export {temp as AboutUs}