import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { history, getPersonId } from '../../_helpers';
import { sleepInfoAction, alertAction } from '../../_actions';
import { SleepInfoValidator } from '../../_validator';
import { BloodPressure } from '.';
import { IconButton } from '../../components';


class EditSleepInfoPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        const { dispatch, sleepInfoObj } = this.props;

        if (sleepInfoObj === undefined) {
            dispatch(sleepInfoAction.getByPerson(getPersonId()));
        }
    }

    handleCancel() {
        history.goBack();
    }

    handleChange = event => {
        const { dispatch, sleepInfoObj } = this.props;
        let { name, value } = event.target;

        if (value === 'true') {
            value = true;
        } else if (value === 'false') {
            value = false;
        }

        sleepInfoObj[name] = value;
        dispatch(sleepInfoAction.change(sleepInfoObj));
    }

    handleDateChange = date => {
        const { dispatch, sleepInfoObj } = this.props;
        sleepInfoObj.blood_pressure_date = date;
        dispatch(sleepInfoAction.change(sleepInfoObj));
    }

    handleSubmit = (event) => {
        event.preventDefault();

        const { dispatch, sleepInfoObj } = this.props;

        const validator = new SleepInfoValidator();
        validator.form(sleepInfoObj);

        if (validator.validate()) {
            dispatch(sleepInfoAction.save(sleepInfoObj));
        } else {
            dispatch(alertAction.warning('Form validation warning', validator.errorMsgs));
        }
    }

    renderRadioField(question, name) {
        const { sleepInfoObj } = this.props;

        return (
            <div className="col-md-12 my-2">
                <label htmlFor="snore" className="mr-5">
                    {question}
                    <span className="text-danger"><b> *</b></span>
                </label>
                <div className="custom-control custom-radio custom-control-inline">
                    <input type="radio" className="custom-control-input" id={`${name}-yes`} name={name} value={true}
                        checked={sleepInfoObj[name] === true} onChange={this.handleChange}
                    />
                    <label className="custom-control-label" htmlFor={`${name}-yes`}>Yes</label>
                </div>
                <div className="custom-control custom-radio custom-control-inline">
                    <input type="radio" className="custom-control-input" id={`${name}-no`} name={name} value={false}
                        checked={sleepInfoObj[name] === false} onChange={this.handleChange}
                    />
                    <label className="custom-control-label" htmlFor={`${name}-no`}>No</label>
                </div>
            </div>
        );
    }

    renderHeightWeight(question, name, unit) {
        const { sleepInfoObj } = this.props;

        return (
            <div className="col-md-12 my-2">
                <label htmlFor={name} className="mr-5">
                    {question}
                    <span className="text-danger"><b> *</b></span>
                </label>
                <input type="number" min="0" className="form-control mr-2" id={name} name={name}
                    value={sleepInfoObj[name]} onChange={this.handleChange} required />
                <span>{unit}</span>
            </div>
        );
    }

    addBloodPressure = () => {
        const { dispatch, sleepInfoObj } = this.props;
        dispatch(sleepInfoAction.addBloodPressure(sleepInfoObj));
    }

    removeBloodPressure = () => {
        const { dispatch, sleepInfoObj } = this.props;        
        dispatch(sleepInfoAction.removeBloodPressure(sleepInfoObj));
    }

    bloodPressureChange = (index, name, value) => {
        const { dispatch, sleepInfoObj } = this.props;

        sleepInfoObj.bloodPressures[index][name] = value;
        dispatch(sleepInfoAction.change(sleepInfoObj));
    }

    renderBloodPressure() {
        const { bloodPressures } = this.props.sleepInfoObj;

        return (
            <>
                <div className="col-md-12 my-2 text-info">
                    Blood pressure: please go to a machine at the pharmacy or measure at home 
                    with a machine and provide 1 to 3 readings, preferably on different days.
                </div>
                {bloodPressures.map((o, index) => {
                    return <BloodPressure key={index} bloodPressureObj={o} onChange={({name, value}) => this.bloodPressureChange(index, name, value)} />;
                })}
                <div className="col-md-12 mt-2">
                    {bloodPressures.length < 3 &&
                        <IconButton type="button" icon="fas fa-plus" className="btn btn-outline-secondary mb-2 mr-2" onClick={this.addBloodPressure}>
                            Add another blood pressure measurement
                        </IconButton>
                    }
                    {bloodPressures.length > 1 &&
                        <IconButton type="button" icon="fas fa-minus" className="btn btn-outline-secondary mb-2" onClick={this.removeBloodPressure}>
                            Remove blood pressure measurement
                        </IconButton>
                    }
                </div>
            </>
        );
    }

    renderEditSleepReferral() {
        const { sleepInfoObj } = this.props;

        if (sleepInfoObj === undefined) {
            return (
                <div className="my-4">
                    Please wait, loading the sleep referral...
                </div>
            );
        } else {
            return (
                <div className="row">
                    {this.renderHeightWeight('What is your height?', 'height', 'cm')}
                    {this.renderHeightWeight('What is your weight?', 'weight', 'kg')}
                    {this.renderRadioField('Did you gain weight recently?', 'gainWeight')}
                    {this.renderRadioField('Do you suffer from depression? ', 'depression')}
                    {this.renderRadioField('Have you been diagnosed with diabetes?', 'diabetes')}
                    {this.renderRadioField('Do you have any heart disease?', 'heartDisease')}
                    {this.renderRadioField('Have you ever been told that you have a heart condition called atrial fibrillation?', 'atrialFibrillation')}
                    {this.renderRadioField('Is your cognition (ability to think and remember) deteriorating?', 'cognitionDeteriorating')}
                    {this.renderRadioField('Do you snore?', 'snore')}
                    {this.renderRadioField('Does you breathing pause while you are asleep?', 'asleepBreathPause')}
                    {this.renderRadioField('Do you choke while you are asleep?', 'asleepChoke')}
                    {this.renderRadioField('Are you taking any medication for blood pressure?', 'bloodPressureMedication')}
                    {this.renderBloodPressure()}
                </div>
            );
        }
    }

    render() {
        return (
            <div id="edit-sleep-info-page">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h1 className="h2">
                        {this.props.noSleepInfo ? 'Add ' : 'Edit '} Sleep Info
                    </h1>
                </div>
                <div className="description mb-3">
                    <p> Answer all the following questions in order to send a Level III sleep apnea test request to your preferred sleep clinic: </p>
                </div>
                <form onSubmit={this.handleSubmit} noValidate>
                    {this.renderEditSleepReferral()}
                    <hr className="mb-4" />
                    <div className="mb-3 text-right">
                        <button className="btn btn-outline-secondary btn-lg mr-3" type="button" onClick={this.handleCancel}>Cancel</button>
                        <button className="btn btn-success btn-lg" type="submit">Save</button>
                    </div>
                </form>
            </div>
        );
    }
}


EditSleepInfoPage.propTypes = {
    dispatch: PropTypes.func,
    sleepInfoObj: PropTypes.object,
    noSleepInfo: PropTypes.bool
};


function mapStateToProps(state) {
    const { noSleepInfo, sleepInfoObj } = state.sleepInfo;

    return {
        noSleepInfo,
        sleepInfoObj
    };
}

const temp = connect(mapStateToProps)(EditSleepInfoPage);

export { temp as EditSleepInfoPage };

