import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { StripeProvider } from 'react-stripe-elements';
import { Elements } from 'react-stripe-elements';
import { connect } from 'react-redux';
import { history } from '../../_helpers';
import { PrescriptionCost, CheckoutForm } from './';
import config from '../../config';

class PrescriptionPayment extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    renderCheckout() {
        const { prescription } = this.props;

        if (prescription) {
            const medications = prescription.medications;
            const STRIPE_PUBLIC_KEY = config.stripePublicKey;

            return (
                <>
                    <div className="d-flex">
                        <h5>
                            {`Pay the prescription - ${prescription._id}`}
                        </h5>
                        <PrescriptionCost medications={medications} />
                    </div>
                    <br />
                    <StripeProvider apiKey={STRIPE_PUBLIC_KEY}>
                        <Elements>
                            <CheckoutForm />
                        </Elements>
                    </StripeProvider>
                </>
            );
        } else {
            return (
                <div>
                    <h5>
                        Prescription is not available!
                    </h5>
                    <button className="btn btn-outline-secondary mt-4" onClick={() => history.goBack()}>Back</button>
                </div>
            );
        }
    }

    render() {
        return (
            <div>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h1 className="h2">Prescription &gt; Payment</h1>
                </div>
                {this.renderCheckout()}
            </div>
        );
    }
}


PrescriptionPayment.propTypes = {
    prescription: PropTypes.object
};


function mapStateToProps(state) {
    const { prescription } = state.payment;

    return {
        prescription
    };
}

const temp = connect(mapStateToProps)(withRouter(PrescriptionPayment));

export { temp as PrescriptionPayment };