import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Medications, Medication } from '.';
import { alertAction, drugSearchAction } from '../_actions';
import { drugService, prescriptionService } from '../_services';
import { getPersonId } from '../_helpers';


class DrugSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        const { dispatch, preload } = this.props;

        if (preload) {
            dispatch(drugSearchAction.preload(preload));
        } else {
            dispatch(drugSearchAction.init());
        }
    }

    handleSearch = (event) => {
        const { dispatch } = this.props;
        const searchKey = event.target.value;

        dispatch(drugSearchAction.change(searchKey));
        dispatch(drugSearchAction.clearSearch());

        if (searchKey.length > 2) {
            dispatch(drugSearchAction.search(searchKey));
        }
    }

    isDrugExisted = (drugName) => {
        const { drugs } = this.props;
        const existed = drugs.find(o => o.name === drugName) !== undefined;

        return existed;
    }
     
    isDuplicatePrescription = async (drug) => {
        const res = await prescriptionService.CheckPrescriptionDuplicted(getPersonId(), drug);
        const { isDrugExisted } = res.data;
        return isDrugExisted ;
    }

    isDrugDosageExisted = (drug ) => {
        const { drugs } = this.props;
         return drugs.find(o => {
            if(o.name === drug.name){
               for( let i=0; i<drug.ingredients.length; i++){
                   if(o.ingredients[i].strength != drug.ingredients[i].strength)
                        return false;
               } 
               return true;
            } else {
                return false;
            }
        })
    }

    handleSelect = async (drug) => {
        const { dispatch } = this.props;
        const res = await drugService.getByName(drug.name);
        const multipleDrugs = res.data;
        if (multipleDrugs.length > 1) {
            this.props.selectDrugAction(multipleDrugs, drug);
        } else {
            if (!this.isDrugExisted(drug.name)) {
                dispatch(drugSearchAction.showUsage(drug));
            } else {
                dispatch(alertAction.warning('This drug is already added.'));
            }
        }
    }

    handleMultipleSelect = (drug) =>{
        const { dispatch } = this.props;
         if(!this.isDrugDosageExisted(drug)){
            dispatch(drugSearchAction.showUsage(drug));
         } else {
            dispatch(alertAction.warning('This drug is already added.'));
         }
    }

    handleAdd = async (usage) => {
        const { dispatch, drug, drugs, onMedicationsChanged, hideDuration } = this.props;
        let isDrugExisted;
        isDrugExisted =!hideDuration && await this.isDuplicatePrescription(drug);

        if (!isDrugExisted) {
            drug.usages = [usage];
            const newDrugs = [...drugs, drug];

            dispatch(drugSearchAction.addDrug(newDrugs));
            onMedicationsChanged(newDrugs);
        } else {
            dispatch(alertAction.warning('This drug is already added as in previous pending prescription.'));
        }
    }

    handleCancel = () => {
        const { dispatch } = this.props;
        dispatch(drugSearchAction.clearSearch());
    }

    handleEditUsage = async (drug, usage) => {
        let { dispatch, drugs, onMedicationsChanged, hideDuration} = this.props;
        drugs = JSON.parse(JSON.stringify(drugs));  // deep copy so it won't affect the medicalInfo currently taken medication
        
        let isDrugExisted;
        isDrugExisted =!hideDuration && await this.isDuplicatePrescription(drug);

        if (!isDrugExisted){

          const newDrugs = [...drugs].map(o => {
            if (o.name === drug.name) {
                o.usages = [...o.usages, usage];
            }

            return o;
        });

        dispatch(drugSearchAction.updateDrugUsage(newDrugs));
        onMedicationsChanged(newDrugs);
        } else {
            dispatch(alertAction.warning('This drug is already added as in previous pending prescription.'));
        }
    }

    /**
     * Delete a tag item
     * @param {item} i 
     */
    handleDelete = (drug) => {
        const { dispatch, drugs, onMedicationsChanged } = this.props;
        const newDrugs = drugs.filter((o) => o.name !== drug.name);

        dispatch(drugSearchAction.removeDrug(newDrugs));
        onMedicationsChanged(newDrugs);
    }

    /**
     * Ask the medication's frequency
     */
    handleShowUsage = (drug) => {
        const { dispatch } = this.props;
        dispatch(drugSearchAction.showUsage(drug));
    }

    renderDrugs() {
        const { drugs, hideDuration, allowEdit } = this.props;

        if (drugs && drugs.length > 0) {
            return (
                <Medications items={drugs} handleDelete={this.handleDelete} hideDuration={hideDuration}
                    allowEdit={allowEdit} onEditUsage={(drug, usage) => this.handleEditUsage(drug, usage)}

                />
            );
        } else {
            return '';
        }
    }

    renderSearchList() {
        const { searchList, isCrossOut } = this.props;

        if (searchList && searchList.length > 0) {
            return (
                <div className="search-list">
                    <ul className="ul-suggestions">
                        {
                            searchList.map((item, index) => {
                                let classes = '';
                                let disabled = false;

                                // cross out medication, red and cross out
                                if (item.filter === 'CROSS_OUT' && isCrossOut) {
                                    classes = `${classes} cross-out`;
                                    disabled = true;
                                }

                                return <li key={index} className={classes} 
                                    data-test-id={`drug-search__search-list__${index}`}
                                    onClick={(event) => {
                                        if (disabled) {
                                            event.preventDefault();
                                        } else {
                                            this.handleSelect(item);
                                        }
                                    }}
                                >
                                    <span className="medication">{item.name}</span>
                                    <span className="ingredients">
                                        Ingredients:
                                        {
                                            item.ingredients.map((ing) => {
                                                return ' ' + ing.ingredient_name + '; ';
                                            })
                                        }
                                    </span>
                                </li>;
                            })
                        }
                    </ul>
                </div>
            );
        } else {
            return '';
        }
    }

    renderMultipleDrugs() {
        const { multipleDrugs, drug } = this.props;

        if (multipleDrugs && multipleDrugs.length > 0 && drug) {
            return (
                <div className="multiple-drugs">
                    <div>
                        <div className="select-drug">{drug.name}</div>
                        <div>
                            Select the specific medication based on Strength, Administration and Formulation:
                        </div>
                    </div>
                    <ul>
                        {
                            multipleDrugs.map((item, index) => {
                                return <li key={index} onClick={() => this.handleMultipleSelect(item)}>
                                    <span className="ingredients-medication">
                                        Ingredients:
                                        {
                                            item.ingredients.map((ing) => {
                                                return ' ' + ing.ingredient_name + ' - ' + ing.strength + ' ' + ing.strength_unit + '; ';
                                            })
                                        }
                                    </span>
                                    <span className="administration">
                                        Administration: {item.administrations[0]} | Formulation: {item.formulations[0]}
                                    </span>
                                </li>;
                            })
                        }
                    </ul>
                </div>
            );
        } else {
            return '';
        }
    }

    renderUsage() {
        const { drug, showUsage, hideDuration } = this.props;

        if (showUsage) {
            return (
                <Medication item={drug} hideDuration={hideDuration}
                    onAddUsage={(usage) => this.handleAdd(usage)}
                    onCancelUsage={this.handleCancel}
                />
            );
        } else {
            return '';
        }
    }

    render() {
        const { searchKey, drugs } = this.props;
        const placeholder = drugs.length > 0 ? 'Add another medication' : 'Add a medication';

        return (
            <div id="drug-search" className="py-1">
                {this.renderDrugs()}
                <input type="text" className="form-control" placeholder={placeholder}
                    value={searchKey} onChange={this.handleSearch} onFocus={this.handleSearch} 
                    data-test-id="drug-search__input"
                />
                {this.renderSearchList()}
                {this.renderMultipleDrugs()}
                {this.renderUsage()}
            </div>
        );
    }
}


DrugSearch.propTypes = {
    dispatch: PropTypes.func,
    onMedicationsChanged: PropTypes.func,
    searchKey: PropTypes.string,
    searchList: PropTypes.array,
    showUsage: PropTypes.bool,
    multipleDrugs: PropTypes.array,
    drug: PropTypes.object,
    drugs: PropTypes.array,
    preload: PropTypes.array,
    hideDuration: PropTypes.bool,
    allowEdit: PropTypes.bool,
    isCrossOut: PropTypes.bool,
};


function mapStateToProps(state) {
    const { searchList, multipleDrugs, showUsage, drug } = state.drugSearch;
    let { searchKey, drugs } = state.drugSearch;

    if (searchKey === undefined) {
        searchKey = '';
    }

    if (drugs === undefined) {
        drugs = [];
    }

    return {
        searchKey,
        searchList,
        multipleDrugs,
        showUsage,
        drug,
        drugs
    };
}

function mapDispatchToProps(dispatch) {
    return {
        selectDrug: (drug) => dispatch(drugSearchAction.selectDrug(drug)),
        selectDrugAction: (multipleDrugs, drug) => dispatch(drugSearchAction.selectDrugAction(multipleDrugs, drug)),
        dispatch
    };
}

const temp = connect(mapStateToProps, mapDispatchToProps)(DrugSearch);

export { temp as DrugSearch };