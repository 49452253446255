import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { connect } from 'react-redux';
import ReactDOMServer from 'react-dom/server';
import { Provider } from 'react-redux';
import TextareaAutosize from 'react-textarea-autosize';
import { alertAction, loadingAction } from '../../_actions';

import { store } from '../../_helpers';


import { Medications } from '../../components';
import { formatDate, uploadBucket, getS3ImageUrl } from '../../_helpers';
import { prescriptionAction, faxAction, profileAction} from '../../_actions';
import SignatureCanvas from 'react-signature-canvas'
import getUrlComponents from '../../_helpers/getUrlComponents'
import getUrlFromMultiCanvas from '../../_helpers/getUrlFromMultiCanvas';
import getCanvasFromComp from '../../_helpers/getCanvasFromComp';
import getCanvasFromEle from '../../_helpers/getCanvasFromEle';

class PrescriptionDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            print: false,
            // quantity:'',
            // frequency:'',
            // asNeeded:'', 
            // duration:'',
            items:[],
            


        };

        this.handleFax = this.handleFax.bind(this);
    }

    componentDidMount(){
        const { dispatch, user } = this.props;
        
        if (!user){
           dispatch(profileAction.get());
        }
    }

    handleFax = async () => {
        const { dispatch, prescription } = this.props;

        dispatch(loadingAction.open());
        dispatch(alertAction.info('Queue prescription fax in progress...'));

        const coverCanvas = await getCanvasFromComp(this.renderCover());
        const prescriptionCanvas = await getCanvasFromEle(this.refs.prescription);
        const url = await getUrlFromMultiCanvas([
            coverCanvas,
            prescriptionCanvas// this.displayContent()
        ]);
        dispatch(prescriptionAction.closePrescriptionDialog());
        dispatch(loadingAction.close());

        await dispatch(faxAction.queuePrescriptionFax(prescription, url));
        
    }

    handlePrint = () => {
        const { dispatch, prescription } = this.props;
        window.print();
        dispatch(prescriptionAction.print(prescription));
        dispatch(prescriptionAction.closePrescriptionDialog());
    }

    handleClose = () => {
        const { dispatch } = this.props;
        dispatch(prescriptionAction.closePrescriptionDialog());
    };

    renderAllergy = (list, other) => {
        if (list.length === 0 && other === null) {
            return 'No allergies to medications';
        } else {
            return (
                <>
                    <div className="mb-1"><strong>Allergies</strong></div>
                    <ul>
                        {list.length > 0 && list.map((item) => {
                            return <li key={item}>{item}</li>;
                        })}
                        {/* display Other if it exist */}
                        {other && other.length > 0 && other.map((item) => {
                            return <li key={item._id}>{item.name}</li>;
                        })}
                    </ul>
                </>
            );
        }
    }

    renderIngredients = (items) => {
        return (
            <div className="ingredients">
                {items.map((item) => {
                    return ' ' + item.ingredient_name + ' ' + item.strength + ' ' + item.strength_unit + '; ';
                })}
            </div>
        );
    }

    renderUsage = (item) => {
        const usage = [...item.usages].pop();
        const { quantity, frequency, asNeeded, duration} = usage;
        return(
            <div>
                <div>Quantity: {quantity}</div>
                <div>Frequency: {frequency}</div>
                {asNeeded ? <div>As needed</div> : ''}
                <>
                 <div>Duration: {duration}</div>
                 <div>No Repeats</div>
                </>
            </div>
        )

    }

    renderMedications = () => {
        const { prescription } = this.props;
        const items = prescription.medications.filter( item => item.status === 'Approved' );
        return (
            items.map((item, index) => {
                return (
                    <div className="medication">
                    <div className=''>
                        <div className="left-panel">
                            <div className="drug-title">
                                <div>
                                    <span className="drug-name">
                                        {item.name}
                                    </span>
                                    <span className="drug-description">
                                        ({item.administrations[0]}/{item.formulations[0]})
                                    </span>
                                </div>
                            </div>
                            {this.renderIngredients(item.ingredients)}

                           
                            {this.renderUsage(item)}
                        </div>
                        {/* {this.renderRightPanel()} */}
                    </div>
                </div>
                )
            })
        )
        
    }

    getMedicationsString() {
        if (((this.props.prescription || {}).medications || []).length == 0)
            return "";

        const element = <Provider store={store}><Medications items={this.props.prescription.medications} type="print"></Medications></Provider>;
        const string = ReactDOMServer.renderToString(element);
        let stringNoTag = string.replace(/<[^>\/]+>/g, '');
        stringNoTag = stringNoTag.replace(/(<[^>]+>){1,3}/g, '\r\n');
        stringNoTag = stringNoTag.replace(/(<[^>]+>){3,}/g, '\r\n\r\n');
        return stringNoTag;
    }

    renderCover = () => {
        const { prescription } = this.props;
        if ( typeof(prescription) == 'undefined'){
            return ''
        } else {
            const pharmacy = prescription.pharmacy;
            const drPharmacy = prescription.drPharmacy;
            const displayPharmacy = drPharmacy ? drPharmacy : pharmacy;

            return (<div className="cover-page">
            <div className="textContainer"> 
                <p className="title">
                    <span className="title__text">Fax Cover Page</span>
                    <span className="title__slash">{" / "}</span>
                    <span className="title__text">See prescription on next page</span>
                </p>
                <p className="text">To: <span className="pharmacy-info">{displayPharmacy.name} </span> </p>
                <p className="text">Fax: <span className="pharmacy-info">{displayPharmacy.fax_number}</span></p>
                <p className="text">From: <span className="webname">AskMedication.com</span></p>
                <p className="text">info@askmedication.com  &nbsp;&nbsp;<strong className="text--strong">Fax</strong>:&nbsp; (604) 398-5999</p>
            </div>
            <div className="row">
                <div className="col-md-6 col-sm-6">
                    <p className="text-request">Patients requiring a <br/>
                        prescription can go to <br/>
                            <strong>AskMedication.com</strong> and <br/>
                            connect with a <strong>doctor</strong> who <br/>
                            quickly reviews their <br/>
                            prescription requests. </p>
                </div>
                <div className="col-md-6 col-sm-6">
                    <img src={require('../../assets/img/rx-logo.png')}  alt='Prexcription Icon' title='Prescription Icon' className="rx-logo"/>
                </div>
            </div>
            <div className="row">
                <div className="col-md-5 col-sm-5" 
                    
                >
                    <div
                        style={{
                            backgroundImage: "url(" + require('../../assets/img/cloud.png') + ")",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            height: "280px",
                            width:"230px",
                        }}
                    ><p className="text-location">
                        Available for <br/>
                            patients and <br/>
                            pharmacies <br/>
                            located in <br/>
                            British <br/>
                            Columbia
                        </p>
                    </div>
                    <p className="text-service">
                    Our services are free <br/>
                        with a valid B.C. <br/>
                        Services Card 
                        
                    </p>
                </div>
                <div className="col-md-7 col-sm-7">
                    <p className="ct">
                    Prescriptions are <br/>
                    faxed to the patient’s <br/>
                    pharmacy of choice
                    </p>
                    <p className="words-error">
                    If this fax was received in error,<br/>
                        please destroy it immediately and <br/>
                        contact us at:
                    </p>
                    <div className="contact">
                        <p>
                            info@askmedication.com
                        </p>
                        <p>
                            <strong>Fax</strong>: (604) 398-5999 
                        </p>
                    </div>
                </div>
            </div>

        </div>)
        }


        
    }

    displayContent = () => {
        const { prescription, medicalInfoObj, user, isText } = this.props;
        const signatureKey = `${uploadBucket.signatureImage}/${user.signatureImageKey}`;
        const url = getS3ImageUrl(signatureKey);

        if (prescription === undefined) {
            return '';
        } else {
            const person = prescription.person;
            const pharmacy = prescription.pharmacy;
            const drPharmacy = prescription.drPharmacy;
            const displayPharmacy = drPharmacy ? drPharmacy : pharmacy;
            return (
                <div id="prescription-form" ref="prescription">
                    <div className="header">
                        <div className="message">
                            <div>This prescription was generated online. </div>
                            <div>Pharmacist: please double check picture ID prior to dispensing.</div>
                        </div>
                        <div className="banner">
                            <div className="logo">
                                <img src={require('../../assets/img/rx_logo.PNG')} />
                            </div>
                            <div className="clinic">
                                <div className="clinic-owner">
                                    Balint Budai MD CM #63473
                                </div>
                                <div className="clinic-name">
                                    AskMedication.com
                                </div>
                                <div className="clinic-address">
                                    4651 No. 3 Road, Richmond, BC V6X 2C4
                                </div>
                                <div>
                                    Email:&nbsp;info@askmedication.com
                                </div>
                                <div>
                                    Fax: (604) 398-5999
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content">
                        <div className="today">{formatDate(new Date())}</div>
                        <div className="profile">
                            <div className="person">
                                <div>{`${person.FirstName} ${person.LastName}`}&nbsp;&nbsp;&nbsp;&nbsp;DOB: {formatDate(person.DateOfBirth)}</div>
                                <div>{person.Address}</div>
                                <div>{`${person.City}, ${person.Province}, ${person.PostalCode}`}</div>
                                {person.BestPhoneNumber && <div>{`Phone Number: ${person.BestPhoneNumber}`}</div>}
                                <div className="healthInfo">{`Health Ins. #${((person.HealthDocuments || [])[0] || {}).DocumentNumber || ''}`}</div>
                            </div>
                            <div className="allergy">
                                {this.renderAllergy(medicalInfoObj.AllergyToMedication, medicalInfoObj.otherAllergy)}
                            </div>
                        </div>
                        <div className="medications">
                            {isText 
                            ? <div style={{whiteSpace: "pre-wrap"}}> { this.props.prescription.text }</div> 
                            : <Medications items={prescription.medications} type="print"></Medications>}
                            {/*this.renderMedications()*/}
                            {/*<textarea style={{width: "100%", height: "65px"}}>{this.getMedicationsString()}</textarea>*/}
                            {/*<TextareaAutosize style={{width: "100%"}}>{this.getMedicationsString()}</TextareaAutosize>*/}
                        </div>
                        {/*<div className="doctorNote">
                            <label for="providerNote">Medications</label>
                            <textarea id="providerNote" name="providerNote">{'hi'}</textarea>
                        </div>*/}
                        <div className="signature">
                            <div>
                                <span className="signature-wrapper">Signature:&nbsp;  
                                <SignatureCanvas penColor='black'
                                                 canvasProps={{dotSize:0.5, className: 'sigCanvas'}} />
                                 {/* <img src={url} className="signature-img" crossOrigin="anonymous"/> */}
                                 &nbsp;&nbsp;&nbsp;</span>
                                <span className="underline"></span>
                            </div>
                            <div className="formal-sign">Balint Budai MD CM #63473</div>
                        </div>
                        <div className="pharmacy">
                            <div>
                                <b>This prescription can only be filled at the following pharmacy:</b>
                            </div>
                            
                            <div>
                                {displayPharmacy.name}
                            </div>
                            <div>
                                {displayPharmacy.address}
                            </div>
                            <div>
                                Fax: {displayPharmacy.fax_number}
                            </div>
                            
                        </div>
                    </div>
                </div>
            );
        }
    }

    render() {
        const { openPrescription, prescription } = this.props;

        return (
            openPrescription ?
                <Dialog
                    fullWidth={true}
                    maxWidth="md"
                    open={true}
                    onClose={this.handleClose}
                >
                    <DialogTitle className="no-print">
                        Prescription
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleClose}>
                            <i className="fa fa-close mx-2"></i>
                        </button>
                    </DialogTitle>
                    <DialogContent>
                        {/*this.renderCover()*/}
                        {this.displayContent()}
                    </DialogContent>
                    <DialogActions className="no-print">
                        <button className="btn btn-outline-secondary" onClick={this.handlePrint}>Print</button>
                        {/* {prescription.fax == null &&
                            <button className="btn btn-outline-primary" onClick={this.handleFax}>Fax</button>
                        } */}
                         <button className="btn btn-outline-primary" onClick={this.handleFax}>{prescription.fax == null ? "Fax" : "Re-Fax"}</button>
                    </DialogActions>
                </Dialog>
                :
                ''
        );
    }
}

PrescriptionDialog.propTypes = {
    dispatch: PropTypes.func,
    onClose: PropTypes.func,
    prescription: PropTypes.object,
    medicalInfoObj: PropTypes.object,
    user:PropTypes.object,
    openPrescription: PropTypes.bool
};


function mapStateToProps(state) {
    const { prescription } = state.prescription;
    const { medicalInfoObj } = state.medicalInfo;
    const { user } = state.profile;
    let { openPrescription, isText } = state.prescription;

    if (openPrescription === undefined) {
        openPrescription = false;
    }

    return {
        prescription,
        openPrescription,
        medicalInfoObj,
        user,
        isText
    };
}

const temp = connect(mapStateToProps)(PrescriptionDialog);

export { temp as PrescriptionDialog };