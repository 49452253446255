import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {  problemAction } from '../../_actions';
import { ReportProblemDialog } from './';


class Screenshot extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    handleClick = () => {
        const { dispatch } = this.props;

        dispatch(problemAction.capture(document));
    }

    hideIcon = () => {
        const { capturing, openPrescription } = this.props;

        // hide screen shot icon condition
        if (capturing || openPrescription ) {
            return true;
        } else {
            return false;
        }
    }

    render() {
        return (
            <>
                <div id="screenshot-icon" className={`${this.hideIcon() ? 'hidden' : 'shown'}`} onClick={this.handleClick}>
                    <i className="fas fa-bug" />
                    <div className="description">
                        Report a problem on this page
                    </div>
                </div>
                <ReportProblemDialog />
            </>
        );
    }
}


Screenshot.propTypes = {
    dispatch: PropTypes.func.isRequired,
    personId: PropTypes.string,
    capturing: PropTypes.bool,
    openPrescription: PropTypes.bool
};


function mapStateToProps(state) {
    const { capturing } = state.problem;
    const { openPrescription } = state.prescription;

    return {
        capturing,
        openPrescription
    };
}

const temp = connect(mapStateToProps)(Screenshot);

export { temp as Screenshot };