import { appConstant, userConstant } from '../_constants';
import { isOver24Hours, getUser } from '../_helpers';


export function app(state = {}, action) {
    switch (action.type) {
        case appConstant.API_NOT_AVAILABLE:
            return {
                apiNotAvailable: true
            };
        case appConstant.SET_LAST_LOGIN: {
            let lastLogin = { ...action.lastLogin };
            const user = {...action.user}

            if (user.dontShowLastLogin) {
                // if less than 24 hours then return empty object which won't display the last login modal
                lastLogin = isOver24Hours(user.dontShowLastLoginDate) ? lastLogin : {};
            }

            return {
                lastLogin: lastLogin               
            };
        }
        case userConstant.LOGOUT:
            return {};
        default:
            return state;
    }
}