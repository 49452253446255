import axios from 'axios';
import config from '../config';
import { getToken } from '../_helpers';


export const drugService = {
    getByNameOrIngredient,
    getByName,
    isCancel,
    getByNames,
    getIngredientsByTcAhfs
};

const CancelToken = axios.CancelToken;
let cancel;

function getByNameOrIngredient(searchKey) {
    const url = `${config.apiHost}/drug/name/${encodeURIComponent(searchKey)}`;    
    axios.defaults.headers.common['x-access-token'] = getToken();

    cancel && cancel();

    return axios.get(url, {
        cancelToken: new CancelToken(function executor(c) {
            // An executor function receives a cancel function as a parameter
            cancel = c;
        })
    });
}

function getByName(searchKey) {
    const url = `${config.apiHost}/drug/name/only/${encodeURIComponent(searchKey)}`;
    axios.defaults.headers.common['x-access-token'] = getToken();

    return axios.get(url);
}

function isCancel(error) {
    return axios.isCancel(error);
}

async function getByNames(names = []) {
    try {
        const url = `${config.apiHost}/drug/names`;
        axios.defaults.headers.common['x-access-token'] = getToken();
        const body = { names };
        const response = await axios.post(url, body);
        return response.data;
    } catch (error) {
        throw (error);
    }
    
}

async function getIngredientsByTcAhfs(tcAhfs) {
    const url = `${config.apiHost}/drug/tcAhfs/${encodeURIComponent(tcAhfs)}`;
    axios.defaults.headers.common['x-access-token'] = getToken();
    const { data = [] } = await axios.get(url);
    return data;
}
