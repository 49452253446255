import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { connect } from 'react-redux';
import { messageAction } from '../../_actions';
import { messageConstant } from '../../_constants';
import { Messages, NewMessageDialog } from './index';
import { isDoctor, isCustomerService } from '../../_helpers';
import { IconButton } from '../../components';

const styles = () => ({
    root: {
        marginTop: 20,
    }
});


class Messaging extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(messageAction.changeTab(messageConstant.TAB_INBOX));
    }

    handleChange = (event, tabValue) => {
        const { dispatch } = this.props;
        dispatch(messageAction.changeTab(tabValue));
    };

    handleCompose = () => {
        const { dispatch } = this.props;
        dispatch(messageAction.compose());
    }

    render() {
        const { classes, tabValue } = this.props;

        if (tabValue === undefined) return '';

        return (
            <div className={classes.root}>
                <div 
                    style={{display: "flex", justifyContent: "space-between"}}
                >
                    <IconButton icon="fas fa-plus" className="btn btn-outline-secondary" onClick={this.handleCompose}>
                        Compose
                    </IconButton>
                    <div>
                    <span style={{borderStyle: "dotted", borderColor: "#17a2b8"}}> {"message"} </span> <span>{" indicates the message just clicked on lastly "}</span>
                    </div>
                </div>
                <Tabs
                    value={tabValue}
                    onChange={this.handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                >
                    <Tab value={messageConstant.TAB_INBOX} label="Inbox" />
                    <Tab value={messageConstant.TAB_SENT} label="Sent" />
                    {(isDoctor() || isCustomerService() )&& <Tab value={messageConstant.TAB_ARCHIVE} label="Archive" />}
                </Tabs>
                {tabValue === messageConstant.TAB_INBOX && <Messages />}
                {tabValue === messageConstant.TAB_SENT && <Messages />}
                {(isDoctor() || isCustomerService())&& tabValue === messageConstant.TAB_ARCHIVE && <Messages />}
                <NewMessageDialog />
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { tabValue, unreadCount } = state.message;

    return {
        tabValue,
        unreadCount,
    };
}


Messaging.propTypes = {
    dispatch: PropTypes.func.isRequired,
    tabValue: PropTypes.string,
    classes: PropTypes.object
};


const temp = connect(mapStateToProps)(withStyles(styles)(Messaging));

export { temp as Messaging };

